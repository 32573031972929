// eslint-disable-next-line no-use-before-define
import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { ReferralType } from 'op-enums';
import React from 'react';
import { generatePath } from 'react-router';
import { FormRow } from 'shared-components/components';
import { ErrorInfo, WarningInfo, BaseDatePicker } from 'shared-components/components/FormFields';
import { Info } from 'shared-components/images';
import { ListData, SelectOptions } from 'shared-components/interfaces';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { styled } from '@mui/system';
import { DeviceUtilities } from 'shared-components/utils';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';

// CONSTANTS
export const CONTINUE_BUTTON_TEXT = 'Continue';
export const RETURN_TO_SUBMIT_TEXT = 'Return to submit';
export const PSO_SUMMARY_LINK = '/navigator/patient/:patientId/summary';
export const SPECIFY_MARITAL_FIELDS = ['Married', 'De Facto', 'Domestic Partner'];

// Validation Constants
export const VALUE_REQUIRED = 'This field is required';
export const CHAR_ONLY = 'Please enter characters only.';
export const PHONE_VALIDATION = 'Please enter a valid phone number';
export const PHONE_REQUIRED = 'Please enter at least one phone number';
export const ADDRESS_VALIDATION = 'Please enter a valid address';
export const POSTCODE_VALIDATION = 'Please enter your correct Postcode';
export const MEDICARE_NUMBER_VALIDATION = 'Please enter a valid Medicare card number';
export const MEDICARE_IRN_VALIDATION = 'Please enter a valid IRN';
export const MEDICARE_EXPIRY_VALIDATION = 'Please complete Medicare card expiry';
export const MEDICARE_EXPIRY_FORMAT_VALIDATION = 'Please enter a valid date';
export const DVA_VALIDATION = 'Please enter your DVA card number';
export const DVA_FORMAT_VALIDATION = 'Please enter a valid DVA card number';
export const DVA_EXPIRY_VALIDATION = 'Please complete DVA card expiry';
export const DVA_EXPIRY_FORMAT_VALIDATION = 'Please enter a valid date';
export const VIC_AMBULANCE_NUMBER_LENGTH_VALIDATION = 'Max length: 20 characters';
export const VIC_AMBULANCE_NUMBER_VALIDATION = 'Please enter a valid ambulance membership number';
export const GENDER_DIFFERENT_TERM_VALIDATION = 'Max length: 25 characters';

export const MONTH_FORMAT_VALIDATION = 'Please enter a valid month';
export const YEAR_FORMAT_VALIDATION = 'Please enter a valid year';

export const EMPTY_ADDRESS = {
  country: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postcode: '',
};

export const generatePractitionerValidation = (
  referrerType: ReferralType,
  validationType: 'warning' | 'error',
): JSX.Element => {
  switch (validationType) {
    case 'warning':
      return (
        <WarningInfo
          warnings={[
            `This ${referrerType} cannot be found in the system. Please check for ${referrerType} in MOSAIQ or add new record.`,
          ]}
        />
      );
    case 'error':
      return <ErrorInfo errors={[`This ${referrerType} cannot be found in the system. Please use the lookup above`]} />;
    default:
      return <></>;
  }
};

// STYLED COMPONENTS
export const HeaderTitle = styled('div')`
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 8px;

  @media all and (max-width: ${supportedSize.tablet}px) {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 6px 0;
  }
`;

export const SectionTitle = styled('div')`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  color: ${(props) => props.theme.palette.text.primary};
`;

export const HeaderSubTitle = styled('div')`
  margin-bottom: 30px;

  @media all and (max-width: ${supportedSize.transition}px) {
    margin-bottom: 0;
  }
`;

export const SubText = styled('div')`
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const SecondaryTitle = styled('div')`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.text.primary};
  margin-top: 40px;
  margin-bottom: 20px;

  @media all and (max-width: ${supportedSize.tablet}px) {
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

export const StyledIndentedSection = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
  label {
    margin-left: 16px;
    @media all and (max-width: ${supportedSize.transition}px) {
      margin-left: 0;
    }
  }
  padding-left: 10px;
  @media all and (max-width: ${supportedSize.transition}px) {
    padding-left: 18px;
  }
  margin-top: 10px;
`;

export const StyledHorizontalLine = styled('div')`
  margin-top: -5px !important;
  margin-bottom: 5px !important;
  width: 100%;
  height: 10px;
  border-bottom: 1px solid black;
  border-color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;

  span {
    font-size: 13px;
    background-color: white;
    padding: 0 20px;
    color: ${(props) => props.theme.palette.grey[600]};
  }
`;

export const ExtraInformation = styled('div')`
  margin-top: 6px;
  color: ${(props) => props.theme.palette.info.main};
  font-size: 13px;
  display: flex;
  align-items: center;
`;

export const StyledInfoIcon = styled(Info)`
  fill: ${(props) => props.theme.palette.info.main};
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

export const extraInformation = (
  <ExtraInformation>
    <StyledInfoIcon />
    Please provide a copy of the document to the clinic
  </ExtraInformation>
);

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

// FUNCTIONS

export const getDisplayValue = (refData: ListData[], option: string): string => {
  const filtered = refData?.filter((item: ListData) => item.id === option);
  return filtered?.length ? filtered[0].name : option;
};

export const sharedFormContainerProps = (
  sectionTitle: string,
  pageNumber?: number | undefined,
  previousPageLink?: string,
): any => {
  return {
    isLocked: false,
    updatedBy: '',
    deleteModal: () => {},
    showSaveAndExit: true,
    pageNumber,
    sectionTitle: sectionTitle,
    showDiscard: false,
    sidePanelWidth: '-15%',
    footerPosition: 'relative',
    isLoading: false,
    isShowSaveExitConfirm: true,
    backLink: previousPageLink,
  };
};

export const registrationPath = (patientId: string | number, suffix: string): string => {
  return generatePath(`/patient/${patientId}/registration/${suffix}`);
};

export interface StandardFieldProps {
  NAME: string;
  COMPONENT: any;
  TITLE: string;
  PLACE_HOLDER?: string;
}

export const standardField = (
  field: StandardFieldProps,
  updateField: (field: string, value: string | number | boolean) => void,
  options?: SelectOptions[],
  alternateStyle = true,
): JSX.Element => {
  const menuPlacement = ['maritalStatus'].includes(field.NAME) && !DeviceUtilities.isDesktopDevice();

  return (
    <FormRow fieldLabel={field.TITLE} fieldName={field.NAME} labelClass={'label-form-row'}>
      <Field
        id={field.NAME}
        name={field.NAME}
        component={field.COMPONENT}
        alternateStyle={alternateStyle}
        updateMutation={(value: string) => updateField(field.NAME, value)}
        handleMutation={updateField}
        placeholder={field.PLACE_HOLDER}
        options={options || []}
        menuPlacement={menuPlacement ? 'top' : undefined}
      />
    </FormRow>
  );
};

export const linkedField = (
  field: StandardFieldProps,
  linkedField: StandardFieldProps,
  updateField: (field: string, value: string | number) => void,
): JSX.Element => (
  <FormRow fieldLabel={field.TITLE} fieldName={field.NAME} labelClass={'label-form-row'}>
    <Field
      id={field.NAME}
      name={field.NAME}
      component={field.COMPONENT}
      alternateStyle
      updateMutation={(value: string) => {
        updateField(field.NAME, value);
      }}
      placeholder={field.PLACE_HOLDER}
    />
  </FormRow>
);

// Checks if fields with errors have been touched. If they have form is invalid
export const isFormValid = (errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues>): boolean => {
  return Object.keys(errors).some((error: string) => touched[error] === true);
};

export const MEDICARE_DVA_FIELD_MAPPINGS = {
  healthFundMedicareDVA: 'Medicare and DVA',
  healthFundMedicare: 'Medicare',
};

export const DVA_FIELD_MAPPINGS = {
  dvaCardGold: 'Gold',
  dvaCardWhite: 'White',
};

export const DateField = (
  field: StandardFieldProps,
  value: string,
  isValid: boolean,
  updateField: (field: string, value: string | number) => void,
  setFieldTouched: (field: string, isTouched: boolean, checkError: boolean) => void,
  setFieldValue: (field: string, value: string, checkError: boolean) => void,
  limitMaxDate?: boolean,
): JSX.Element => (
  <FormRow
    fieldLabel={field.TITLE}
    fieldName={field.NAME}
    className={!isValid ? 'registration-date-error' : 'registration-date'}
    labelClass={'label-form-row'}>
    <Stack sx={{ maxWidth: '200px' }}>
      <Field
        name={field.NAME}
        component={BaseDatePicker}
        alternateStyle
        id={field.NAME}
        value={value ? dayjs(value) : undefined}
        disableFuture={limitMaxDate}
        onChange={(date: Date | null): void => {
          const newDate = dayjs(date);
          if (!newDate.isValid()) {
            updateField(field.NAME, '');
            setFieldValue(field.NAME, '', true);
          } else {
            updateField(field.NAME, newDate.format('YYYY-MM-DD'));
            setFieldValue(field.NAME, newDate.format('YYYY-MM-DD'), true);
          }
        }}
        onBlur={() => {
          setFieldTouched(field.NAME, true, true);
        }}
        error={!isValid}
      />
    </Stack>
  </FormRow>
);
