import React from 'react';
import { styled } from '@mui/system';
import Button from '../Button';
import { ButtonProps } from '../Button/types';
import { ToggleButtonProps, ButtonValueType } from './types';

const StyledToggleButton = styled('div')`
  && {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
  }
  button {
    border-right-width: 0px !important;
  }
  button:last-child {
    border-right-width: 2px !important;
  }
  button:disabled {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

interface StyledButtonProps extends ButtonProps {
  borderRadius: string;
  checked: boolean;
  error: boolean | undefined;
  value: ButtonValueType;
}
const StyledButton = styled(Button)<StyledButtonProps>`
  border-radius: ${(props: StyledButtonProps) => props.borderRadius};
  background-color: ${(props) => props.checked && props.disabled && props.theme.palette.grey[600]} !important;
  color: ${(props) => props.checked && props.disabled && props.theme.palette.primary?.contrastText} !important;
  &&:hover,
  focus {
    background-color: ${(props) =>
      (props.checked && props.disabled && props.theme.palette.grey[600]) ||
      (props.disabled && props.theme.palette.grey[100]) ||
      (props.error && props.theme.palette.primary?.contrastText)} !important;
  }
`;
const getBorderRadiusOverride = (index: number, length: number) => {
  if (index === 0) return '4px 0px 0px 4px !important';
  if (index === length - 1) return '0px 4px 4px 0px !important';
  return '0px !important';
};

const ToggleButton = (props: ToggleButtonProps): JSX.Element => {
  const handleChange = (value: ButtonValueType) => {
    props.onChange && props.onChange(value);
    props.setter(value);
  };
  const color = props.error ? 'error' : 'primary';
  if (props.buttons?.length > 5) return <>Only 5 Options allowed</>;
  return (
    <StyledToggleButton data-cy={props['data-cy']}>
      {props.buttons.map((button, index) => {
        return (
          <StyledButton
            key={button.value.toString()}
            borderRadius={getBorderRadiusOverride(index, props.buttons.length)}
            mode={button.value === props.value && !props.error ? 'contained' : 'outlined'}
            color={color}
            size="fit"
            disabled={props.disabled}
            checked={button.value === props.value}
            error={props.error}
            data-cy={`${props['data-cy']}-${button.value.toString()}`}
            value={button.value}
            onClick={() => {
              handleChange(button.value);
            }}>
            {button.text}
          </StyledButton>
        );
      })}
    </StyledToggleButton>
  );
};

export default ToggleButton;
