import React, { useContext } from 'react';
import Table, { DATATYPE, SORT_TYPES, TableHeaderProps } from 'shared-components/components/Table/Table';
import moment from 'moment';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { Patient, UpcomingDoctorCareplans } from './interfaces';
import { TABLE_KEYS } from './Constants';
import { UserContext } from 'op-contexts/index';
import { useTheme } from '@mui/material';

const getGender = (gender: string): string => {
  switch (gender) {
    case '1':
      return 'M';
    case '2':
      return 'F';
    default:
      return 'Unknown';
  }
};

const getPatientDob = (patient: any): string => {
  return moment(patient.dob, 'YYYY-MM-DD').format('L');
};

const StyledDot = styled('div')`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;
const StyledText = styled('div')`
  font-size: 16px;
  line-height: 1.4;
`;

const MOCareplanStatus = (props: any) => {
  return (
    <>
      <StyledDot {...props} /> {props.status}
    </>
  );
};

const PatientDetails = (props: { patient: Patient }) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ paddingRight: '16px', alignSelf: 'center' }}>
        <PhotoWidget url={props.patient?.photoUrl} enableOverlay={true} size={'sm'} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <div style={{ fontSize: '16px', paddingBottom: '8px' }}>{`${props.patient.fullName} (${getGender(
          props.patient.gender,
        )})`}</div>
        <div style={{ fontSize: '13px', color: theme.palette.grey[600], paddingBottom: '4px' }}>
          <span style={{ fontWeight: 600, paddingRight: '2px' }}>DOB: </span>
          {getPatientDob(props.patient)}
        </div>
        <div
          style={{
            fontSize: '13px',
            color: theme.palette.grey[600],
          }}>
          <span style={{ fontWeight: 600, paddingRight: '2px', wordBreak: 'break-word' }}>ADDR: </span>
          {props.patient?.address?.formattedAddress}
        </div>
      </div>
    </div>
  );
};

interface MOCareplanTableProps {
  careplans: UpcomingDoctorCareplans[];
  headers: TableHeaderProps[];
}

const MOCareplanTable = ({ careplans, headers }: MOCareplanTableProps): JSX.Element => {
  const history = useHistory();
  const { state: userDetails } = useContext(UserContext);
  const theme = useTheme();
  const tableData = careplans
    .sort((a: UpcomingDoctorCareplans, b: UpcomingDoctorCareplans): number => {
      return moment(a.nextTreatmentDate, 'YYYY-MM-DD').diff(moment(b.nextTreatmentDate, 'YYYY-MM-DD'));
    })
    .map((careplanInfo: UpcomingDoctorCareplans) => ({
      tableRows: [
        {
          key: TABLE_KEYS.PATIENT_NAME,
          width: '14%',
          component: <PatientDetails patient={careplanInfo.patient} />,
        },
        {
          key: TABLE_KEYS.TREATING_DEPARTMENT,
          width: '16%',
          component: <StyledText>{careplanInfo.treatingDepartment}</StyledText>,
        },
        {
          key: TABLE_KEYS.CAREPLAN,
          width: '19%',
          component: <StyledText>{careplanInfo.careplanName}</StyledText>,
        },
        {
          key: TABLE_KEYS.NEXT_TREATMENT,
          width: '12%',
          component: (
            <StyledText>
              {moment(careplanInfo.nextTreatmentDate, 'YYYY-MM-DD').calendar({
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'DD/MM/YYYY',
                sameElse: 'DD/MM/YYYY',
              })}
              , {careplanInfo.nextTreatmentInfo}
            </StyledText>
          ),
        },
        {
          key: TABLE_KEYS.STATUS,
          width: '12%',
          component: <MOCareplanStatus status={careplanInfo.status} />,
        },
        {
          key: TABLE_KEYS.PRESCRIBING_DOCTOR,
          width: '15%',
          component: <StyledText>{careplanInfo.prescribingDoctor}</StyledText>,
        },
        {
          key: TABLE_KEYS.LAST_MODIFIED,
          sort: true,
          width: '12%',
          component: (
            <StyledText>{moment(careplanInfo.lastModified).tz(userDetails.timezone).format('L LT')}</StyledText>
          ),
        },
      ],
      onRowClick: () => {
        history.push(
          `/medonc/patient/${careplanInfo.patient.id}/careplan/${careplanInfo.id}/prescription/${careplanInfo.nextTreatmentCycleId}`,
        );
      },
      rowHoverColor: theme.palette.primary.light,
    }));

  const tableProps = {
    tableContainerProps: { minWidth: '1500px', overflow: 'auto' },
    tableHeaders: headers,
    tableData: tableData,
    defaultSortState: { key: TABLE_KEYS.NEXT_TREATMENT, order: SORT_TYPES.ASC, type: DATATYPE.DATE },
    hoverScroll: true,
    noRowsText: 'No upcoming patients',
  };
  return <Table testId="moDashboardTable" {...tableProps} />;
};

export default MOCareplanTable;
