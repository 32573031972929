import { CircularProgress, SimplePaletteColorOptions, ThemeOptions, useTheme } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { typography } from '../../styles/typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CreateIcon from '@mui/icons-material/Create';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonColors, ButtonProps, ButtonIconProps } from './types';

const getColourSet = (color: ButtonColors | undefined, theme: ThemeOptions): SimplePaletteColorOptions | undefined => {
  if (color === 'error') return theme.palette?.error as SimplePaletteColorOptions;
  return theme.palette?.primary as SimplePaletteColorOptions;
};

const FontColor = (props: any) => {
  if (props.disabled) return props.theme.palette.grey[600];
  if (props.mode == 'outlined') return props.theme.palette.text.primary;
  if (props.mode == 'text') return props.theme.palette.text.primary;

  return props.theme.palette.primary?.contrastText;
};

const ButtonWidth = (props: ButtonProps) => {
  if (props.size == 'small') return '155px';
  if (props.size == 'medium') return '168px';
  if (props.size == 'regular') return '208px';
  if (props.size == 'auto') return 'auto';
  if (props.size == 'fit') return '100%';

  return '168px';
};

const IconColor = (props: any) => {
  if (props.disabled) return props.theme.palette.grey[600];
  if (props.mode == 'contained') return props.theme.palette.primary?.contrastText;
  return getColourSet(props.color, props.theme)?.dark;
};

const BorderColor = (props: any) => {
  if (props.mode == 'text') return 'transparent';
  if (props.disabled) return props.theme.palette.grey[300];
  return getColourSet(props.color, props.theme)?.dark;
};

const BackgroundColor = (props: any) => {
  if (props.disabled) return props.theme.palette.grey[100];
  if (props.mode == 'text') return 'transparent';
  if (props.mode == 'outlined') return 'transparent';
  return getColourSet(props.color, props.theme)?.dark;
};

const HoverBackgroundColor = (props: any) => {
  if (props.disabled) return 'default';
  if (props.mode == 'text') return getColourSet(props.color, props.theme)?.contrastText;
  if (props.mode == 'outlined') return getColourSet(props.color, props.theme)?.contrastText;
  if (props.mode == 'contained') return getColourSet(props.color, props.theme)?.main;
};

const PressedBackgroundColor = (props: any) => {
  return getColourSet(props.color, props.theme)?.dark;
};

const StyledButton = styled('button')<ButtonProps>`
  && {
    cursor: pointer;
    white-space:nowrap;
    text-transform: none;
    
    color: ${(props) => FontColor(props)};
    border-color: ${(props) => BorderColor(props)};
    background-color: ${(props) => BackgroundColor(props)};
    border-radius: ${(props) => (props.borderShape == 'square' ? '0px' : '20px')};
    min-height: 2em;
    border-style: solid;
    width: ${(props) => ButtonWidth(props)};
    height: 40px;
    font-size: ${typography.size.button};
    border-width: 2px;
    font-weight: ${typography.weight.bold};
    line-height: 24px;
    padding: 4px 8px;
    text-align: center;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    &:hover, focus {
      background: ${(props) => HoverBackgroundColor(props)};
      outline: 0;
    }
    &:pressed {
      background: ${(props) => PressedBackgroundColor(props)};
      outline: 0;
    }
`;

const ButtonIcon = (props: ButtonIconProps): JSX.Element | null => {
  // TODO apply colours to this and better dispatching (possibly just allow an element to be passed)
  if (props.icon == 'plus-circle') return <AddCircleOutlineIcon />;
  if (props.icon == 'delete') return <DeleteOutlineIcon />;
  if (props.icon == 'create') return <CreateIcon />;
  if (props.icon == 'archive') return <MoveToInboxIcon />;
  if (props.icon == 'close') return <CloseIcon />;

  return null;
};

const Button = (props: ButtonProps) => {
  const theme = useTheme();
  const themeProps = { ...props, theme };
  return (
    <StyledButton type="button" {...props}>
      {props.busy ? (
        <CircularProgress size={24} color={'inherit'} />
      ) : (
        <span>
          {props.icon && props.iconPosition != 'right' && (
            <span style={{ paddingRight: '8px', color: IconColor(themeProps) }}>
              <ButtonIcon icon={props.icon} />
            </span>
          )}
          {props.children}
          {props.icon && props.iconPosition == 'right' && (
            <span style={{ paddingLeft: '8px', color: IconColor(themeProps) }}>
              <ButtonIcon icon={props.icon} />
            </span>
          )}
        </span>
      )}
    </StyledButton>
  );
};

export default Button;
