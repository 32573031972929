import { FormikValues as IntakeFormikValues } from './DiagnosisPages/Intake/Interface';
import { DiagnosisType, DEFAULT_DIAGNOSIS_TYPE } from './DiagnosisPages/Interface';
import { TreatmentInfoType, DEFAULT_TREATMENT_INFO } from './TreatmentInfoPage/Interface';

export interface CareplanRule {
  id: number;
  field: string;
  defaultValue: string;
}

export interface CareplanListFilter {
  id: number;
  name: string;
  rules: CareplanRule[];
}

export interface Patient {
  id: string;
  fullName: string;
  ida: string;
  photoUrl: string;
  patientGender: string;
}

export interface SiteGroupSet {
  id: string;
  orderRanking: any;
  siteSet: any;
}
export interface CareplanCreatorTemplate {
  id: string;
  groupId: string;
  legacyId: string;
}
export interface CareplanTemplate {
  id: string;
  name: string;
  selectionMethod: string;
  template: CareplanCreatorTemplate;
}
export interface Careplan {
  id: string;
  latestPageUrl: string;
  createdBy: string;
  careplanName: string;
  careplanStatus: string;
  careplanTemplate: CareplanTemplate;
  simulationModification: boolean | null;
  prescriptionModification: boolean | null;
  diagnosisModification: boolean | null;
  diagnosisCode: string;
  diagnosis: { id: number; diagnosisCode: { diagnosisDescription: string; diagnosisCode: string } };
  patient: Patient;
  priority: string;
  updatedAt: string;
  submittedAt: string;
  sitegroupSet: [];
  documentSet: [];
  treatingDepartment: string;
}

export enum CarePlanActionType {
  SET_CAREPLAN,
  SET_TREATMENT_INFO,
  SET_CAREPLAN_SITES,
  SET_CAREPLAN_TEMPLATE,
  SET_INTAKE_CODE_MAPPING,
}

export interface CarePlanAction {
  type: CarePlanActionType;
  payload: any;
}

export interface CarePlanType {
  id: string;
  treatmentInfo: TreatmentInfoType;
}

export interface VolumingDoseTemplateType {
  id?: string;
  label: string;
  value: string;
}

export interface VolumingType {
  volumingGtv: string;
  volumingCtv: string;
  volumingPtv: string;
  volumingDose: string;
  volumingDoseTemplates: VolumingDoseTemplateType[];
  volumingAdditionalOarInfo: string;
}

export interface ImagingType {
  imagingId: string;
  imagingRequirement: string;
  imagingScanExamination: string;
  imagingBodyRegion: string;
  imagingContrastRequired: boolean | string;
  imagingRadiologyClinic: string;
  imagingAnatomicalMatchPriority: string;
  imagingInstructions: string;
  showImageOptions: boolean;
}
export interface SimulationType {
  treatmentSimulationId: string;
  treatmentCtSimulation: boolean;
  treatmentRORequired: boolean;
  treatmentPatientPosition: string;
  treatmentPositionSetup: string;
  treatmentExtentOfCt: string;
  treatmentExtentDefined: string;
  treatmentSpacerGel: boolean;
  treatmentGoldSeeds: boolean;
  treatmentBladder: string;
  treatmentBowelPrep: boolean;
  treatmentExtraCtMarkers: string;
  treatmentAdditionalSimInfo: string;
}

export interface SubmissionType {
  submissionId: string;
  submissionPdfUrl: string;
  submissionComments: string;
}

export interface SimulationField {
  value?: string;
  isShown?: boolean;
}

export interface PrescriptionType {
  prescriptionTreatmentSite: string;
  prescriptionPhase: string;
  prescriptionTechnique: string;
  prescriptionModality: string;
  prescriptionDose: string;
  prescriptionDoseHigh: string;
  prescriptionDoseLow: string;
  prescriptionDoseIntermediate: string[];
  prescriptionFractions: string;
  prescriptionFractionDose: string;
  prescriptionDoseTo: string;
  prescriptionFrequency: string;
  prescriptionCustomDoseFrequency: string;
  prescriptionImaging: string;
  prescriptionImagingTechnique: string;
  prescriptionAdditionalPrescriptionInfo: string;
}
export interface SiteType extends PrescriptionType {
  id: string;
  treatmentSite: string;
  laterality: string;
}

export interface SiteGroupType {
  siteGroupId: string;
  sites: SiteType[];
  treatment: SimulationType;
  imagingList: ImagingType[];
  voluming: VolumingType;
  submission: SubmissionType;
}

export interface CarePlanTemplateType {
  name: string;
  groups: SiteGroupType[];
}

export interface CarePlanState {
  careplan: CarePlanType;
  diagnosis: DiagnosisType;
  template: CarePlanTemplateType[];
  createCarePlan: any;
  updateCarePlanDiagnosis: any;
  history: any;
  creatingCareplan: boolean;
  intakePageMapping: Record<string, string>;
}

export const DEFAULT_CAREPLAN_STATE: CarePlanState = {
  careplan: { id: '', treatmentInfo: DEFAULT_TREATMENT_INFO },
  diagnosis: DEFAULT_DIAGNOSIS_TYPE,
  template: [],
  createCarePlan: (): void => {},
  updateCarePlanDiagnosis: (): void => {},
  history: {},
  creatingCareplan: false,
  intakePageMapping: {},
};

export const DEFAULT_SITE_DETAIL = {
  id: '',
  added: '',
  compliance: '',
  usage: 0,
  national: false,
};
export const DEFAULT_PRESCRIPTION = {
  prescriptionTreatmentSite: 'Lung',
  prescriptionPhase: '',
  prescriptionTechnique: '',
  prescriptionModality: '',
  prescriptionDose: '',
  prescriptionDoseHigh: '',
  prescriptionDoseLow: '',
  prescriptionDoseIntermediate: [],
  prescriptionFractions: '',
  prescriptionFractionDose: '',
  prescriptionDoseTo: '',
  prescriptionFrequency: '',
  prescriptionCustomDoseFrequency: '',
  prescriptionImaging: '',
  prescriptionImagingTechnique: '',
  prescriptionAdditionalPrescriptionInfo: '',
};
export const DEFAULT_SIMULATION = {
  treatmentSimulationId: '',
  treatmentCtSimulation: false,
  treatmentRORequired: false,
  treatmentPatientPosition: '',
  treatmentPositionSetup: '',
  treatmentExtentOfCt: '',
  treatmentExtentDefined: '',
  treatmentSpacerGel: false,
  treatmentGoldSeeds: false,
  treatmentBladder: '',
  treatmentBowelPrep: false,
  treatmentExtraCtMarkers: '',
  treatmentAdditionalSimInfo:
    'Bladder comfortably full. Bowel comfortably empty with no cross section of rectum > 50 mm',
};
export const DEFAULT_SITE = {
  id: '',
  treatmentSite: '',
  laterality: '',
  detail: { ...DEFAULT_SITE_DETAIL },
  ...DEFAULT_PRESCRIPTION,
};

export const DEFAULT_VOLUMING_DOSE_TEMPLATE = { label: '', value: '' };

export const DEFAULT_VOLUMING_DOSE_TEMPLATES = [
  { label: 'Rectum', value: '' },
  { label: 'FemHead_Neck_L', value: '' },
  { label: 'FemHead_Neck_R', value: '' },
  { label: 'Skin', value: '' },
];

export const DEFAULT_VOLUMING = {
  volumingGtv: '',
  volumingCtv: '',
  volumingPtv: '',
  volumingDose: '',
  volumingDoseTemplates: [...DEFAULT_VOLUMING_DOSE_TEMPLATES],
  volumingAdditionalOarInfo: '',
};

export const DEFAULT_SUBMISSION = {
  submissionId: '',
  submissionPdfUrl: 'https://ray-op.zh-everest.testing-genesis.care/server/static/sample/GenesisCare_pdf_sample.pdf',
  submissionComments: '',
};

export const DEFAULT_SITEGROUP = {
  siteGroupId: '',
  sites: [],
  treatment: { ...DEFAULT_SIMULATION },
  imagingList: [],
  voluming: { ...DEFAULT_VOLUMING },
  submission: { ...DEFAULT_SUBMISSION },
};

export interface ROPatientCarePlanRoute {
  id: string;
  siteIdx: string;
  careplanId: string;
  diagnosisId: string;
  siteGroupIdx: string;
  oncologyType: 'radiation' | 'medonc';
}

export interface CareplanFormikValues extends IntakeFormikValues {
  diagnosis: DiagnosisType;
  intakePage: string;
  treatmentInfo: TreatmentInfoType;
  user: any;
}
