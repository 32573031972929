// eslint-disable-next-line no-use-before-define
import React from 'react';
import { ToggleButtonGroup, ToggleButton, useTheme, ToggleButtonGroupProps, Stack } from '@mui/material';
import ROHelperText from '../ROHelperText/ROHelperText';
import { getBorderColor } from '../common';

export interface Props extends ToggleButtonGroupProps {
  id: string;
  handleChange: (value: string) => void;
  options: ButtonOptions[];
  warning?: boolean;
  info?: boolean;
  error?: boolean;
  helperText?: string;
  alwaysShowHelper?: boolean;
  neverShowHelper?: boolean;
}

interface ButtonOptions {
  label: string;
  value: string;
}

const ToggleButtons = (props: Props): JSX.Element => {
  const {
    id,
    sx,
    options,
    handleChange,
    error,
    warning,
    info,
    neverShowHelper,
    alwaysShowHelper,
    helperText,
    fullWidth,
    ...rest
  } = props;
  const theme = useTheme();

  const showHelperText = !neverShowHelper && (alwaysShowHelper || error || warning || info);
  const helperTextProps = {
    error,
    warning,
    info,
    helperText,
    id,
  };

  return (
    <Stack sx={{ width: fullWidth ? '100%' : 'default', gap: '4px' }}>
      <ToggleButtonGroup
        id={id}
        data-testid={id}
        exclusive
        fullWidth={fullWidth}
        onChange={(_, newAlignment: string): void => {
          if (newAlignment === null) return; // Enforces at least one is active
          handleChange(newAlignment);
        }}
        sx={{ ...{ whiteSpace: 'nowrap', height: '100%' }, ...sx }}
        {...rest}>
        {options.map((option: ButtonOptions): JSX.Element => {
          const toggleId = `toggle-button-${option.value.replace(/\s/g, '')}`;
          const borderColor = getBorderColor(error, warning, info);

          return (
            <ToggleButton
              id={toggleId}
              sx={{
                color: theme.palette.text.primary,
                maxHeight: '37px',
                fontSize: '14px',
                textTransform: 'capitalize',
                borderColor: borderColor,
                fontWeight: '400',
                '&.MuiToggleButton-root.Mui-selected': {
                  // TODO: replace with MUI theme override
                  backgroundColor: theme.palette.action.selected,
                  color: theme.palette.text.primary,
                },
                ':hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                padding: fullWidth ? '8px 4px' : '8px',
              }}
              color="primary"
              data-testid={`${id + option.value}`}
              key={option.value}
              disabled={props?.disabled}
              value={option.value}>
              {option.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {showHelperText && <ROHelperText {...helperTextProps} />}
    </Stack>
  );
};
export default ToggleButtons;
