// eslint-disable-next-line no-use-before-define
import React, { useMemo, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';
import {
  VARIATION_MESSAGE,
  SUBMISSION_MESSAGE,
  CPOT_SUBMISSION_MESSAGE,
  UNSUBMITTED_MESSAGE,
  CPOT_MESSAGE,
} from '../Constants';
import { Success, Warning } from 'shared-components/images';
import './Banner.scss';
import moment from 'moment';
import { GET_PRACTITIONER } from 'op-graphql/queries';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { useQuery, useMutation } from '@apollo/client';
import { useRouteMatch } from 'react-router';
import { DISCARD_CAREPLAN_CHANGES } from '../Queries';
import { useTheme, Typography } from '@mui/material';

const mapStatus = {
  SIMULATION: 'Simulation',
  PLAN_AIM: 'Plan Aim',
  PRESCRIPTION: 'Prescription',
  PRESCRIPTION_DIRECTIVE: 'Prescription Directive',
};

const renderDiscardChangesModal = (
  handleClose: any,
  handleDiscardChanges: any,
  parentLastSubmissionDate: any,
  parentCareplanStatus: string,
) => {
  return (
    <Modal width={'small'} id={'outcome-modal'} open>
      <ModalHeader>{'Discard unsubmitted changes'}</ModalHeader>
      <ModalBody>
        {`You’ll lose all unsubmitted changes, and revert to the last submitted version ‘${
          mapStatus[parentCareplanStatus as keyof typeof mapStatus]
        }’ at ${parentLastSubmissionDate}. Are you sure you want to discard unsubmitted changes?`}
      </ModalBody>
      <ModalFooter>
        <Button mode="outlined" size="auto" onClick={handleClose} data-cy={'back-discard-changes-modal'}>
          Back
        </Button>
        <Button
          mode="contained"
          size="small"
          onClick={() => {
            handleDiscardChanges();
            handleClose();
          }}
          data-cy={'continue-discard-changes-modal'}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const VariationBanner = ({ variationMessage }: { variationMessage?: string }): JSX.Element => (
  <div className="ro-careplan-variation-warning">
    <div className="variation-warning-content">
      <Warning className="icon icon-form-warning" />
      <Typography variant="body2" className="variation-warning-text">
        {variationMessage || VARIATION_MESSAGE}
      </Typography>
    </div>
  </div>
);

export const SubmissionBanner = (): JSX.Element => (
  <div className="ro-careplan-submission-banner">
    <div className="submission-banner-content">
      <Success className="icon icon-form-submission" />
      <Typography variant="body2" className="submission-banner-text">
        {SUBMISSION_MESSAGE}
      </Typography>
    </div>
  </div>
);

export const CpotSubmissionBanner = (): JSX.Element => (
  <div className="ro-careplan-submission-banner">
    <div className="submission-banner-content">
      <Success className="icon icon-form-submission" />
      <Typography variant="body2" className="submission-banner-text">
        {CPOT_SUBMISSION_MESSAGE}
      </Typography>
    </div>
  </div>
);

export const UnsubmittedBanner = ({
  lastSubmissionData,
  carePlanStatus,
  handleDiscardChanges,
  parentLastSubmissionDate,
  parentCareplanStatus,
}: any): JSX.Element => {
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const theme = useTheme();

  return (
    <div className="ro-careplan-unsubmitted-banner">
      {showDiscardModal &&
        renderDiscardChangesModal(
          () => setShowDiscardModal(false),
          handleDiscardChanges,
          parentLastSubmissionDate,
          parentCareplanStatus,
        )}
      <div className="unsubmitted-banner-content">
        <Warning className="icon icon-form-unsubmitted" />
        <Typography data-testid="unsubmitted-banner" variant="body2">
          {UNSUBMITTED_MESSAGE}
          {lastSubmissionData &&
            ` Last submitted as '${mapStatus[carePlanStatus as keyof typeof mapStatus]}' at: ${lastSubmissionData}`}
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          <button
            onClick={() => setShowDiscardModal(true)}
            style={{ color: theme.palette.info.main, background: 'none', textDecoration: 'underline', border: 'none' }}>
            Discard Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export const CpotBanner = (): JSX.Element => (
  <div className="ro-careplan-variation-warning">
    <div className="variation-warning-content">
      <Warning className="icon icon-form-warning" />
      <Typography variant="body2" className="variation-warning-text">
        {CPOT_MESSAGE}
      </Typography>
    </div>
  </div>
);

// Check for submission date time
interface CareplanHasSubmitted {
  submittedAt: string;
}
export const hasSubmitted = (careplan: CareplanHasSubmitted, practitionerTimezone: string): string | boolean => {
  if (careplan && careplan.submittedAt && practitionerTimezone) {
    const submittedDate = moment(careplan.submittedAt).tz(practitionerTimezone);
    return submittedDate.format('D MMM YYYY, h:mm a');
  }
  return false;
};

// Check for submission and if either value has modification
interface CareplanChangesFromSubmitted {
  submittedAt: string;
  simulationModification: boolean | null;
  prescriptionModification: boolean | null;
  diagnosisModification: boolean | null;
}
export const changesFromLastEdited = (careplan: CareplanChangesFromSubmitted): boolean => {
  if (
    careplan &&
    careplan.submittedAt &&
    (careplan.simulationModification || careplan.prescriptionModification || careplan.diagnosisModification)
  ) {
    return true;
  }
  return false;
};

export const CareplanBanners = ({
  data,
  variation,
  variationMessage,
  forceUpdate,
  refetchQueriesList,
}: {
  data?: any;
  variation?: boolean;
  variationMessage?: string;
  forceUpdate?: any;
  refetchQueriesList?: any;
}): JSX.Element => {
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const careplan = data?.careplan;
  const carePlanStatus = data && data.careplan && data.careplan.careplanStatus;
  const cpotTriggered = carePlanStatus === 'CPOT';
  const cpotSubmitted = carePlanStatus === 'PRESCRIPTION' && data?.careplan?.changeType === 'CPOT';
  const parent = careplan?.parent;
  const parentCarePlanStatus = parent?.careplanStatus;

  const { data: pracData } = useQuery(GET_PRACTITIONER, {
    variables: { patientId },
    skip: !patientId,
  });
  const practitionerTimezone = useMemo(
    () => pracData?.practitioner?.timezone || CurrentAppConfig.DefaultTimezone,
    [pracData?.practitioner?.timezone],
  );

  const [discardCareplanChanges] = useMutation(DISCARD_CAREPLAN_CHANGES, {
    awaitRefetchQueries: true,
    refetchQueries: [...(refetchQueriesList ? refetchQueriesList : [])],
  });

  const handleDiscardChanges = () => {
    discardCareplanChanges({ variables: { id: careplan?.id || 0 } }).then((s: any) => {
      if (forceUpdate) {
        forceUpdate();
      }
    });
  };

  return (
    <div className="ro-careplan-banner">
      {['Prescription', 'Plan Aim', 'Prescription Directive'].includes(
        mapStatus[carePlanStatus as keyof typeof mapStatus],
      ) &&
        hasSubmitted(careplan, practitionerTimezone) &&
        !changesFromLastEdited(data?.careplan) &&
        (cpotSubmitted ? <CpotSubmissionBanner /> : <SubmissionBanner />)}
      {!cpotTriggered && hasSubmitted(careplan, practitionerTimezone) && changesFromLastEdited(data?.careplan) && (
        <UnsubmittedBanner
          lastSubmissionData={hasSubmitted(careplan, practitionerTimezone)}
          carePlanStatus={carePlanStatus}
          handleDiscardChanges={handleDiscardChanges}
          parentLastSubmissionDate={parent ? hasSubmitted(careplan, practitionerTimezone) : undefined}
          parentCareplanStatus={parentCarePlanStatus}
        />
      )}
      {variation && !cpotTriggered && <VariationBanner variationMessage={variationMessage} />}
      {cpotTriggered && <CpotBanner />}
    </div>
  );
};
