import { gql } from '@apollo/client';

export const ON_TREATMENT_REVIEW_MUTATION = gql`
  mutation createTreatmentReview(
    $patientId: ID!
    $reviewDate: Date!
    $careplan: String!
    $treatmentFractionsReviewed: String!
    $responseToTreatment: String!
    $imageReview: String!
    $managementDecision: String!
    $clinicalExamination: String!
    $dss: JSONString!
  ) {
    createTreatmentReview(
      patientId: $patientId
      reviewDate: $reviewDate
      careplan: $careplan
      treatmentFractionsReviewed: $treatmentFractionsReviewed
      responseToTreatment: $responseToTreatment
      imageReview: $imageReview
      managementDecision: $managementDecision
      clinicalExamination: $clinicalExamination
      dss: $dss
    ) {
      treatmentReview {
        id
      }
    }
  }
`;

export const GET_ON_TREATMENT_REVIEW_LIST = gql`
  query getOnTreatmentReviewList($patientId: ID!) {
    treatmentReviewList(patientId: $patientId) {
      id
      reviewDate
      careplan
      treatmentFractionsReviewed
      responseToTreatment
      imageReview
      managementDecision
      clinicalExamination
      dss
      status
      submittedAt
      lastSubmittedAt
    }
  }
`;

export const CREATE_ON_TREATMENT_REVIEW = gql`
  mutation createOnTreatmentReview($patientId: ID!, $careplan: String!, $dss: JSONString!) {
    createOnTreatmentReview(patientId: $patientId, careplan: $careplan, dss: $dss) {
      review {
        id
      }
    }
  }
`;

export const UPDATE_ON_TREATMENT_REVIEW = gql`
  mutation updateOnTreatmentReview(
    $id: ID!
    $treatmentFractionsReviewed: String!
    $responseToTreatment: String!
    $imageReview: String!
    $managementDecision: String!
    $clinicalExamination: String!
  ) {
    updateOnTreatmentReview(
      id: $id
      treatmentFractionsReviewed: $treatmentFractionsReviewed
      responseToTreatment: $responseToTreatment
      imageReview: $imageReview
      managementDecision: $managementDecision
      clinicalExamination: $clinicalExamination
    ) {
      review {
        id
        currentChanges
      }
    }
  }
`;

export const SUBMIT_ON_TREATMENT_REVIEW = gql`
  mutation submitOnTreatmentReview($id: ID!) {
    submitOnTreatmentReview(id: $id) {
      review {
        id
        reviewDate
        careplan
        treatmentFractionsReviewed
        responseToTreatment
        imageReview
        managementDecision
        clinicalExamination
        dss
        status
        submittedAt
        lastSubmittedAt
      }
    }
  }
`;

export const DISCARD_ON_TREATMENT_REVIEW = gql`
  mutation discardOnTreatmentReview($id: ID!) {
    discardOnTreatmentReview(id: $id) {
      review {
        id
        reviewDate
        careplan
        treatmentFractionsReviewed
        responseToTreatment
        imageReview
        managementDecision
        clinicalExamination
        dss
        status
        submittedAt
        lastSubmittedAt
        currentChanges
      }
    }
  }
`;

export const GET_ON_TREATMENT_REVIEW = gql`
  query getOnTreatmentReview($id: ID!) {
    treatmentReview(id: $id) {
      id
      reviewDate
      careplan
      treatmentFractionsReviewed
      responseToTreatment
      imageReview
      managementDecision
      clinicalExamination
      dss
      status
      submittedAt
      lastSubmittedAt
      currentChanges
    }
  }
`;

export const DELETE_ON_TREATMENT_REVIEW = gql`
  mutation deleteOnTreatmentReview($id: ID!) {
    deleteOnTreatmentReview(id: $id) {
      success
    }
  }
`;
