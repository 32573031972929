import React from 'react';
import GC_LOGO from 'shared-components/images/gclogo.png';
import Cookies from 'js-cookie';

import {
  Form,
  FormContent,
  FormSection,
  SectionField,
  FreeTextField,
  GCButton,
} from 'shared-components/components/FormComponents';

import { ButtonType } from 'shared-components/enums';
import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import './Login.scss';

import { LoginResults, RedirectionParams } from './Helper';

import { Box, Stack } from '@mui/system';
import { ErrorCross } from 'shared-components/images';

export const DEFAULT_DASHBOARD = '/px/appointments';
const PAGE_TITLE = 'Welcome!';
const COUNTER_DURATION = 60000; // 1 minutes
const LOCKOUT_DURATION = COUNTER_DURATION * 5; // 5 minutes
const USERNAME_TITLE = 'Email Address';
const PASSWORD_TITLE = 'Password';
const LOGIN_ERROR = 'Email or password was incorrect!';
const LOGIN_RATE_LIMIT_MESSAGE = 'Maximum login attempts reached, you can try again in <n_minutes>.';
const LOGIN_RATE_LIMIT_MINUTES = 5;
const LOGIN_TITLE = 'Log In';
const GC_PATIENT_EMAIL = 'patientportal@genesiscare.com';
const API_ENDPOINT_LOGIN_PATH = '/server/patient/auth/login';
const API_ENDPOINT_TRUSTED_AGENT_PATH = '/server/patient/auth/trusted_agent';

const PatientLogin = () => {
  const [loginError, setLoginError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitButtonLocked, setSubmitButtonLocked] = React.useState<boolean>(false);
  const [bypassMFA, setBypassMFA] = React.useState<boolean>(false);
  const [passwordExpired, setPasswordExpired] = React.useState<boolean>(false);

  const [formData, setFormData] = React.useState<{ [key: string]: string }>({
    username: '',
    password: '',
  });
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback((): void => updateState({}), []);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const theme = useTheme();
  const history = useHistory();

  const clearErrorWarning = (): void => {
    setLoginError(false);
  };

  const validateCallback = (key: string, newValue: string): void => {
    // Currently has no validation rules
    const validationObject: { [key: string]: string } = {
      ...formData,
      [key]: newValue,
    };
    setFormData(validationObject);
  };

  const forgotPasswordRedirect = (): void => {
    const { username } = formData;
    history.push({
      pathname: '/px/forgotPassword',
      state: {
        username: username,
      },
    });
  };

  const errorMessage = (): string => {
    const sessionMins = sessionStorage.getItem('minutesUntilSubmitButtonUnlocked');
    const minutesUntilSubmitButtonUnlocked = sessionMins ? parseInt(sessionMins) : 0;
    if (minutesUntilSubmitButtonUnlocked > 0) {
      return LOGIN_RATE_LIMIT_MESSAGE.replace(
        '<n_minutes>',
        minutesUntilSubmitButtonUnlocked > 1 ? `${minutesUntilSubmitButtonUnlocked} minutes` : '1 minute',
      );
    } else {
      return LOGIN_ERROR;
    }
  };

  const removeButtonLock = (): void => {
    setSubmitButtonLocked(false);
    setLoginError(false);
    sessionStorage.removeItem('submitButtonLocked');
    sessionStorage.removeItem('minutesUntilSubmitButtonUnlocked');
    sessionStorage.removeItem('epochEndTime');
  };

  const updateLockSubmitButton = (): void => {
    const sessionMins = sessionStorage.getItem('minutesUntilSubmitButtonUnlocked');
    const currentMinutesLeft = parseInt(sessionMins || '5') - 1;
    const epochEndTime = Number(sessionStorage.getItem('epochEndTime'));
    const epochCurrentTime = Date.now();
    if (currentMinutesLeft <= 0 || epochCurrentTime >= epochEndTime) {
      removeButtonLock();
    } else {
      sessionStorage.setItem('minutesUntilSubmitButtonUnlocked', String(currentMinutesLeft));
      forceUpdate();
      waitForLockSubmitButtonUpdate();
    }
  };

  const waitForLockSubmitButtonUpdate = () => {
    setTimeout(() => {
      updateLockSubmitButton();
    }, COUNTER_DURATION);
  };

  const lockSubmitButton = (): void => {
    setSubmitButtonLocked(true);

    const epochEndTime = Date.now() + LOCKOUT_DURATION;
    sessionStorage.setItem('submitButtonLocked', String(true));
    sessionStorage.setItem('minutesUntilSubmitButtonUnlocked', String(LOGIN_RATE_LIMIT_MINUTES));
    sessionStorage.setItem('epochEndTime', String(epochEndTime));
    forceUpdate();
    //  Trigger the count down
    setTimeout(() => {
      waitForLockSubmitButtonUpdate();
    }, COUNTER_DURATION);
  };

  //   On Load check the session storage to see if the submit button is locked
  React.useEffect(() => {
    const epochEndTime = sessionStorage.getItem('epochEndTime');
    const submitButtonLocked = sessionStorage.getItem('submitButtonLocked');

    const currentTime = Date.now();
    let minutes = 0;

    if (epochEndTime) {
      const remainder = Number(epochEndTime) - currentTime;
      minutes = Math.floor(remainder / COUNTER_DURATION);
      if (minutes > 0) {
        sessionStorage.setItem('minutesUntilSubmitButtonUnlocked', String(minutes));
      }
    }

    if (submitButtonLocked === 'true' && minutes > 0) {
      setSubmitButtonLocked(true);
      setLoginError(true);
    }

    waitForLockSubmitButtonUpdate();
  }, []);

  const loginSuccess = (user: LoginResults['user'], token: string): void => {
    // Make sure TS is happy and we have a user. Should not run into this unless backend does not match interface
    if (!user) {
      throw Error('User is not defined');
    }
    const scope = Sentry.getCurrentScope();
    scope.setTag('user_type', 'patient');
    scope.setUser({
      id: user.id,
      username: user.username,
    });

    // Check to see if the mfa is disabled
    // As long as the login has worked, proceed onwards to the multi factor page
    let redirectionParams: RedirectionParams = {
      pathname: '/px/mfa/',
      state: { next: DEFAULT_DASHBOARD, mfaToken: token, user: user },
    };

    // Check to determine if the password has expired to redirect the patient to the change password screen
    if (passwordExpired) {
      redirectionParams = {
        pathname: '/px/changePassword/',
        state: { next: DEFAULT_DASHBOARD, user, resetPassword: true },
      };
    }

    // Change the redirection to the next in the search params
    if ((user.mfaDisabled || bypassMFA) && DEFAULT_DASHBOARD) {
      redirectionParams = { pathname: DEFAULT_DASHBOARD };
    }

    history.push(redirectionParams);
  };

  const getCSRFCookie = (): string => {
    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
      return csrfToken;
    }
    return 'invalid_token';
  };

  const onSubmitHandler = (): void => {
    // const { username, password } = formData;
    // Get values directly from dom as firefox autocomplete does not update the react state
    const username_field = document.getElementsByName('username')[0] as HTMLInputElement;
    const password_field = document.getElementsByName('password')[0] as HTMLInputElement;

    setSubmitButtonLocked(true);
    setLoading(true);
    // TODO add validation
    const loginData = {
      username: username_field.value,
      password: password_field.value,
    };
    if (loginData.username === '' || loginData.password === '') {
      setLoginError(true);
      setLoading(false);
      return;
    }
    axios
      .post(API_ENDPOINT_LOGIN_PATH, loginData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': getCSRFCookie(),
        },
      })
      .then((response): void => {
        // Redirection
        const results = response.data as LoginResults;
        // Get whether the agent is trusted or not to determine whether to bypass mfa
        axios
          .get(API_ENDPOINT_TRUSTED_AGENT_PATH, {
            headers: {
              'X-CSRFTOKEN': getCSRFCookie(),
            },
          })
          .then((trustedAgentResponse): void => {
            setLoading(false);
            setLoginError(false);

            // If the user is a trusted agent, bypass mfa
            setBypassMFA(trustedAgentResponse.data.trustedAgent);
            // If the user's password is expired, redirect to reset password page
            setPasswordExpired(trustedAgentResponse.data.passwordExpired);

            loginSuccess(results.user, trustedAgentResponse.data.token);
          });
      })
      .catch((error): void => {
        if (error.response.status === 403) {
          setLoading(false);
          setLoginError(true);
          lockSubmitButton();
        } else {
          // Unauthorized error
          setLoading(false);
          setLoginError(true);
          setSubmitButtonLocked(false);

          forceUpdate();
        }
        // React dosen't seem to like rerendering on axios error so force it to re-render
        forceUpdate();
      });
  };

  return (
    <Container className="auth-container" maxWidth={false}>
      {/* <div className="auth-container"> */}
      <div
        className="auth-container-inner"
        style={{
          padding: '2em',
        }}>
        <div className="welcome-container">
          <Stack justifyContent="center">
            <Box>
              <img src={GC_LOGO} alt="GenesisCare Logo" width={60} />
            </Box>
            <Typography variant="h4" sx={{ marginTop: '1.25rem' }}>
              {PAGE_TITLE}
            </Typography>
            <Typography variant="subtitle1" color={theme.palette.grey[600]}>
              Enter your password to Log in
            </Typography>
          </Stack>
        </div>
        <Form id="patient-login-form" formData={{ formData: formData }} submit={onSubmitHandler}>
          <FormContent>
            <FormSection>
              <SectionField htmlFor="username" title={USERNAME_TITLE} invalidInput={loginError}>
                <FreeTextField
                  fieldName="username"
                  modelValue={formData['username']}
                  validateField={validateCallback}
                  invalidInput={loginError}
                  showErrorMessage={false}
                  placeholder=""
                  updateParent={clearErrorWarning}
                />
              </SectionField>
              <SectionField htmlFor="password" title={PASSWORD_TITLE} invalidInput={loginError}>
                <FreeTextField
                  fieldName="password"
                  modelValue={formData['password']}
                  validateField={validateCallback}
                  invalidInput={loginError}
                  showErrorMessage={false}
                  placeholder=""
                  secure={true}
                  updateParent={clearErrorWarning}
                />
              </SectionField>
              {/* If there is an error take the auth error and render it */}

              {loginError && (
                <Stack direction="row" sx={{ marginBottom: '15px' }} alignItems={'center'}>
                  <Box>
                    <ErrorCross className="icon" />
                  </Box>
                  <Typography
                    variant="body2"
                    color={theme.palette.error.dark}
                    sx={{
                      marginLeft: '10px',
                    }}>
                    {errorMessage()}
                  </Typography>
                </Stack>
              )}
              {submitButtonLocked}
              <div className="flex-horizontal-center">
                <GCButton
                  onClick={(e): void => {
                    if (e) {
                      e.preventDefault();
                    }
                    onSubmitHandler();
                  }}
                  name="login"
                  type={submitButtonLocked ? ButtonType.DISABLED : ButtonType.GREEN}
                  inputType="submit"
                  disabled={submitButtonLocked}
                  loading={loading}
                  title={LOGIN_TITLE}
                />
              </div>
              {timeZone.includes('Australia') && (
                <div className="flex-horizontal-center">
                  <div
                    className="link"
                    onClick={(): void => {
                      forgotPasswordRedirect();
                    }}>
                    Reset Password
                  </div>
                </div>
              )}
            </FormSection>
          </FormContent>
        </Form>
        <div id="trouble-container">
          {timeZone.includes('Australia') && (
            <>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.grey[600],
                }}>
                Having trouble logging in? Contact support
              </Typography>
              <a href={`mailto: ${GC_PATIENT_EMAIL}`}>{GC_PATIENT_EMAIL}</a>
            </>
          )}
          {!timeZone.includes('Australia') && (
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.grey[600],
              }}>
              GenesisCare Patient Portal is available to Australian patients only, please contact your clinic for any
              further enquiries.
            </Typography>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PatientLogin;
