import { useTheme } from '@mui/material';

export const translateTimePoint = (timePoint: number): string => {
  const timePointTranslation = {
    0.5: '3-6 months',
    0.75: '6-9 months',
    1: '12 months',
  };
  // @ts-ignore
  return timePointTranslation[timePoint] || `${timePoint} years`;
};

export const getStatusIndicatorFillColour = (status: string): string => {
  const theme = useTheme();
  if (status === 'Submitted') {
    return theme.palette.primary.dark;
  }
  if (status === 'In Progress' || status === 'Unsubmitted Changes') {
    return theme.palette.warning.dark;
  }
  if (status === 'Not started') {
    return theme.palette.grey[600];
  }
  return theme.palette.grey[600];
};
