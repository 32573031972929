import React from 'react';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { BannerType, OnTreatmentReviewType } from './Interface';
import dayjs from 'dayjs';
interface DiscardDialogProps {
  discardCallback: () => void;
  cancelCallback: () => void;
  open: boolean;
  bannerType: BannerType;
  review: OnTreatmentReviewType;
}

const DiscardDialog = ({
  discardCallback,
  cancelCallback,
  bannerType,
  open,
  review,
}: DiscardDialogProps): JSX.Element => {
  const title = bannerType === BannerType.DRAFT ? 'Discard draft' : 'Discard unsubmitted changes';
  const bodyText =
    bannerType === BannerType.DRAFT ? (
      <>
        <Typography variant="body1" style={{ fontSize: '16px' }}>
          You are about to discard the changes to {review.careplan}?
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="body1" style={{ fontSize: '16px' }}>
          You'll lose all unsubmitted changes, and revert to the last submitted version at{' '}
          {dayjs(review.lastSubmittedAt).format('DD MMM YYYY, hh:mm A')}.
        </Typography>
        <Typography variant="body1" style={{ fontSize: '16px' }}>
          Are you sure you want to discard unsubmitted changes?
        </Typography>
      </>
    );
  const okText = bannerType === BannerType.DRAFT ? 'DISCARD DRAFT' : 'DISCARD CHANGES';
  const cancelText = 'BACK';
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={cancelCallback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" style={{ fontSize: '20px', fontWeight: 500 }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{bodyText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="dialog-cancel-btn" onClick={cancelCallback}>
            {cancelText}
          </Button>
          <Button data-testid="dialog-discard-btn" onClick={discardCallback} color="error" autoFocus>
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DiscardDialog;
