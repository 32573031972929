import React, { useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';
import { styled } from '@mui/system';
import { FractionDoseModalInterface } from './Interface';
import { WarningOutlined as WarningOutlinedIcon } from '@mui/icons-material';
import { TenantContext } from 'op-contexts';
import { DoseUnit } from 'op-enums';

const AdjustButtonWrapper = styled('div')`
  margin-right: 8px;
`;

const HeaderTitleWrapper = styled('span')`
  margin-right: 9px;
`;

const FractionDoseWarningModal: React.FC<FractionDoseModalInterface> = ({ isOpen, handleClose, handleContinue }) => {
  const { doseUnit } = useContext(TenantContext);
  const value = doseUnit === DoseUnit.CGY ? '500' : '5.0';
  return (
    <Modal width="small" id="outcome-modal" open={isOpen}>
      <ModalHeader>
        <HeaderTitleWrapper>Dose per fraction</HeaderTitleWrapper>
        <WarningOutlinedIcon color="warning" />
      </ModalHeader>
      <ModalBody>
        {`The daily dose per fraction is >${value}${doseUnit}. Please confirm or adjust the value before continuing.`}
      </ModalBody>
      <ModalFooter>
        {/* TODO Temporary width fix for the modal buttons until that's standardized in GC-UI */}
        <AdjustButtonWrapper>
          <Button
            mode="outlined"
            size="auto"
            onClick={() => handleClose(false)}
            data-cy="5g-exceeded-fraction-adjust-button">
            Adjust
          </Button>
        </AdjustButtonWrapper>
        <Button
          mode="contained"
          size="small"
          onClick={() => handleContinue()}
          data-cy="5g-exceeded-fraction-confirm-button">
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FractionDoseWarningModal;
