// Has to be the first import for I.E
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line no-use-before-define
import { ApolloClient, ApolloLink, ApolloProvider, Operation } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import Cookies from 'js-cookie';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import fetch from 'unfetch';
import { ThemeContext } from './contexts';

import 'shared-components/Styles/Main.scss';
import 'shared-components/components/Modals/Modal.scss';

import { ApolloHandlerModel } from 'shared-components/models';
import { FormatSpecialCharactersMiddleware, SetMomentLocale } from 'shared-components/utils';
import Favicon from 'react-favicon';
import {
  ErrorModalContextProvider,
  NavigationContextProvider,
  ROBase,
  Refresh,
  RegistrationContextProvider,
  region,
} from 'op-components';
import { isDemo } from 'op-utils';
import { AssessmentProvider } from 'op-pages/OP/PatientFormSummary/context';
import { Region } from 'shared-components/enums';

import { gql } from '@apollo/client';
import { CACHE_ERROR_MSG_QUERY } from 'op-components/ErrorModalContextProvider/ErrorModalQueries';
import {
  AppSelector,
  CovidContainer,
  CreatePatient,
  DTContainer,
  DocumentsPageRouter,
  ErrorPage,
  HAContainer,
  HomeRegistrationLogin,
  HomeRegoEndSession,
  InformationNotice,
  LinkExpired,
  LogoutOP,
  MOBase,
  MOCreatePatient,
  MODashboard,
  MOPatientCarePlanPage,
  MOPatientSummary,
  ManagementPlan,
  Notes,
  OutcomesPage,
  PSODashboard,
  PXChangePassword,
  PXForgotPassword,
  PXLinkExpired,
  PXLogin,
  PXResetLink,
  PXResetPassword,
  PXSignup,
  PatientFormSummaryApollo,
  PatientNavigationApollo,
  PreCtChartCheckApollo,
  PreCtTriageApollo,
  ROCreatePatient,
  ROPatientCarePlanPage,
  ROPatientLabs,
  ROPatientSummaryPage,
  RegistrationAU,
  RegistrationAddressApollo,
  RegistrationBasicApollo,
  RegistrationComplete,
  RegistrationConflictsApollo,
  RegistrationContactDetailsApollo,
  RegistrationFileAttachmentsApollo,
  RegistrationInformationNoticeApollo,
  RegistrationInsuranceApollo,
  RegistrationLoginApollo,
  RegistrationPreferencesApollo,
  RegistrationUS,
  SSOPage,
  SessionTimeout,
  Summary,
  UKAppointmentsDashboardApollo,
  UKHomePage,
  UKPatientSearch,
  UKRegistrationDemographicsApollo,
  UKRegistrationGPApollo,
  UKRegistrationSummaryApollo,
  PXAppointments,
  MappingUpdate,
  PXTermsAndConditions,
  PXFAQContentContainer,
  PXFAQHome,
  PXContentContainer,
  PXInformation,
  PXFeedback,
  PXPatientDetails,
  PXMultifactorAuth,
  PXHome,
  InsightsDashboard,
  ROPatientTracker,
  ROPatientPathway,
  OnTreatmentReview,
  OnTreatmentReviewForm,
} from 'op-pages';
import UKRegistrationAltContactApollo from 'op-pages/OP/RegistrationForm/UKRegistrationAltContact/UKRegistrationAltContactApollo';
import SentryUser from 'shared-components/components/Sentry/SentryUser';
import { Logger } from 'shared-components/utils';
import { FormContextProvider } from './pages/OP/PatientNavigation/context';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { datadogRum } from '@datadog/browser-rum';
import DatadogUserInfo from 'shared-components/components/DatadogUserInfo/DatadogUserInfo';
import { GCTheme, LumonusTheme } from 'theme';
import Dashboard from 'op-pages/RO/Dashboard/Dashboard';
import OutcomeFormPage from 'op-pages/RO/Outcomes/OutcomeFormPage';

const logger = new Logger('index');
const defaultTheme = import.meta.env.REACT_APP_THEME === 'Lumonus' ? LumonusTheme : GCTheme;

// datadog rum init
const ALLOWED_ENVS = ['production', 'demo'];
if (ALLOWED_ENVS.includes(import.meta.env.DATADOG_RUM_ENV)) {
  const appId = import.meta.env.DATADOG_RUM_APP_ID;
  const clientToken = import.meta.env.DATADOG_RUM_CLIENT_TOKEN;
  const rumEnv = import.meta.env.DATADOG_RUM_ENV;

  const loggerName = 'DataDog RUM init';
  if (!appId) {
    logger.error(loggerName, 'DATADOG_RUM_APP_ID not set');
  } else if (!clientToken) {
    logger.error(loggerName, 'DATADOG_RUM_CLIENT_TOKEN not set');
  } else {
    datadogRum.init({
      applicationId: appId,
      clientToken: clientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'horizon',
      env: rumEnv,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: isDemo,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
    });
  }
}

// Set the moment locale -- this may need to change when the language is to be settable by the patient/user
SetMomentLocale();

const logUndefinedError = (networkError = {} as any, operation: Operation) => {
  const requestInfo = {
    operationName: operation?.operationName ?? 'Unknown',
    body: operation?.query?.loc?.source?.body ?? 'Unknown',
    variables: JSON.stringify({
      id: operation.variables?.id || 'Unknown',
    }),
    networkResponse: JSON.stringify(networkError?.response ?? networkError?.target ?? {}),
    networkBody: networkError?.bodyText ?? JSON.stringify(networkError) ?? 'Unknown',
  };

  const functionName = networkError?.response ? networkError?.response : `Operation ${operation?.operationName} Failed`;

  logger.error(functionName, requestInfo);

  // Triggers the error modal
  client.writeQuery({
    query: CACHE_ERROR_MSG_QUERY,
    data: {
      error: {
        __typename: 'Error',
        message: 'Network Undefined Error',
        statusCode: 0,
      },
    },
  });
};

// Error handler
const errorHandlerMiddleware = onError(({ graphQLErrors, networkError = {} as any, operation }): void => {
  const statusCode: number = networkError.statusCode ?? -1;

  if (statusCode === 503) {
    window.location.reload();
  } else if (statusCode === 403) {
    window.location.replace('/sso/login');
  } else if (networkError?.type === 'error' && statusCode === -1) {
    logUndefinedError(networkError?.target, operation);
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    // @ts-ignore
    const graphQLErrorMessage = ApolloHandlerModel.determineError(graphQLErrors[0]);
    if (graphQLErrorMessage && graphQLErrorMessage.statusCode) {
      // HRO and Navigator errors
      if (
        window.location.pathname.startsWith('/radiation') ||
        window.location.pathname.startsWith('/navigator') ||
        window.location.pathname.startsWith('/medonc') ||
        window.location.pathname.endsWith('/patient/create')
      ) {
        client.writeQuery({
          query: CACHE_ERROR_MSG_QUERY,
          data: {
            error: {
              __typename: 'Error',
              message: graphQLErrorMessage.message,
              statusCode: graphQLErrorMessage.statusCode,
            },
          },
        });
      }
      // Non HRO errors
      switch (graphQLErrorMessage.statusCode) {
        case 403:
          // Get the current path where the 403 occurred
          window.location.replace('/sso/login');
          break;
        default:
          // Not really adding value from a Sentry logging perspective, spammy (also merged with logUndefinedErrors)
          // logger.error('loginLink', `Graphql error, Undefined case. Status code: ${graphQLErrorMessage.statusCode}`);
          break;
      }
    }
  }
});

const httpLink = createHttpLink({ uri: '/server/graphql', fetch });

const csrfMiddleware = new ApolloLink((operation: any, forward) => {
  operation.setContext(({ headers = {} }: { headers: any }): any => ({
    headers: {
      ...headers,
      'X-CSRFTOKEN': Cookies.get('csrftoken'),
    },
  }));

  return forward(operation);
});

const EXTEND_LOCK = 'ExtendLock';
const OPERATIONS_TO_IGNORE = [EXTEND_LOCK];

let sessionTimeout: NodeJS.Timeout;
// Handle redirecting the user when their session has expired
const sessionTimeoutLink = new ApolloLink((operation: any, forward: any) => {
  return forward(operation).map((response: any) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    const expiry = headers.get('X-Expiry-Seconds');

    // currently timeout set for 15 mins for patients, 24h for staff
    const userRedirectMapping = {
      staff: '/',
      patientInClinic: '/patient',
      patientOpHomeRego: '/timeout',
      patientPortal: '/timeout',
      patient: '/timeout',
    };
    const userType = headers.get('X-User-Type');
    let redirectTo = userRedirectMapping[userType as keyof typeof userRedirectMapping];

    // Convert a GraphQL object to a normal Javascript object to have methods like `hasOwnProperty`
    const data = JSON.parse(JSON.stringify(context.cache.data.data));

    // Iterate over the GraphQL cache to try to tease out the logged in User object
    const getUserProperty = Object.keys(data)
      .map((property: any) =>
        data.hasOwnProperty(property) && property.toString().startsWith('UserType') ? data[property] : null,
      )
      .filter(Boolean);

    const props = ['isRo', 'isPso', 'isSuperuser'];

    // Change redirection path if a User has one of the above properties and one of those properties is true
    redirectTo =
      getUserProperty.length &&
      props.some((prop) => getUserProperty[0].hasOwnProperty(prop) && getUserProperty[0][prop])
        ? '/'
        : redirectTo;

    if (!expiry) return response;

    if (expiry && !OPERATIONS_TO_IGNORE.includes(operation?.operationName)) {
      if (sessionTimeout) clearTimeout(sessionTimeout);
      sessionTimeout = setTimeout(() => {
        if (document.location.pathname !== redirectTo) {
          // Redirect if not on login
          document.location.href = redirectTo;
          document.cookie = 'SESSION_COOKIE_AGE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
      }, 1000 * expiry); // Expiry is in seconds, timeout takes ms
    }
    return response;
  });
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => {
      const statusCode = error.statusCode ?? -1;
      if (statusCode === 0) {
        return true;
      }
      return false;
    },
  },
});

// Afterware executed from Apollo
const link = ApolloLink.from([
  FormatSpecialCharactersMiddleware,
  errorHandlerMiddleware,
  sessionTimeoutLink,
  csrfMiddleware,
  retryLink,
  httpLink,
]);

const cache = new InMemoryCache();
// Apollo client
const client = new ApolloClient({
  link,
  cache: cache,
  connectToDevTools: true,
});

cache.writeQuery({
  query: gql`
    query {
      pendingSaveCount
      saveErrorCount
      registrationPagesViewed
      error {
        message
        statusCode
      }
    }
  `,
  data: {
    pendingSaveCount: 0,
    saveErrorCount: 0,
    registrationPagesViewed: [],
    error: {
      __typename: 'Error',
      message: '',
      statusCode: -1,
    },
  },
});

const routes = (
  <Switch>
    <Route path="/homeRegistration/informationNotice" component={InformationNotice} />
    <Route path="/homeRegistration/linkExpired" component={LinkExpired} />
    <Route path="/home-registration/validate/:token" component={HomeRegistrationLogin} />
    <Route path="/patient/:patientId/covid" component={CovidContainer} />
    <Route path="/patient/:patientId/distress/:distressId" component={DTContainer} />
    <Route path="/patient/:patientId/health" component={HAContainer} />
    <Route path="/patient/:patientId/registration" component={region === Region.US ? RegistrationUS : RegistrationAU} />
    <Route path="/patient/registrationComplete" component={RegistrationComplete} />
    <Route path="/patient/:patientId/summary" component={PatientFormSummaryApollo} />
    <Route path="/patient/:patientId/home" component={PatientNavigationApollo} />
    <Route path="/patient/:patientId/nurse/prectchartcheck/:formId" component={PreCtChartCheckApollo} />
    <Route path="/patient/:patientId/nurse/precttriage/:formId" component={PreCtTriageApollo} />
    <Route path="/patient/:patientId/management" component={ManagementPlan} />
    <Route path="/patient/create" component={CreatePatient} />
    <Route path="/patient" component={RegistrationLoginApollo} />
    {/* UK registration */}
    <Route path="/registration/:patientId/address" component={RegistrationAddressApollo} />
    <Route path="/registration/:patientId/altcontact" component={UKRegistrationAltContactApollo} />
    <Route path="/registration/:patientId/basic" component={RegistrationBasicApollo} />
    <Route path="/registration/:patientId/contact" component={RegistrationContactDetailsApollo} />
    <Route path="/registration/:patientId/demographics" component={UKRegistrationDemographicsApollo} />
    <Route path="/registration/:patientId/gp" component={UKRegistrationGPApollo} />
    <Route path="/registration/:patientId/infonotice" component={RegistrationInformationNoticeApollo} />
    <Route path="/registration/:patientId/preferences" component={RegistrationPreferencesApollo} />
    <Route path="/registration/:patientId/insurance" component={RegistrationInsuranceApollo} />
    <Route path="/registration/:patientId/attachments" component={RegistrationFileAttachmentsApollo} />
    <Route path="/registration/:patientId/summary" component={UKRegistrationSummaryApollo} />
    <Route path="/registration/:patientId/conflicts" component={RegistrationConflictsApollo} />
    {/* Other */}
    <Route path="/search" component={region === Region.UK ? UKPatientSearch : PSODashboard} />
    <Route path="/review-forms" component={PSODashboard} />
    <Route path="/sms-logs" component={PSODashboard} />
    {region === Region.UK && <Route path="/appointments" component={UKAppointmentsDashboardApollo} />}
    <Route path="/endSession" component={HomeRegoEndSession} />
    <Route path="/timeout" component={SessionTimeout} />
    <Route path="/error" component={ErrorPage} />
    <Route path="/app-selector" component={AppSelector} />
    <Route path="/logout" component={LogoutOP} />
    {/* OPX */}
    <Route exact path="/login" component={PXLogin} />

    <Route path="/px">
      <Switch>
        <Route path="/px/appointments/:id" component={PXAppointments} />
        <Route path="/px/appointments" component={PXAppointments} />
        <Route path="/px/mappingupdate" component={MappingUpdate} />
        <Route path="/px/termsAndConditions" component={PXTermsAndConditions} />
        <Route path="/px/information/faq/:category" component={PXFAQContentContainer} />
        <Route path="/px/information/faq" component={PXFAQHome} />
        <Route path="/px/information/:pageId" component={PXContentContainer} />
        <Route path="/px/information" component={PXInformation} />
        <Route path="/px/feedback" component={PXFeedback} />
        <Route path="/px/details" component={PXPatientDetails} />
        <Route path="/px/signup" component={PXSignup} />
        <Route path="/px/changePassword" component={PXChangePassword} />
        <Route path="/px/forgotPassword" component={PXForgotPassword} />
        <Route path="/px/resetPassword" component={PXResetPassword} />
        <Route path="/px/resetLink" component={PXResetLink} />
        <Route path="/px/linkExpired" component={PXLinkExpired} />
        <Route path="/px/mfa/" component={PXMultifactorAuth} />
        <Route path="/px/home" component={PXHome} />
        <Route path="/px">
          <Redirect to="/px/home" />
        </Route>
      </Switch>
    </Route>
    {/* OPX */}

    <Route path="/navigator">
      <ROBase>
        <Switch>
          <Route exact path="/navigator/patient/:id/summary" component={ROPatientSummaryPage} />
        </Switch>
      </ROBase>
    </Route>
    <Route path="/radiation">
      <ROBase>
        <Switch>
          {isDemo && <Route path="/radiation/insights/" component={InsightsDashboard} />}
          {isDemo && <Route path="/radiation/patient-tracker/" component={ROPatientTracker} />}
          {isDemo && <Route path="/:oncologyType/patient/:id/pathway" component={ROPatientPathway} />}
          <Route path="/:oncologyType/patient/:id/careplan/:careplanId/" component={ROPatientCarePlanPage} />
          <Route exact path="/:oncologyType/patient/:id/summary" component={ROPatientSummaryPage} />
          <Route path="/:oncologyType/patient/:id/documents/:page" component={DocumentsPageRouter} />
          <Route exact path="/:oncologyType/patient/:id/new" component={Summary} />
          <Route path="/:oncologyType/patient/:id/notes" component={Notes} />
          <Route path="/:oncologyType/patient/:id/labs" component={ROPatientLabs} />
          <Route path="/:oncologyType/patient/:id/outcomes/:outcomeId/" component={OutcomeFormPage} />
          <Route path="/:oncologyType/patient/:id/outcomes" component={OutcomesPage} />
          <Route path="/:oncologyType/patient/:id/on-treatment-review/:reviewId" component={OnTreatmentReviewForm} />
          <Route path="/:oncologyType/patient/:id/on-treatment-review" component={OnTreatmentReview} />
          <Route path="/:oncologyType/registration/patient/:id" component={ROCreatePatient} />
          <Route path="/:oncologyType" component={Dashboard} />
        </Switch>
      </ROBase>
    </Route>
    <Route path="/medonc">
      <MOBase>
        <Switch>
          <Route path="/:oncologyType/patient/:id/careplan/:careplanId/" component={MOPatientCarePlanPage} />
          <Route exact path="/:oncologyType/patient/:id/summary" component={MOPatientSummary} />
          <Route path="/:oncologyType/patient/create" component={MOCreatePatient} />
          <Route path="/:oncologyType" component={MODashboard} />
        </Switch>
      </MOBase>
    </Route>
  </Switch>
);

const WrappedApp = (): JSX.Element => {
  const [theme, setTheme] = useState(defaultTheme);
  // set the window title based
  useEffect(() => {
    document.title = theme?.custom?.title || 'Horizon';
  }, [theme]);

  return (
    <React.StrictMode>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <Favicon url={theme?.custom?.favicon} />
        <Suspense fallback={<div />}>
          <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
              <ErrorModalContextProvider>
                <NavigationContextProvider>
                  <AssessmentProvider>
                    <RegistrationContextProvider>
                      <Refresh />
                      <BrowserRouter>
                        <SentryUser />
                        <DatadogUserInfo />
                        <StyledEngineProvider injectFirst>
                          <FormContextProvider>
                            {region === Region.UK && <UKHomePage routes={routes} />}
                            {region !== Region.UK && routes}
                            <Route exact path="/" component={SSOPage} />
                          </FormContextProvider>
                        </StyledEngineProvider>
                      </BrowserRouter>
                    </RegistrationContextProvider>
                  </AssessmentProvider>
                </NavigationContextProvider>
              </ErrorModalContextProvider>
            </ThemeProvider>
          </ApolloProvider>
        </Suspense>
      </ThemeContext.Provider>
    </React.StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<WrappedApp />);
