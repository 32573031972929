// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { ComposedModal } from 'gc-ui';
import _ from 'lodash';
import { MoreInfoText } from 'op-components';
import { FamilyHistoryItem } from 'op-interfaces';
import { HADropDownField, HAFreeTextField, HARadioField } from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { resolveListDataValue } from 'op-utils';
import React, { useEffect, useState } from 'react';

import { ListData } from 'shared-components/interfaces';
import { SegmentedInputBoolean } from 'shared-components/utils';
import { styled } from '@mui/system';
import { UPDATE_FAMILY_HISTORY } from '../../HAQueries';
import { FAMILY_HISTORY_MODAL } from '../constants';
import './HAMedicalHistoryCancerModal.scss';

const DEFAULT_FAMILY_HISTORY_ITEM: FamilyHistoryItem = {
  id: '-1',
  familyMember: '',
  cancerType: '',
  stillAlive: undefined,
  ageOfFamilyMember: '',
  other: '',
};

const OTHER = 'Other';

const StyledCancerSection = styled('div')`
  background-color: ${(props) => props.theme.palette.grey[100]};
  padding: 16px 24px;
  margin-bottom: 16px;
`;

interface ModalField {
  KEY: string;
  NAME: string;
  TITLE: string;
  HINT?: string;
}

interface Props {
  isOpen?: boolean;
  familyHistory: FamilyHistoryItem[];
  patientId: string;
  dismissModal: () => void;
  familyMemberRefData: ListData[];
  selectedId: string | undefined;
  viewed: boolean;
}

const HAFamilyHistoryModal = (props: Props): JSX.Element => {
  const { isOpen, dismissModal, familyHistory, familyMemberRefData, patientId, selectedId } = props;
  const [data, setData] = useState<FamilyHistoryItem>(DEFAULT_FAMILY_HISTORY_ITEM);

  const [updateFamilyHistory] = useMutation(UPDATE_FAMILY_HISTORY, { refetchQueries: ['HealthAssessment'] });

  const resetFamilyHistoryModal = () => {
    const selectedFamilyHistory =
      familyHistory.find((value: FamilyHistoryItem) => value.id === selectedId) || DEFAULT_FAMILY_HISTORY_ITEM;
    setData(_.omit(selectedFamilyHistory, '__typename'));
    dismissModal();
  };

  const updateFamilyHistoryModal = () => {
    updateFamilyHistory({ variables: { patientId, ...data } });
    dismissModal();
  };

  const freeTextField = (field: string, modalFields: ModalField, required = true, overrideTitle?: string) => {
    const defaultValue = data[field as keyof typeof data] as string;
    return (
      <HAFreeTextField
        name={modalFields.NAME}
        title={overrideTitle ? overrideTitle : modalFields.TITLE}
        required={required}
        defaultValue={defaultValue}
        hint={modalFields.HINT}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
          //@ts-ignore
          data[field] = e.target.value;
          setData({ ...data });
        }}
      />
    );
  };

  const dropDownField = (field: string, modalFields: ModalField, options: ListData[]) => {
    //@ts-ignore
    const defaultValue = data[field];
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      //@ts-ignore
      data[field] = e.target.value;
      setData({ ...data });
    };
    return (
      <HADropDownField
        name={modalFields.NAME}
        title={modalFields.TITLE}
        defaultValue={defaultValue}
        hint={modalFields.HINT}
        options={options}
        onChange={onChange}
      />
    );
  };

  const radioField = (field: string, modalFields: ModalField) => {
    //@ts-ignore
    const defaultValue = data[field];
    const onChange = (value: string): void => {
      //@ts-ignore
      data[field] = value;
      setData({ ...data });
    };
    return (
      <HARadioField
        name={modalFields.NAME}
        title={modalFields.TITLE}
        defaultValue={defaultValue}
        onChange={onChange}
        options={SegmentedInputBoolean}
      />
    );
  };

  useEffect(() => {
    const selectedFamilyHistory =
      familyHistory.find((value: FamilyHistoryItem) => value.id === selectedId) || DEFAULT_FAMILY_HISTORY_ITEM;
    setData(_.omit(selectedFamilyHistory, '__typename'));
  }, [selectedId, familyHistory]);

  const renderModalBody = (): JSX.Element => {
    const familyMember = resolveListDataValue(data.familyMember, familyMemberRefData);

    const evaluateLabelForAge = (value: boolean | null | undefined): string | undefined => {
      let returnVal = undefined;
      if (value === undefined || value === null) return returnVal;

      returnVal = value ? undefined : 'Age of family member when passed away';
      return returnVal;
    };

    return (
      <div>
        <MoreInfoText
          showIcon={false}
          moreInfoText={'Add any family members with a history of cancer of blood diseases'}
        />
        <StyledCancerSection>
          {dropDownField('familyMember', FAMILY_HISTORY_MODAL.FAMILY_MEMBER, familyMemberRefData)}
          {familyMember === OTHER && freeTextField('other', FAMILY_HISTORY_MODAL.OTHER)}
          {freeTextField('cancerType', FAMILY_HISTORY_MODAL.TYPE_OF_CANCER, false)}
          {radioField('stillAlive', FAMILY_HISTORY_MODAL.STILL_ALIVE)}
          {freeTextField(
            'ageOfFamilyMember',
            FAMILY_HISTORY_MODAL.AGE_OF_FAMILY_MEMBER,
            true,
            evaluateLabelForAge(data['stillAlive']),
          )}
        </StyledCancerSection>
      </div>
    );
  };

  return (
    <ComposedModal
      headerText={'Add Family Member' || ''}
      isOpen={isOpen || false}
      dismissFunction={resetFamilyHistoryModal}
      primaryRightButton={{
        buttonText: 'Add',
        onClick: () => {
          updateFamilyHistoryModal();
        },
      }}
      width={'560px'}
      backdrop="static">
      {renderModalBody()}
    </ComposedModal>
  );
};

export default HAFamilyHistoryModal;
