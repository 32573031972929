import { useQuery } from '@apollo/client';
import { useErrorModalContext } from 'op-contexts';
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GCButton } from 'shared-components/components/FormFields';
import { ButtonType, GCButtonSize } from 'shared-components/enums';
import { GCTextLogo } from 'shared-components/images';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { styled } from '@mui/system';
import DefaultHeader from './DefaultHeader';
import { LOGGED_IN_PATIENT_QUERY } from './HeaderBarQueries';

interface Props {
  children: any;
  primaryRole: string;
  headingText?: string | null;
  menuButton?: boolean;
  menuButtonText?: string;
}

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
  background-color: white;
  height: 56px;
  position: sticky;
  z-index: 50;
  box-shadow: 0px 3px 8px ${(props) => props.theme.palette.grey[600]};
  align-items: center;
`;

const StyledLogo = styled(GCTextLogo)`
  height: 28px;
`;

const StyledHeadingText = styled('div')`
  color: black;
  font-size: 23px;
  font-weight: bold;
`;

const PatientFacingHeader = ({
  children,
  primaryRole,
  headingText,
  menuButton,
  menuButtonText,
}: Props): JSX.Element => {
  const windowSize = useWindowSize();
  const isSmallDisplay = windowSize.width < 1440;

  const { loading, data, error } = useQuery(LOGGED_IN_PATIENT_QUERY, { fetchPolicy: 'network-only' });

  const { setError } = useErrorModalContext();
  useEffect(() => {
    if (error) return setError();
  }, [error]);

  if (loading) {
    return <DefaultHeader patientFacing={true} />;
  }

  const MenuButton = (): JSX.Element => {
    return (
      <Link to={`/patient/${data.patient.id}/home`}>
        <GCButton
          data-testid="go-to-home"
          title={menuButtonText || 'Main Menu'}
          type={ButtonType.WHITE}
          buttonSize={GCButtonSize.SMALL}
        />
      </Link>
    );
  };

  if (data) {
    return (
      <Fragment>
        <HeaderContainer id="patient-facing-header">
          <Link to={`/patient/${data.patient.id}/home`} id="gc-logo-patient-facing">
            <StyledLogo />
          </Link>
          {headingText && isSmallDisplay && <StyledHeadingText>{headingText}</StyledHeadingText>}
          {menuButton && <MenuButton />}
        </HeaderContainer>
        {children && React.cloneElement(children, { primaryRole })}
      </Fragment>
    );
  }
  return <DefaultHeader patientFacing={true} />;
};

export default PatientFacingHeader;
