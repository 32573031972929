import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';

import { ROPatientContext } from 'op-pages/RO/PatientSummary/context';
import { Badge } from 'shared-components/components';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';

import PatientId from './PatientId';
import { AlertCardProps, AllergyCardProps, DiagnosisCardProps, DetailedCardProps } from './types';
import { GET_ALERTS } from './PatientCardQueries';
import './PatientCard.scss';
import { useTheme, Typography } from '@mui/material';

const AlertCard = (props: AlertCardProps): JSX.Element => {
  const { index, info } = props;
  return (
    <div className="ro-patient-alert-card">
      <div className="title">
        {index}. {info.name}
      </div>
      <div className="second-line">
        <div className="status">
          Status: <strong>{info.status}</strong>
        </div>
        |{' '}
        <div className="assigned">
          Assigned: <strong>{info.assigned && moment.utc(info.assigned).format('L')}</strong>
        </div>
      </div>
      <div className="comment">
        Notes: <strong>{info.comment}</strong>
      </div>
    </div>
  );
};

const AllergyCard = (props: AllergyCardProps): JSX.Element => {
  const { index, info } = props;
  return (
    <div className="ro-patient-allergy-card">
      <div className="title">
        {index}. {info.name}
      </div>
      <div className="second-line">
        <div className="severity">
          Severity: <strong>{info.severity}</strong>
        </div>
      </div>
      <div className="comment">
        Notes: <strong>{info.reaction}</strong>
      </div>
    </div>
  );
};

const DiagnosisCard = (props: DiagnosisCardProps): JSX.Element => {
  const { index, info } = props;
  return (
    info.diagnosisCode && (
      <div className="ro-patient-diagnosis-card">
        <div className="title">
          {index}. {info.diagnosisCode.diagnosisCode} {info.isPrimaryDiagnosis ? 'Primary' : 'Metastasis'}
        </div>
        <div className="second-line">
          <div className="diagnosis">{`${info.diagnosisCode.tumourStream.name} - ${info.diagnosisCode.diagnosisDescription}`}</div>
        </div>
      </div>
    )
  );
};

const ROPatientCard = (props: DetailedCardProps): JSX.Element => {
  const { attributes, moreDetailsClickHandler, showMoreDetails } = props;

  const { allergy, carepathPatientDiagnosis } = attributes;
  const { setPatientAlerts } = useContext(ROPatientContext);
  const address = attributes.address ? attributes.address.ukFormattedAddress : '-';
  const theme = useTheme();

  const NoKnownAllergiesProps = { bubbleColor: theme.palette.grey[600], bubbleText: 'N' };
  const NoAllergiesProps = { bubbleColor: theme.palette.warning.dark, bubbleText: '?' };

  const filteredAllergies = allergy?.filter((allergyInfo: any) => allergyInfo.status === 'Active');
  const isNoKnownAllergy = filteredAllergies?.some(
    (allergy: any): boolean =>
      allergy.name && ['no known drug allergies', 'no known allergies'].includes(allergy.name.toLowerCase()),
  );

  const getAllergyProps = (allergyList: any): any => {
    if (allergyList && allergyList.length === 0) return NoAllergiesProps;
    return isNoKnownAllergy
      ? NoKnownAllergiesProps
      : {
          bubbleColor: theme.palette.error.main,
          bubbleText: allergyList?.length,
        };
  };
  const allergyCardProps = getAllergyProps(filteredAllergies);

  const { data: alertsData } = useQuery(GET_ALERTS, {
    variables: { patientId: attributes.patientId },
    onCompleted: (data) => setPatientAlerts(data?.alerts),
  });
  const alerts = alertsData?.alerts;

  const diagnosis = carepathPatientDiagnosis.filter(
    (diagnosis: any) => diagnosis.isMostRecent && diagnosis.diagnosisId !== null,
  );

  return (
    <div className="patient-detailed-card-wrapper patient-card-wrapper">
      <div className="patient-profile-wrapper">
        <div className="photo-widget">
          <PhotoWidget url={attributes.photoUrl} enableOverlay={true} />
        </div>
        <div className="patient-id-wrapper">
          <PatientId attributes={attributes} />
          <Typography variant="body2">EMR: {attributes.emrInstance}</Typography>
          <span
            role="presentation"
            onClick={() => {
              if (moreDetailsClickHandler) {
                moreDetailsClickHandler();
              }
            }}
            className="more-details-button"
            data-testid="more-details-button">
            {showMoreDetails ? 'Hide' : 'Show'} details & appts
          </span>
        </div>
      </div>
      <div className="patient-address-wrapper">
        <div className="header-text">Address</div>
        <div className="body-text">
          <b>{address}</b>
        </div>
      </div>
      <div className="patient-dob-wrapper">
        <div className="header-text">Date of birth</div>
        <div className="body-text">
          <b>{attributes.fullAge}</b>
        </div>
        <div className="header-text">
          Mobile: <b>{attributes.mobile}</b>
        </div>
        <div className="header-text">
          Landline: <b>{attributes.landline}</b>
        </div>
      </div>
      <div className="ro-patient-card-vertical-bar" />
      <div className="ro-patient-diagnosis-top-bar-block">
        {!showMoreDetails && (
          <>
            <div className="ro-patient-round-block">
              <div className="ro-patient-card-header">
                Diagnosis{' '}
                {diagnosis && diagnosis.length ? (
                  <Badge $bgColor={theme.palette.grey[600]}>{diagnosis.length}</Badge>
                ) : (
                  ''
                )}
              </div>
              <div className="ro-patient-card-core">
                {diagnosis && diagnosis.length
                  ? diagnosis.map((diagnosisInfo: any, index: number) => {
                      return (
                        <DiagnosisCard
                          index={index + 1}
                          info={diagnosisInfo}
                          key={diagnosisInfo.diagnosisCode.diagnosisCode}
                        />
                      );
                    })
                  : 'No diagnoses available'}
              </div>
            </div>
            <div className="ro-patient-round-block">
              <div className="ro-patient-card-header">
                Allergies <Badge $bgColor={allergyCardProps.bubbleColor}>{allergyCardProps.bubbleText}</Badge>
              </div>
              <div className="ro-patient-card-core">
                {filteredAllergies && filteredAllergies.length
                  ? filteredAllergies.map((allergyInfo: any, index: number) => {
                      return <AllergyCard index={index + 1} info={allergyInfo} key={allergyInfo.name} />;
                    })
                  : 'No allergies available'}
              </div>
            </div>
            <div className="ro-patient-round-block">
              <div className="ro-patient-card-header">
                Alerts{' '}
                {alerts && alerts.length ? (
                  <Badge $bgColor={theme.palette.error.main}>{alerts.length}</Badge>
                ) : (
                  <Badge $bgColor={theme.palette.warning.dark}>?</Badge>
                )}
              </div>
              <div className="ro-patient-card-core">
                {alerts && alerts.length
                  ? alerts.map((alertInfo: any, index: number) => {
                      return <AlertCard index={index + 1} info={alertInfo} key={`${alertInfo.name}-${index}`} />;
                    })
                  : 'No alerts available'}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ROPatientCard;
