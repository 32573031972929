// eslint-disable-next-line no-use-before-define
import { ModalSaveExit, ModalSubmit, PatientFormFooter } from 'op-components';
import { NavigatorDirection } from 'op-enums';
import React, { useState, useContext } from 'react';

import { Redirect, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { StepperLink } from 'shared-components/interfaces';
import { FormContext } from '../../../pages/OP/PatientNavigation/context';
import { getNextForm } from '../../../pages/OP/PatientNavigation/helpers';
import { isUs } from 'op-utils/helpers';
import './HANavigator.scss';
import { RegistrationContextType } from 'op-contexts/RegistrationContext/RegistrationContext';
import { navigateToExternalURL } from 'shared-components/utils';

interface Props extends RouteComponentProps<{ patientId?: string }> {
  links: StepperLink[];
  redirectToRegistraton?: boolean;
  loading: boolean;
  submitCalled: boolean;
  submitFunction: () => void;
  isPso: boolean;
  generateURL?: () => void;
  isInClinic?: boolean;
  registrationContext: RegistrationContextType;
  showNewRego: boolean;
}

const HANavigator = (props: Props) => {
  const {
    links,
    redirectToRegistraton = false,
    loading,
    submitCalled,
    submitFunction,
    location: { pathname },
    match: {
      params: { patientId },
    },
    isPso,
    isInClinic,
    generateURL,
    registrationContext,
    showNewRego,
  } = props;

  const history = useHistory();

  const { formStatusDetails } = useContext(FormContext);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [saveExitModalOpen, setSaveExitModalOpen] = useState(false);

  const pathSegments = pathname.split('/');
  const currentPage = pathSegments[pathSegments.length - 1];

  let pageNumber: number | undefined = undefined;

  for (let i = 0; i < links.length; i++) {
    const link = links[i];

    const linkHref = link.href.replace(/\//, '');

    if (linkHref === currentPage) {
      pageNumber = i;
      break;
    }
  }
  const firstPage = pageNumber === 0;
  const lastPage = pageNumber === links.length - 1;

  // Set up the submit button to display submitting for usability.
  const submitButtonText = loading ? 'Submitting...' : 'Submit';

  const nextForm = getNextForm(
    //@ts-ignore
    formStatusDetails,
    patientId!,
    'healthAssessment',
    showNewRego,
  );

  if (submitCalled && !loading) {
    if (isUs()) {
      if (redirectToRegistraton) {
        if (isInClinic) {
          return <Redirect to={{ pathname: `/patient/${patientId}/home` }} />;
        }
        return <Redirect to={{ pathname: '/patient/registrationComplete' }} />;
      }
      return <Redirect to={{ pathname: `/patient/${patientId}/registration/basic` }} />;
    }

    if (nextForm.name === 'theranostics') {
      navigateToExternalURL(nextForm.path);
      return <></>;
    }

    return <Redirect to={{ pathname: nextForm.path, state: { pxRedirect: true } }} />;
  }

  const buttonHandler = (currentPageIndex: number, direction: NavigatorDirection, lastPage: boolean): void => {
    // On the last page, so call the submission instead
    if (lastPage && direction === NavigatorDirection.FORWARD) {
      setSubmitModalOpen(true);
    } else if (registrationContext.haSummaryVisited && direction === NavigatorDirection.FORWARD) {
      // 'Continue' has been replaced with 'Return to submit'
      const patientId = props.match.params.patientId;
      const newPath = `/patient/${patientId}/health/summary`;
      history.push(newPath);
    } else {
      // Need to traverse based on direction
      let navigateTo = '#';
      let nextIndex = currentPageIndex;

      switch (direction) {
        case NavigatorDirection.FORWARD:
          nextIndex += 1;
          break;
        case NavigatorDirection.BACK:
          nextIndex -= 1;
          break;
        default:
          break;
      }

      if (nextIndex >= 0 && nextIndex <= links.length - 1) {
        navigateTo = links[nextIndex].href.replace(/\//, '');
        history.push(navigateTo);
      }
    }
  };

  const dismissSubmitModal = (): void => {
    setSubmitModalOpen(false);
  };

  const dismissSNEModal = (): void => {
    setSaveExitModalOpen(false);
  };

  const exitForm = (): void => {
    if (isPso) {
      history.push('/search');
    } else {
      history.push({ pathname: `/patient/${patientId}/home`, state: { pxRedirect: true } });
    }
  };

  const handleBackButton = (isLastPage: boolean, pageIndex?: number, isPso?: boolean) => {
    if (isPso) {
      window.history.back();
    }
    if (pageIndex !== undefined) {
      buttonHandler(pageIndex, NavigatorDirection.BACK, isLastPage);
    }
  };

  const handleContinueButton = (isLastPage: boolean, pageIndex?: number, isPso?: boolean, generateURL?: any) => {
    if (isPso) {
      if (generateURL) {
        generateURL();
      }
    } else {
      if (pageIndex !== undefined) {
        buttonHandler(pageIndex, NavigatorDirection.FORWARD, isLastPage);
      }
    }
  };

  return (
    <>
      <ModalSaveExit
        isOpen={saveExitModalOpen}
        dismissFunction={dismissSNEModal}
        exitForm={exitForm}
        exitText={'Exit form'}
      />
      <ModalSubmit
        isOpen={submitModalOpen}
        dismissFunction={dismissSubmitModal}
        submitText={submitButtonText}
        submitForm={submitFunction}
        isUs={isUs()}
        continueNextForm={nextForm['name'] !== 'home'}
        isPSO={false}
      />

      <PatientFormFooter
        navComponentId="ha-nav"
        isPso={isPso}
        isFirstPage={firstPage}
        isLastPage={lastPage}
        submitCalled={submitCalled}
        showPrintPdfButton={isPso ?? false}
        showContinueButton={isPso ? false : true}
        handleClickOnBack={() => handleBackButton(lastPage, pageNumber, isPso)}
        handleSaveAndExit={() => setSaveExitModalOpen(true)}
        handleClickOnContinue={() => handleContinueButton(lastPage, pageNumber, isPso, generateURL)}
        summaryVisited={registrationContext?.haSummaryVisited}
      />
    </>
  );
};

const routedComponent = withRouter(HANavigator);
export default routedComponent;
