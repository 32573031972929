import { useMutation, useQuery } from '@apollo/client';
import { ComposedModal } from 'gc-ui';
import { FileContext } from 'op-contexts';
import UserContext, { UserContextType } from 'op-contexts/UserContext/UserContext';
import { RoleType } from 'op-enums';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Prompt } from 'react-router-dom';
import dayjs from 'dayjs';
import { DropDownField, BaseDatePicker } from 'shared-components/components/FormFields';
import GCButton from 'shared-components/components/UIFormComponents/GCButton';
import { WarningTriangle } from 'shared-components/images';
import { styled } from '@mui/system';
import {
  DRAG_AND_DROP,
  FILE_TYPES_MIME_TO_FILE_TYPES,
  HEADING,
  LEAVE_PAGE_WARNING,
  MAX_FILE_SIZE,
  MODAL,
  UPLOAD_ERRORS,
} from './constants';
import {
  CHANGE_DOCUMENT_TYPE,
  CHANGE_ENCOUNTER_DATE,
  DELETE_ATTACHMENT,
  GET_ATTACHMENTS_TYPES,
  GET_PATIENT_ATTACHMENTS,
  SET_STAGING_VALUE,
} from './queries';

import { allowedExtensions } from 'op-components/FileUploadField/helper';
import { documentTypeOptions } from 'op-pages/OP/RegistrationForm/utils';
import { Region } from 'shared-components/enums';
import { usePreventUnload } from 'shared-components/utils/CustomHooks';
import DeleteDocument from './DeleteDocument';
import UploadToS3 from './UploadToS3';
import { Dayjs } from 'dayjs';
import { Typography, Stack, useTheme } from '@mui/material';
import { UploadOutlined as UploadOutlinedIcon } from '@mui/icons-material';

const region = import.meta.env.REACT_APP_REGION;

interface DocumentTypesType {
  id: string;
  name: string;
}
interface DocumentUploadProps {
  patientId: string;
  userId: string;
  currentDocuments: any;
  onSubmitDocumentUpload: () => Promise<boolean>;
  onCancelDocumentUpload?: any;
}

export interface ActiveFile {
  id: string;
  baseFile: any;
  encounterDate: Date;
  accepted: boolean;
  uploadErrors: string;
  loading: boolean;
  uploadingToS3: boolean;
  baseS3Url: string;
  previewUrl: string;
  fileType: string;
  s3Fields: {
    awsKey: string;
    awsPayload: object;
  };
}

const DocUploadWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const StyledContainer = styled('div')`
  height: 100%;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 16px;
`;

const StyledBoxHeader = styled('h3')`
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
`;

const TableContainer = styled('div')`
  overflow-y: auto;
  min-height: 8.2%;
  margin: 15px 0px 30px 0px;
`;

const StyledTable = styled('table')`
  display: table;
  width: 100%;
  font-size: 16px;
  line-height: 52px;
  float: left;
  border-collapse: separate;
`;

const Header = styled('thead')`
  width: auto;
  font-weight: 500;
  th {
    top: 0;
    background: ${(props) => props.theme.palette.grey[100]};
    font-weight: 500;
    position: sticky;
    z-index: 100;
  }
`;

const HeaderRow = styled('tr')`
  width: auto;
`;

const HeaderCol = styled('th')`
  width: auto;
  vertical-align: middle;
  border-collapse: collapse;
  padding: 0 16px;
  text-align: left;
`;

const DataBody = styled('tbody')`
  width: auto;
`;

const DataCol = styled('td')`
  width: auto;
  border-collapse: collapse;
  padding: 8px 8px 8px 16px;
  text-align: left;
`;

const StyledDragAndDropBox = styled(Stack)`
  height: 143px;
  border: 1px dashed ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
`;

const FileUploadLabel = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
`;

const FilesAcceptedLabel = styled(Typography)`
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
`;

const UploadIcon = styled(UploadOutlinedIcon)`
  width: 24px;
  height: 24px;
  margin-top: -5px;
  margin-right: 5px;
  cursor: pointer;
`;

const StyledDropDown = styled(DropDownField)`
  width: 90%;
  border: 1px ${(props) => props.theme.palette.grey[300]} solid !important;
  border-radius: 4px;
  height: 42px !important;
  padding-top: 1px;
`;

const FooterWrapper = styled('div')`
  background: white;
  position: fixed;
  right: 0;
  left: 240px;
  bottom: 0;
  @media screen and (max-width: 576px) {
    left: 0;
  }
`;

const StyledFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  text-align: end;
  border-top: 1px solid ${(props) => props.theme.palette.primary.main};
  padding: 12px 24px;
  && button {
    margin: 0px;
  }
`;

const DataRow = styled('tr')`
  background: 'none';
  & td:first-child {
    text-align: left;
    border-radius: 'none';
    border-left: 'none';
    vertical-align: top;
  }
  & td:last-child {
    border-radius: 'none';
    border-right: 'none';
  }
  & td {
    border-top: 'none';
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
    text-align: left;
  }
  box-shadow: 0px 1px 0px ${(props) => props.theme.palette.grey[300]};
  width: auto;
  height: 50px;
`;

const StyledSubmitModalBody = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 1.4rem;
`;

const StyledSubmitModalBodyNote = styled('div')`
  font-weight: 500;
`;
const DocumentUpload = (props: DocumentUploadProps): JSX.Element => {
  const { patientId, userId, currentDocuments, onSubmitDocumentUpload, onCancelDocumentUpload } = props;
  const { state } = useContext<UserContextType>(UserContext);
  const [documentTypes, setDocumentTypes] = useState<DocumentTypesType[]>(documentTypeOptions);
  const { activeFiles, setActiveFiles } = useContext(FileContext);
  const { PSO, RO, OTHER } = RoleType;
  const theme = useTheme();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [validateAttachments, setValidateAttachments] = useState(false);
  const [setStagingValue] = useMutation(SET_STAGING_VALUE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PATIENT_ATTACHMENTS, variables: { id: patientId } }],
  });

  useQuery(GET_ATTACHMENTS_TYPES, {
    skip: region !== Region.UK,
    onCompleted: (data: any): void => {
      const documentTypeRefData = data?.documentTypeRefData ? data?.documentTypeRefData : [];
      const newListOptions = documentTypeRefData.map((item: any, index: number) => {
        return {
          id: item.conceptCode,
          key: index,
          name: item.conceptDisplay,
        };
      });
      if (newListOptions.length > 0) {
        setDocumentTypes(newListOptions);
      }
    },
  });

  const FILE_TYPES_ACCEPTED_TEXT = `File types accepted:  ${allowedExtensions.join(', ')}`;
  // Role based displays
  const tableHeader = {
    [PSO]: {
      headers: ['File name', 'Type*', 'Encounter date*', 'Actions'],
      widths: ['40%', '25%', '25%', '10%'],
    },
    [RO]: {
      headers: ['File name', 'Type*', 'Encounter date*', 'Actions'],
      widths: ['40%', '25%', '25%', '10%'],
    },
    [OTHER]: {
      headers: ['File name', 'Actions'],
      widths: ['80%', '20%'],
    },
  };
  const showEncounterDate = Boolean(state.primaryRole !== OTHER);
  const showDocumentType = Boolean(state.primaryRole !== OTHER);

  // Mutations for document changes
  const [changeDocumentType] = useMutation(CHANGE_DOCUMENT_TYPE);
  const [changeEncounterDate] = useMutation(CHANGE_ENCOUNTER_DATE);
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PATIENT_ATTACHMENTS, variables: { id: patientId } }],
  });

  // Set context with refetched data from query (and include any existing rejected files)
  useEffect(() => {
    const rejectedFiles = activeFiles?.filter((file: any) => file.accepted === false);
    const refetchedFiles = currentDocuments?.map((file: any) => ({
      id: file.id,
      baseFile: {
        name: file.filename,
        type: file.documentType,
      },
      encounterDate: file.encounterDate,
      accepted: true,
      uploadErrors: '',
      loading: false,
      baseS3Url: '',
      uploadingToS3: false,
      fileType: file.documentType,
      previewUrl: file.url,
      s3Fields: {
        awsKey: '',
        awsPayload: {},
      },
    }));
    setActiveFiles([...refetchedFiles, ...rejectedFiles]);
  }, [currentDocuments]);

  const DragAndDropBox = (): JSX.Element => {
    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const newAcceptedFiles: any = [];
      const newRejectedFiles: any = [];
      if (acceptedFiles.length !== 0) {
        acceptedFiles.forEach((item: File) => {
          newAcceptedFiles.push({
            baseFile: item,
            encounterDate: new Date(),
            accepted: true,
            validationError: false,
            uploadErrors: '',
            loading: true,
            previewUrl: '',
            uploadingToS3: false,
          });
        });
      }
      if (fileRejections.length !== 0) {
        fileRejections.forEach((item: FileRejection) => {
          newRejectedFiles.push({
            baseFile: item.file,
            encounterDate: new Date(),
            accepted: false,
            uploadErrors: UPLOAD_ERRORS[item.errors[0]['code'] as keyof typeof UPLOAD_ERRORS],
            previewUrl: '',
          });
        });
      }
      setActiveFiles([...activeFiles, ...newAcceptedFiles, ...newRejectedFiles]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: FILE_TYPES_MIME_TO_FILE_TYPES,
      maxSize: MAX_FILE_SIZE,
      multiple: true,
    });

    return (
      <StyledDragAndDropBox {...getRootProps({ refKey: 'innerRef' })}>
        <input {...getInputProps()} data-test-id="drag-and-drop-box" />
        {
          <>
            <Stack direction="row" justifyContent="center">
              <UploadIcon color="primary" />
              <FileUploadLabel>{DRAG_AND_DROP}</FileUploadLabel>
            </Stack>
            <FilesAcceptedLabel>{FILE_TYPES_ACCEPTED_TEXT}</FilesAcceptedLabel>
          </>
        }
      </StyledDragAndDropBox>
    );
  };

  const handleDocumentTypeChange = (index: number, newDocType: string): void => {
    changeDocumentType({
      variables: {
        attachmentPk: activeFiles[index].id,
        documentType: newDocType,
      },
    });
    activeFiles[index].fileType = newDocType;
    setActiveFiles([...activeFiles]);
  };

  const handleDateChange = (index: number, newDate: any): void => {
    if (newDate) {
      changeEncounterDate({
        variables: {
          attachmentPk: activeFiles[index].id,
          encounterDate: newDate,
        },
      });
      activeFiles[index].encounterDate = newDate;
      setActiveFiles([...activeFiles]);
    }
  };

  const handleSubmitClick = (): void => {
    setValidateAttachments(true);
    let submit = true;
    activeFiles.forEach((item: ActiveFile) => {
      if (item.fileType === undefined || item.fileType === '') {
        submit = false;
      }
    });
    setShowSubmitModal(Boolean(submit && activeFiles.length));
  };

  const handleSubmission = async (): Promise<void> => {
    const success = await onSubmitDocumentUpload();
    if (success) {
      // Set staging values to true so we know those attachments are being submitted to MQ
      activeFiles.forEach((item: ActiveFile) => {
        if (item.accepted) {
          setStagingValue({
            variables: {
              id: item.id,
            },
          });
        }
      });

      setActiveFiles([...activeFiles]);
    }
  };

  const handleCancelClick = (): void => {
    activeFiles.length > 0 ? setShowCancelModal(true) : onCancelDocumentUpload();
  };
  const handleClose = (): void => {
    setShowSubmitModal(false);
  };

  const renderSubmitModal = () => (
    <ComposedModal
      isOpen={true}
      headerText={MODAL.HEADER}
      secondaryRightButton={{
        buttonText: 'Cancel',
        testId: 'submit-cancel-button',
        onClick: handleClose,
      }}
      primaryRightButton={{
        buttonText: MODAL.SUBMIT_BUTTON,
        onClick: handleSubmission,
        testId: 'confirm-submit-button',
      }}>
      {region === Region.AU && <div>{MODAL.DESCRIPTION}</div>}
      {region === Region.UK && (
        <StyledSubmitModalBody>
          <p>Please ensure details are correct before proceeding.</p>
          <StyledSubmitModalBodyNote>
            <WarningTriangle title="Warning_Triangle.svg" /> Note: Any upload failed documents will not be submitted.
          </StyledSubmitModalBodyNote>
        </StyledSubmitModalBody>
      )}
    </ComposedModal>
  );

  const renderCancelModal = () => (
    <ComposedModal
      isOpen={true}
      headerText={'Quit document upload'}
      secondaryRightButton={{
        buttonText: 'Cancel',
        testId: 'quit-cancel-button',
        onClick: () => {
          setShowCancelModal(false);
        },
      }}
      primaryRightButton={{
        buttonText: 'Quit',
        onClick: () => {
          onCancelDocumentUpload && onCancelDocumentUpload();
        },
        testId: 'confirm-quit-button',
      }}>
      <div>The uploaded document(s) are NOT submitted and will be cleared upon leaving the page.</div>
      <div>Are you sure you want to leave this page?</div>
    </ComposedModal>
  );

  usePreventUnload(activeFiles.length);

  return (
    <DocUploadWrapper data-testid="doc-upload-wrapper">
      <StyledContainer>
        {showSubmitModal && renderSubmitModal()}
        {showCancelModal && renderCancelModal()}
        <StyledBoxHeader>{HEADING}</StyledBoxHeader>
        <DragAndDropBox />
        <TableContainer>
          <StyledTable data-test-id={'document-table'}>
            <Header>
              <HeaderRow>
                {tableHeader[state.primaryRole] &&
                  tableHeader[state.primaryRole]?.headers.map(
                    (item: string, index: number): JSX.Element => (
                      <HeaderCol key={`head-${index}`} style={{ width: tableHeader[state.primaryRole].widths[index] }}>
                        {item}
                      </HeaderCol>
                    ),
                  )}
              </HeaderRow>
            </Header>
            {activeFiles.length !== 0 && (
              <DataBody className="document-list-table">
                {activeFiles.map(
                  (value: ActiveFile, index: number): JSX.Element => (
                    <DataRow
                      id={`row-${index}`}
                      key={`row-${index}`}
                      className={'not-clicky'}
                      data-test-id={`upload-row-${index}`}>
                      <DataCol id={`${value.baseFile['name']}-name-col-${index}`} key={`name-col-${index}`}>
                        <UploadToS3 index={index} patientId={patientId} userId={userId} />
                      </DataCol>
                      {showDocumentType && (
                        <DataCol id={`${value.baseFile['name']}-type-col-${index}`} key={`type-col-${index}`}>
                          <StyledDropDown
                            inputKey={`document-type-${index}`}
                            inputName="document-type"
                            placeholder="Please choose..."
                            defaultValue={value.baseFile.type}
                            options={documentTypes}
                            disabled={value.loading || !value.accepted}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                              handleDocumentTypeChange(index, e.target.value);
                            }}
                            errors={
                              validateAttachments && value.baseFile.type === '' ? ['This field is required'] : undefined
                            }
                            errorType={'document-upload-table'}
                            dataTestId={`document-type-dropdown-${index}`}
                          />
                        </DataCol>
                      )}
                      {showEncounterDate && (
                        <td id={`${value.baseFile['name']}-date-col-${index}`} key={`date-col-${index}`}>
                          <Stack sx={{ marginTop: '6px' }}>
                            <BaseDatePicker
                              id="encounterDate"
                              value={value.encounterDate ? dayjs(value.encounterDate) : null}
                              onChange={(date: Dayjs | null, context: any) => {
                                if (context.validationError) return;
                                const dateString = date ? date.format('YYYY-MM-DD').toString() : '';
                                handleDateChange(index, dateString);
                              }}
                              disabled={value.loading || !value.accepted}
                            />
                          </Stack>
                        </td>
                      )}
                      <DataCol id={`${value.baseFile['name']}-actions-col-${index}`} key={`actions-col-${index}`}>
                        <DeleteDocument
                          document={value}
                          handleMutation={deleteAttachment}
                          documentId={index.toString()}
                          dataTestId={`delete-document-${index}`}
                        />
                      </DataCol>
                    </DataRow>
                  ),
                )}
              </DataBody>
            )}
          </StyledTable>
        </TableContainer>
      </StyledContainer>
      <FooterWrapper>
        <StyledFooter>
          <GCButton
            onClick={(): void => {
              handleCancelClick();
            }}
            buttonText={'Cancel'}
            textColor="black"
            fontWeight="bold"
          />
          <GCButton
            onClick={(): void => {
              handleSubmitClick();
            }}
            buttonText={'Submit'}
            backgroundColor={
              activeFiles && activeFiles.length !== 0 ? theme.palette.primary.main : theme.palette.grey[100]
            }
            textColor={
              activeFiles && activeFiles.length !== 0 ? theme.palette.primary.contrastText : theme.palette.grey[600]
            }
            fontWeight="bold"
            disabled={!(activeFiles && activeFiles.length !== 0)}
            borderColor={activeFiles && activeFiles.length !== 0 ? theme.palette.primary.main : theme.palette.grey[300]}
          />
        </StyledFooter>
      </FooterWrapper>
      <Prompt when={!(showCancelModal || showSubmitModal) && activeFiles.length > 0} message={LEAVE_PAGE_WARNING} />
    </DocUploadWrapper>
  );
};

export default DocumentUpload;
