import { OutcomeInterface } from './Interfaces';
import { FormikHandlers, Formik, FormikHelpers, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RODatePicker, ROSelect, ROTextField, ROToggleButtons } from 'shared-components/components/FormFields';
import dayjs, { Dayjs } from 'dayjs';
import {
  Box,
  BoxProps,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  Button as MuiButton,
} from '@mui/material';
import useOncologyListData from 'op-pages/MO/Careplan/useOncologyListData';
import { theme } from 'theme';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'gc-ui';
import { useMutation } from '@apollo/client';
import { CREATE_OR_UPDATE_OUTCOME, SAVE_OUTCOME_DATA } from './Queries';
import { useHistory, useLocation } from 'react-router-dom';
import AutoSave from 'shared-components/components/FormikComponents/AutoSave';
import * as Yup from 'yup';
import { HelpOutline } from '@mui/icons-material';
import { Banner } from 'shared-components/components';
import { OUTCOME_STATUS } from './Constants';
import DiscardModal from './DiscardModal';

const itIsABool = (value: string): boolean => value === 'True';

const itIsADate = (value: string): Dayjs | null => {
  const date = dayjs(value ?? '');
  return date.isValid() ? date : null;
};

const IndentBox = (props: BoxProps) => (
  <Box {...props} sx={{ borderLeft: `1px ${theme.palette.grey[300]} solid`, ...props.sx }} />
);

const MortalityTooltip = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpen(true)} size="small" sx={{ padding: 0 }}>
        <HelpOutline />
      </IconButton>
      <Modal open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <ModalHeader>Mortality</ModalHeader>
        <ModalBody sx={{ span: { fontWeight: 600 } }}>
          <Typography>
            <span>ANED</span> = Alive, no evidence of disease
          </Typography>
          <Typography>
            <span>AWD</span> = Alive with disease
          </Typography>
          <Typography>
            <span>DOD</span> = Dead of disease
          </Typography>
          <Typography>
            <span>DOC</span> = Dead of other cause
          </Typography>
        </ModalBody>
        <ModalFooter>
          <MuiButton onClick={() => setOpen(false)} sx={{ textTransform: 'uppercase' }}>
            Close
          </MuiButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ASSESSMENT_METHOD_OPTIONS = ['PET', 'MRI', 'CT', 'Tumour Marker', 'Clinical', 'Histology'].map((value) => ({
  label: value,
  value: value,
}));

const RelapseSiteInfo = ({
  id,
  handleChange,
  setValue,
  dateValue,
  siteValue,
  checkedValue,
  label,
}: {
  id: string;
  handleChange: FormikHandlers['handleChange'];
  setValue: FormikHelpers<FormValues>['setFieldValue'];
  dateValue?: Dayjs | null;
  siteValue?: string;
  checkedValue?: boolean;
  label: string;
}): JSX.Element => {
  const dateName = `${id}Date`;
  const siteName = `${id}Site`;
  return (
    <>
      <FormControlLabel
        sx={{ width: 'fit-content', marginBottom: 2 }}
        control={
          <Checkbox
            checked={checkedValue}
            name={id}
            onChange={(ev, checked) => {
              if (!checked) {
                setValue(siteName, '');
                setValue(dateName, itIsADate(''));
              }
              setValue(id, checked);
            }}
            color="primary"
          />
        }
        label={label}
        data-testid={id}
      />
      {checkedValue && (
        <IndentBox>
          <RODatePicker
            id={dateName}
            name={dateName}
            fieldlabel="Date"
            indent
            views={['month', 'year']}
            onChange={(date) => setValue(dateName, date)}
            value={dateValue}
            format="MM/YYYY"
          />
          <ROTextField
            id={siteName}
            name={siteName}
            fieldlabel="Relapse site"
            indent
            onChange={handleChange}
            value={siteValue}
          />
        </IndentBox>
      )}
    </>
  );
};

const StatusBanner = ({ outcome }: { outcome: OutcomeInterface }) => {
  const submittedAt = outcome.submittedAt ? dayjs(outcome.submittedAt).format('DD MM YYYY h:mm a') : '';
  return (
    <div data-cy="status-banner">
      {outcome.status === OUTCOME_STATUS.IN_PROGRESS && (
        <Banner text="" subtext="You have unsubmitted changes, please submit" type="info"></Banner>
      )}
      {outcome.status === OUTCOME_STATUS.UNSUBMITTED_CHANGE && (
        <Banner
          text=""
          subtext={`You have unsubmitted changes, please resubmit. Last submitted at ${submittedAt}`}
          type="info"></Banner>
      )}
      {outcome.status === OUTCOME_STATUS.SUBMITTED && (
        <Banner text="" subtext="This outcome has been submitted" type="success"></Banner>
      )}
    </div>
  );
};

type FormValues = {
  followUpDate: Dayjs | null;
  responseToTreatment: string;
  assessmentMethod: string;
  assessmentDate: Dayjs | null;
  mortality: string;
  additionalNotes: string;
  dateOfDeath: Dayjs | null;
  causeOfDeath: string;
  secondPrimaryMalignancy: string;
  diseaseStatus: string;
  secondPrimaryMalignancyType?: string;

  localRelapse?: boolean;
  localRelapseDate?: Dayjs | null;
  localRelapseSite?: string;
  regionalRelapse?: boolean;
  regionalRelapseDate?: Dayjs | null;
  regionalRelapseSite?: string;
  distantRelapse?: boolean;
  distantRelapseDate?: Dayjs | null;
  distantRelapseSite?: string;

  ecogPerformanceStatus?: string;
};

type LocationStateType = {
  timePoint: string;
  diagnosisId: string;
};

const FormContainer = ({ outcome, patientId }: { outcome: OutcomeInterface; patientId: string }): JSX.Element => {
  const location = useLocation<LocationStateType>();
  const [createOrUpdateOutcome] = useMutation(CREATE_OR_UPDATE_OUTCOME);
  const [updateOutcome] = useMutation(SAVE_OUTCOME_DATA);
  const history = useHistory();

  const outcomeValues = useMemo(() => {
    const values = outcome?.formInstance?.values ?? [];
    return Object.fromEntries(values.map((value) => [value.field, value.value]));
  }, [outcome]);

  const goBack = () => {
    history.push(`/radiation/patient/${patientId}/outcomes`);
  };

  const handleSubmitOutcome = async (values?: FormValues) => {
    if (!values) {
      return;
    }
    createOrUpdateOutcome({
      variables: {
        timePoint: location.state.timePoint,
        diagnosisId: location.state.diagnosisId,
        jsonData: JSON.stringify(values),
        status: 'submitted',
      },
    });
    goBack();
  };

  const handleUpdateDraftOutcome = (values: FormValues) => {
    const processedValues = Object.fromEntries(
      Object.entries(values ?? []).map(([key, value]) => [
        key,
        dayjs.isDayjs(value) && value.isValid() ? value.format('YYYY-MM-DD') : value,
      ]),
    );
    updateOutcome({
      variables: {
        outcomeId: outcome.id,
        jsonData: JSON.stringify(processedValues),
      },
    });
  };

  const initialValues = {
    followUpDate: itIsADate(outcomeValues['followUpDate']) ?? itIsADate(outcome.createdAt),
    responseToTreatment: outcomeValues['responseToTreatment'] ?? '',
    assessmentMethod: outcomeValues['assessmentMethod'] ?? '',
    assessmentDate: itIsADate(outcomeValues['assessmentDate']),
    mortality: outcomeValues['mortality'],
    additionalNotes: outcomeValues['additionalNotes'],
    dateOfDeath: itIsADate(outcomeValues['dateOfDeath']),
    causeOfDeath: outcomeValues['causeOfDeath'] ?? '',
    secondPrimaryMalignancy: outcomeValues['secondPrimaryMalignancy'],
    diseaseStatus: outcomeValues['diseaseStatus'],
    secondPrimaryMalignancyType: outcomeValues['secondPrimaryMalignancyType'],
    localRelapse: itIsABool(outcomeValues['localRelapse']),
    localRelapseDate: itIsADate(outcomeValues['localRelapseDate']),
    localRelapseSite: outcomeValues['localRelapseSite'] ?? '',
    regionalRelapse: itIsABool(outcomeValues['regionalRelapse']),
    regionalRelapseDate: itIsADate(outcomeValues['regionalRelapseDate']),
    regionalRelapseSite: outcomeValues['regionalRelapseSite'] ?? '',
    distantRelapse: itIsABool(outcomeValues['distantRelapse']),
    distantRelapseDate: itIsADate(outcomeValues['distantRelapseDate']),
    distantRelapseSite: outcomeValues['distantRelapseSite'] ?? '',

    ecogPerformanceStatus: outcomeValues['ecogPerformanceStatus'] ?? '',
  };

  const validationSchema = Yup.object().shape({
    followUpDate: Yup.date().required(),
    mortality: Yup.string().required(),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmitOutcome}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={validationSchema}>
      {(props) => (
        <OutcomeForm
          {...props}
          patientId={patientId}
          handleUpdateDraftOutcome={handleUpdateDraftOutcome}
          outcome={outcome}
          handleBack={goBack}
        />
      )}
    </Formik>
  );
};

type TheFormProps = FormikProps<FormValues> & {
  patientId: string;
  handleUpdateDraftOutcome: (values: FormValues) => void;
  outcome: OutcomeInterface;
  handleBack: () => void;
};

const OutcomeForm = ({
  values,
  setFieldValue,
  handleChange,
  setValues,
  patientId,
  handleUpdateDraftOutcome,
  outcome,
  errors,
  submitForm,
  submitCount,
  validateForm,
  handleBack,
}: TheFormProps) => {
  const optionsData = useOncologyListData(['responseToTreatment', 'ECOG'], patientId);
  const [discardOpen, setDiscardOpen] = useState(false);
  const options = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(optionsData).map(([field, options]) => [
          field,
          options.map((option) => ({ label: option.option, value: option.option })),
        ]),
      ),
    [optionsData],
  );

  const responseToTreatmentOptions = useMemo(() => {
    return [{ label: 'Not applicable', value: 'Not applicable' }, ...(options?.responseToTreatment ?? [])];
  }, [options]);

  const ecogOptions = useMemo(
    () => options?.ECOG?.sort(({ label: labelA }, { label: labelB }) => parseInt(labelA[0]) - parseInt(labelB[0])),
    [options],
  );

  useEffect(() => {
    if (submitCount > 0) {
      validateForm();
    }
  }, [values, submitCount, validateForm]);

  const handleMortalityChange = useCallback(
    (value: string) => {
      if (value === 'ANED') {
        setFieldValue('dateOfDeath', null);
        setFieldValue('causeOfDeath', '');
        setFieldValue('secondPrimaryMalignancy', '');
        setFieldValue('diseaseStatus', '');

        setFieldValue('localRelapse', '');
        setFieldValue('localRelapseSite', '');
        setFieldValue('localRelapseDate', null);

        setFieldValue('regionalRelapse', '');
        setFieldValue('regionalRelapseSite', '');
        setFieldValue('regionalRelapseDate', null);

        setFieldValue('distantRelapse', '');
        setFieldValue('distantRelapseSite', '');
        setFieldValue('distantRelapseDate', null);
      } else if (value === 'AWD') {
        setFieldValue('dateOfDeath', null);
        setFieldValue('causeOfDeath', '');
      }
      setFieldValue('mortality', value);
    },
    [setFieldValue],
  );

  const ecogEnabled = !!values.ecogPerformanceStatus;

  if (!ecogOptions || !responseToTreatmentOptions) {
    return <Box width={1} />;
  }

  return (
    <Stack direction="column" width={1}>
      <StatusBanner outcome={outcome} />
      <Stack
        paddingX={2}
        paddingTop={2}
        direction="column"
        height="calc(100vh - 200px)"
        bgcolor="background.primary"
        borderRight={`1px solid ${theme.palette.grey[300]}`}
        overflow="auto"
        gap={1}
        width={1}>
        <Typography variant="h5" lineHeight="28px" data-testid="title">
          {outcome.diagnosisName}
        </Typography>
        <Typography variant="subtitle2" sx={{ span: { color: theme.palette.error.main } }} paddingBottom={1}>
          <span>*</span> = required field
        </Typography>
        <Stack>
          <RODatePicker
            id="followUpDate"
            name="followUpDate"
            fieldlabel="Follow up date"
            value={values.followUpDate}
            onChange={(date) => setFieldValue('followUpDate', date)}
            required
            helperText={errors.followUpDate && 'This field is required'}
            error={Boolean(errors.followUpDate)}
            data-testid="follow-up-date"
          />
          <Typography variant="overline" marginBottom={2}>
            Treatment outcome
          </Typography>
          <ROSelect
            id="responseToTreatment"
            name="responseToTreatment"
            fieldlabel="Response to Treatment"
            options={responseToTreatmentOptions}
            value={values.responseToTreatment}
            onChange={handleChange}
            fullWidth
            sx={{ maxWidth: '400px' }}
            data-testid="response-to-treatment"
          />
          {!ecogEnabled && (
            <>
              <ROSelect
                id="assessmentMethod"
                name="assessmentMethod"
                fieldlabel="Assessment Method"
                options={ASSESSMENT_METHOD_OPTIONS}
                value={values.assessmentMethod}
                onChange={handleChange}
                fullWidth
                sx={{ maxWidth: '400px' }}
                data-testid="assessment-method"
              />
              <RODatePicker
                id="assessmentDate"
                name="assessmentDate"
                fieldlabel="Assessment Date"
                value={values.assessmentDate}
                onChange={(date) => setFieldValue('assessmentDate', date)}
                data-testid="assessment-date"
              />
            </>
          )}
          <Typography variant="overline" marginBottom={2}>
            Clinical outcome - Patient
          </Typography>
          {ecogEnabled && (
            <ROSelect
              id="ecogPerformanceStatus"
              name="ecogPerformanceStatus"
              fieldlabel="ECOG performance status"
              value={values.ecogPerformanceStatus}
              options={options?.ECOG ?? []}
              onChange={handleChange}
              fullWidth
              sx={{ maxWidth: '400px' }}
              data-testid="ecog-performance-status"
              SelectProps={{
                MenuProps: { PaperProps: { sx: { maxWidth: '400px' } } },
              }}
            />
          )}
          <ROToggleButtons
            id="mortality"
            fieldlabel="Mortality"
            value={values.mortality}
            options={[
              { label: 'ANED', value: 'ANED' },
              { label: 'AWD', value: 'AWD' },
              { label: 'DOD', value: 'DOD' },
              { label: 'DOC', value: 'DOC' },
            ]}
            handleChange={handleMortalityChange}
            required
            tooltip={<MortalityTooltip />}
            helperText={errors.mortality}
            error={Boolean(errors.mortality)}
            data-testid="mortality"
          />
          {['DOD', 'DOC'].includes(values.mortality) && (
            <IndentBox>
              <RODatePicker
                id="dateOfDeath"
                name="dateOfDeath"
                fieldlabel="Date of death"
                value={values.dateOfDeath}
                onChange={(date) => setFieldValue('dateOfDeath', date)}
                indent
                data-testid="date-of-death"
              />
              <ROTextField
                id="causeOfDeath"
                name="causeOfDeath"
                fieldlabel="Cause of death"
                value={values.causeOfDeath}
                onChange={handleChange}
                indent
                data-testid="cause-of-death"
              />
            </IndentBox>
          )}
          {['AWD', 'DOD', 'DOC'].includes(values.mortality) && (
            <>
              <ROToggleButtons
                id="secondPrimaryMalignancy"
                fieldlabel="Second primary malignancy"
                value={values.secondPrimaryMalignancy}
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                handleChange={(value) => setFieldValue('secondPrimaryMalignancy', value)}
                data-testid="second-primary-malignancy"
              />
              {values.secondPrimaryMalignancy === 'Yes' && (
                <IndentBox>
                  <ROTextField
                    id="secondPrimaryMalignancyType"
                    name="secondPrimaryMalignancyType"
                    fieldlabel="Type"
                    value={values.secondPrimaryMalignancyType}
                    onChange={handleChange}
                    indent
                    data-testid="second-primary-malignancy-type"
                  />
                </IndentBox>
              )}
              <ROToggleButtons
                id="diseaseStatus"
                fieldlabel="Disease status"
                value={values.diseaseStatus}
                options={[
                  { label: 'Relapse', value: 'Relapse' },
                  { label: 'None', value: 'None' },
                ]}
                handleChange={(value) => {
                  if (value === 'None') {
                    setValues({
                      ...values,
                      localRelapse: false,
                      localRelapseSite: '',
                      localRelapseDate: null,
                      regionalRelapse: false,
                      regionalRelapseSite: '',
                      regionalRelapseDate: null,
                      distantRelapse: false,
                      distantRelapseSite: '',
                      distantRelapseDate: null,
                    });
                  }
                  setFieldValue('diseaseStatus', value);
                }}
                data-testid="disease-status"
              />
              {values.diseaseStatus === 'Relapse' && (
                <>
                  <Typography variant="overline" marginBottom={2}>
                    Extent of disease - Select if applicable
                  </Typography>
                  <RelapseSiteInfo
                    label="Local relapse"
                    id="localRelapse"
                    handleChange={handleChange}
                    setValue={setFieldValue}
                    checkedValue={values.localRelapse}
                    siteValue={values.localRelapseSite}
                    dateValue={values.localRelapseDate}
                  />
                  <RelapseSiteInfo
                    label="Regional relapse"
                    id="regionalRelapse"
                    handleChange={handleChange}
                    setValue={setFieldValue}
                    siteValue={values.regionalRelapseSite}
                    dateValue={values.regionalRelapseDate}
                    checkedValue={values.regionalRelapse}
                  />
                  <RelapseSiteInfo
                    label="Distant relapse"
                    id="distantRelapse"
                    handleChange={handleChange}
                    setValue={setFieldValue}
                    siteValue={values.distantRelapseSite}
                    dateValue={values.distantRelapseDate}
                    checkedValue={values.distantRelapse}
                  />
                </>
              )}
            </>
          )}
          <ROTextField
            id="additionalNotes"
            fieldlabel="Additional Notes"
            value={values.additionalNotes}
            onChange={handleChange}
            rows={4}
            multiline
            placeholder="Type your notes here..."
            data-testid="additional-notes"
          />
        </Stack>
      </Stack>
      <Stack
        borderTop={`2px solid ${theme.palette.primary.main}`}
        padding={2}
        height="78px"
        bgcolor="white"
        direction="row"
        justifyContent="space-between">
        {[OUTCOME_STATUS.IN_PROGRESS, OUTCOME_STATUS.NOT_STARTED].includes(outcome.status) &&
        !outcome.isDefaultTimepoint ? (
          <Button mode="outlined" onClick={() => setDiscardOpen(true)}>
            Discard draft
          </Button>
        ) : (
          <div />
        )}
        <Stack direction="row">
          <Button mode="outlined" onClick={handleBack}>
            Back
          </Button>
          <Button onClick={submitForm}>Submit</Button>
        </Stack>
      </Stack>
      <AutoSave saveMethod={handleUpdateDraftOutcome} debounceMs={1000} />
      <DiscardModal
        open={discardOpen}
        setOpen={setDiscardOpen}
        outcome={outcome}
        handleBack={handleBack}
        patientId={patientId}
      />
    </Stack>
  );
};

export default FormContainer;
