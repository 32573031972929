// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { RODoseSiteCarousel } from 'op-components';
import { useErrorModalContext } from 'op-contexts';
import { groupBy } from 'op-utils';
import { GeneralError, LoadingSpinner } from 'shared-components/components';
import { GET_DOSE_SITE_SUMMARIES } from './PatientProfileQueries';
import { Stack, Typography, Card } from '@mui/material';
import { IDose } from './Summary/interfaces';

interface ROPatientDoseNavType {
  id: string;
}

interface ContainerProps {
  mqDoses: IDose[];
  ariaDoses: IDose[];
  doseSize: number;
  index: number;
  setIndex: (index: number) => void;
}

const DoseSiteSummaryContainer = ({ mqDoses, ariaDoses, doseSize, index, setIndex }: ContainerProps): JSX.Element => {
  if (mqDoses || ariaDoses) {
    const handleSelect = (selectedIndex: number) => {
      setIndex(selectedIndex % doseSize);
    };

    return (
      <RODoseSiteCarousel
        handleSelect={handleSelect}
        index={index}
        mqDoses={mqDoses}
        ariaDoses={ariaDoses}
        doseSize={doseSize}
      />
    );
  }
  return <></>;
};

const DoseSiteSummary = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  const match = useRouteMatch<ROPatientDoseNavType>();
  const [index, setIndex] = useState(0);
  const { id: patientId } = match.params;
  const { data, loading, error } = useQuery(GET_DOSE_SITE_SUMMARIES, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (error) {
      return setError('NON_BLOCKING');
    }
  }, [error]);

  useEffect(() => {
    setIndex(0);
  }, [data]);

  if (!data && loading) return <LoadingSpinner loadingText={'Loading dose site summaries'} relativeSpinner={true} />;
  if (!data && error) {
    return (
      <GeneralError
        primaryText="Unable to load dose site summaries."
        secondaryText="Please contact GenesisCare Innovation support."
      />
    );
  }

  const mqDoses = data.doseSiteSummaries.filter((dss: IDose) => !dss.isAria);
  const ariaDoses = data.doseSiteSummaries.filter((dss: IDose) => dss.isAria);
  const groupedDose = groupBy(ariaDoses, 'categorySiteGroup');
  const doseSize = mqDoses.length + Object.keys(groupedDose).length;
  const tracker = doseSize ? `${index + 1}/${doseSize}` : '';

  return (
    <>
      <Card sx={{ height: '50%', padding: '16px', boxShadow: '0px 2px 8px rgba(113, 110, 106, 0.2)' }}>
        <Stack height={1}>
          <Typography variant="h6" data-test-id="dose-site-summary-title" paddingBottom={1}>
            Dose site summary {tracker}
          </Typography>
          <DoseSiteSummaryContainer
            mqDoses={mqDoses}
            ariaDoses={ariaDoses}
            doseSize={doseSize}
            index={index}
            setIndex={setIndex}
          />
        </Stack>
      </Card>
    </>
  );
};

export default DoseSiteSummary;
