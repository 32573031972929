// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';

import { Autocomplete, AutocompleteProps, InputProps, Tooltip, useTheme } from '@mui/material';
import { SelectOptionType } from '../ROSelect/BaseSelect';
import BaseTextField, { Props as BaseTextFieldProps } from '../ROTextField/BaseTextField';
import InputAdornment from '@mui/material/InputAdornment';

export interface Props
  extends Omit<
    AutocompleteProps<SelectOptionType, boolean | undefined, boolean | undefined, boolean | undefined>,
    'renderInput' | 'onChange'
  > {
  options: SelectOptionType[];
  inputProps?: BaseTextFieldProps; // Props passed through to the BaseTextField input
  InputProps?: InputProps;
  value: SelectOptionType | undefined | null;
  onChange: (value: SelectOptionType | string) => void;
  label?: string;
  icon?: JSX.Element;
}

const BaseAutocomplete = ({
  inputProps,
  onChange,
  value,
  sx,
  InputProps,
  placeholder,
  label,
  icon,
  ...rest
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(value?.value || '');
  const theme = useTheme();
  return (
    <Autocomplete
      ListboxProps={{
        sx: {
          '& .MuiAutocomplete-option': { margin: 0 },
        },
        style: {
          maxHeight: 250,
        },
      }}
      isOptionEqualToValue={(option: SelectOptionType, value: SelectOptionType | string) => {
        if (typeof value === 'string') return option.value === value;
        return option.value === value.value;
      }}
      getOptionLabel={(option: SelectOptionType | string): string => {
        if (typeof option === 'string') return option;
        return option.label;
      }}
      fullWidth
      value={value || null}
      inputValue={inputValue} // This stops the component appearing empty on first load for 1 second
      data-testid={rest?.id}
      getOptionDisabled={(option: SelectOptionType) => option?.disabled || false}
      onInputChange={(_, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      {...rest}
      onChange={(_: any, value: NonNullable<string | SelectOptionType> | (string | SelectOptionType)[] | null) => {
        // This jank is just to turn all the possible outputs of autocomplete into a consistent 'SelectOptionType'
        if (value === null) return onChange('');
        if (Array.isArray(value)) {
          const firstValue = value?.[0];
          return onChange(firstValue);
        }
        return onChange(value);
      }}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props as { key: string };
        return (
          <Tooltip
            arrow
            placement="right"
            disableInteractive
            title={option?.tooltip}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -300],
                    },
                  },
                ],
              },
            }}
            key={key}>
            <li
              key={key}
              {...optionProps}
              style={{
                backgroundColor: selected ? theme.palette.action.selected : 'white' + ' !important',
                // @ts-ignore This is a hack to get the hover effect to work
                '&:hover': {
                  backgroundColor: selected
                    ? theme.palette.action.selected
                    : theme.palette.action.hover + ' !important',
                },
              }}>
              {option.label}
            </li>
          </Tooltip>
        );
      }}
      renderInput={(params) => (
        <BaseTextField
          // This is all necessary in order to pass correct props to base text field
          // Placeholder could possible be refactored to always be passed to the autocomplete
          // using the inputProps instead
          {...params}
          {...inputProps}
          placeholder={placeholder ?? inputProps?.placeholder}
          id={`${rest?.id}-input`}
          data-testid={`${rest?.id}-input`}
          fieldLabel={label}
          InputProps={{
            ...params?.InputProps,
            ...InputProps,
            startAdornment: (
              <>
                {icon && <InputAdornment position="start">{icon}</InputAdornment>}
                {params?.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      sx={{
        ...{
          '& .MuiOutlinedInput-root': {
            paddingLeft: '0 !important',
          },
          '& .MuiAutocomplete-clearIndicator': {
            '& .MuiSvgIcon-root': {
              width: '20px',
              height: '20px',
            },
          },
        },
        ...sx,
      }}
    />
  );
};

export default BaseAutocomplete;
