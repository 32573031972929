import { getCareplanStatusColor } from 'op-components/SideNav/common';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { useHistory } from 'react-router-dom';
import { Stack, Typography, useTheme, TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material';
import { hasSubmitted, changesFromLastEdited } from 'op-pages/RO/Careplan/Banner/Banner';
import { visuallyHidden } from '@mui/utils';
import { UserContext } from 'op-contexts';
import { useContext } from 'react';
import { Search } from '@mui/icons-material';
import { BaseTextField } from 'shared-components/components/FormFields';
import { useState, useRef } from 'react';

interface PatientNameProps {
  patientName: string;
  ida: string;
  patientId: string;
  photoUrl: string;
}

export const PatientName = (props: PatientNameProps): JSX.Element => {
  const { patientName, ida, patientId, photoUrl } = props;
  const theme = useTheme();
  const history = useHistory();
  return (
    <Stack direction="row" alignItems="center" gap={1} data-testid="careplan-table-patient-name">
      <PhotoWidget url={photoUrl} enableOverlay={true} size={'sm'} />
      <Stack>
        <Typography
          variant="body1"
          color={theme.palette.primary.dark}
          sx={{ cursor: 'pointer', textDecoration: 'underline', '&:hover': { color: theme.palette.primary.light } }}
          onClick={(e) => {
            if (!patientId) return;
            e.stopPropagation();
            history.push(`/radiation/patient/${patientId}/summary`);
          }}>
          {patientName}
        </Typography>
        <Typography variant="body1">Patient ID: {ida}</Typography>
      </Stack>
    </Stack>
  );
};

export const Status = ({ careplanStatus }: { careplanStatus: string }): JSX.Element => {
  const status =
    careplanStatus === 'CPOT'
      ? 'CPOT'
      : careplanStatus
          .toLowerCase()
          .replace('_', ' ')
          .split(' ')
          .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
          .join(' ');

  return (
    <Stack direction="row" alignItems="center" gap={1} data-testid="careplan-table-status">
      <Stack
        height={'16px'}
        width={'16px'}
        borderRadius={'50%'}
        data-testid="careplan-table-status-pill"
        sx={{ backgroundColor: getCareplanStatusColor(careplanStatus) }}
      />
      {status}
    </Stack>
  );
};

interface CareplanNameProps {
  careplan: {
    submittedAt: string;
    simulationModification: boolean;
    prescriptionModification: boolean;
    diagnosisModification: boolean;
  };
  careplanName: string;
}

export const CareplanName = ({ careplan, careplanName }: CareplanNameProps): JSX.Element => {
  const userContext = useContext(UserContext);
  const hasUnsubmittedChanges = hasSubmitted(careplan, userContext.state.timezone) && changesFromLastEdited(careplan);
  const theme = useTheme();
  return (
    <Stack data-testid="careplan-table-careplan-name">
      <Typography variant="body1">{careplanName}</Typography>
      {hasUnsubmittedChanges && (
        <Typography data-testid="unsubmitted-changes" variant="body1" color={theme.palette.warning.dark}>
          Unsubmitted changes
        </Typography>
      )}
    </Stack>
  );
};

export const Priority = ({ priority }: { priority: string }): JSX.Element => {
  const careplanPriority = priority === 'emerg' ? 'Emergency' : priority;
  return <Stack textTransform="capitalize">{careplanPriority}</Stack>;
};

const TABLE_LABELS = {
  PATIENT_NAME: 'Patient name',
  DEPARTMENT: 'Department',
  CAREPLAN: 'Careplan',
  STATUS: 'Status',
  PRIORITY: 'Priority',
  LAST_MODIFIED: 'Last modified',
};

type Order = 'asc' | 'desc';

export interface TableData {
  id: string;
  patientName: string;
  patientId: string;
  ida: string;
  photoUrl: string;
  department: string;
  careplanName: string;
  status: string;
  priority: string;
  lastModified: string;
}

interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  id: keyof TableData;
  label: string;
  enableSorting: boolean;
  width: string;
}

const headCells: readonly HeadCell[] = [
  { id: 'patientName', enableSorting: true, label: TABLE_LABELS.PATIENT_NAME, width: '20%' },
  { id: 'department', enableSorting: false, label: TABLE_LABELS.DEPARTMENT, width: '10%' },
  { id: 'careplanName', enableSorting: false, label: TABLE_LABELS.CAREPLAN, width: '30%' },
  { id: 'status', enableSorting: false, label: TABLE_LABELS.STATUS, width: '12%' },
  { id: 'priority', enableSorting: false, label: TABLE_LABELS.PRIORITY, width: '12%' },
  { id: 'lastModified', enableSorting: true, label: TABLE_LABELS.LAST_MODIFIED, width: '16%' },
];

export const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
            sx={{ backgroundColor: 'white' }}>
            {headCell.enableSorting ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface InputProps {
  inputValue: string;
  id: string;
  placeholder: string;
  onSubmitSearch: any;
}

export const SearchInput = (props: InputProps): JSX.Element => {
  const [currentTextValue, setCurrentTextValue] = useState(props.inputValue);
  const searchRef = useRef<HTMLInputElement>(null);

  const changeSearchTerm = (searchTerm: string) => {
    setCurrentTextValue(searchTerm);
  };

  const submitSearch = (keyType: string, searchTerm: string) => {
    if (keyType === 'Enter') {
      props.onSubmitSearch(searchTerm);
    }
  };

  const { id, placeholder } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Search onClick={() => submitSearch('Enter', currentTextValue)} color="primary" sx={{ margin: '0 4px' }} />
      <BaseTextField
        ref={searchRef}
        value={currentTextValue}
        type="text"
        id={id}
        placeholder={placeholder}
        onChange={(e) => changeSearchTerm(e.target.value)}
        onKeyDown={(e) => submitSearch(e.key, (e.target as HTMLInputElement).value)}
        sx={{ width: '360px' }}
      />
    </Stack>
  );
};
