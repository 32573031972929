// eslint-disable-next-line no-use-before-define
import { PatientCard } from 'op-components';
import SidePatientNav from 'op-components/SideNav/SidePatientNav';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useOnClickOutside } from 'shared-components/utils/CustomHooks';
import { styled } from '@mui/system';
import {
  AlertsCard,
  AllergiesCard,
  AppointmentsCard,
  DiagnosisCard,
  EmergencyContactCard,
  InsuranceCard,
  TreatmentTeamCard,
} from './PatientDetailsCards';
import './PatientSummary.scss';
import { ROPatientContext } from './context';
import { Stack } from '@mui/material';

const MiddleContainer = styled(Stack)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  width: 100%;
`;

const StyedPatientSummaryRow = styled(Stack, {
  shouldForwardProp: (prop) => !['$rowSpace', '$marginBottom'].includes(prop as string),
})<{ $marginBottom?: number | string; $rowSpace?: number | string }>`
  position: relative;
  min-height: 0;
  flex: 1;
  padding: ${(props) => props.$rowSpace || '16px 0'};
`;

const StyledROSidePatientNavCol = styled(Stack)`
  max-width: 240px;
  min-width: 240px;
  background-color: ${(props) => props.theme.palette.background.secondary};
  border-right: 1px solid rgba(0, 0, 0, 0.2);
`;

const StyledROSidePatientNav = styled(SidePatientNav)`
  max-width: 240px;
  min-width: 240px;
  height: 100vh;
`;

const MoreDetailsContainer = styled('div')`
  position: absolute;
  top: 1px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 49;
  padding: 0;
  overflow-y: auto;
`;

const MoreDetailsBody = styled('div')`
  padding-top: 16px;
  background-color: white;
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const MoreInfoBox = styled('div')<any>`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 4px;
  width: ${(props) => `calc(${props.cardWidth} - 16px)`};
  margin: 0 8px 16px;
`;

interface Props extends RouteComponentProps {
  children: JSX.Element | JSX.Element[];
  refreshChartSummary?: any;
  patientCardView?: string;
  rowSpace?: string;
  marginBottom?: string;
}

const OuterContainer = ({
  children,
  refreshChartSummary,
  patientCardView,
  rowSpace,
  marginBottom,
}: Props): JSX.Element => {
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;

  const targetRef = useRef<HTMLDivElement>(null);
  const { patientDetails, patientAlerts } = useContext(ROPatientContext);
  const cardRef = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState('');

  useEffect(() => {
    const handleResize = () => {
      if (cardRef.current) {
        const { width } = cardRef.current.getBoundingClientRect();
        if (width >= 1500 && width / 6 < 262) {
          return setCardWidth('20%');
        }
        if (width < 1500 && width / 6 < 262) {
          return setCardWidth('25%');
        } else return setCardWidth('16.666667%');
      }
    };
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  });

  const moreDetailsClickHandler = () => {
    setShowMoreDetails(!showMoreDetails);
  };

  useOnClickOutside(targetRef, (event: any) => {
    if (typeof event?.target?.className === 'string' && event?.target?.className.includes('more-details-container')) {
      setShowMoreDetails(false);
    }
  });

  const detailsCards = [
    <TreatmentTeamCard name={'Treating Physicians'} allReferrers={patientDetails?.allReferrers} />,
    <AppointmentsCard name={'Upcoming Appointments'} appointments={patientDetails?.appointments} />,
    <InsuranceCard name={'Insurance'} patientDetails={patientDetails} />,
    <DiagnosisCard
      name={'Diagnosis'}
      patientDiagnosis={patientDetails?.carepathPatientDiagnosis.filter(
        (diagnosis: any) => diagnosis?.isMostRecent && diagnosis?.diagnosisId,
      )}
    />,
    <AllergiesCard name={'Allergies'} allergies={patientDetails?.allergy} />,
    <AlertsCard name={'Alerts'} alerts={patientAlerts} />,
    <EmergencyContactCard name={'Emergency Contact'} patientDetails={patientDetails} />,
  ];

  return (
    <Fragment>
      <Stack
        id="patient-summary-primary"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
          position: 'absolute',
          paddingTop: '56px',
        }}>
        <StyledROSidePatientNavCol>
          <StyledROSidePatientNav patientId={patientId} refreshChartSummary={refreshChartSummary} />
        </StyledROSidePatientNavCol>

        <MiddleContainer ref={cardRef}>
          <PatientCard
            id={patientId}
            simple={false}
            moreDetailsClickHandler={moreDetailsClickHandler}
            showMoreDetails={showMoreDetails}
            patientCardView={patientCardView}
          />
          <StyedPatientSummaryRow
            data-testid="patient-summary-row"
            $rowSpace={rowSpace}
            $marginBottom={marginBottom}
            style={{ display: 'flex', flexDirection: 'row' }}>
            {showMoreDetails && (
              <MoreDetailsContainer className="more-details-container">
                <MoreDetailsBody className="more-details-body" ref={targetRef}>
                  {patientDetails &&
                    detailsCards.map((item: any) => (
                      <MoreInfoBox cardWidth={cardWidth} key={item.props.name}>
                        {item}
                      </MoreInfoBox>
                    ))}
                </MoreDetailsBody>
              </MoreDetailsContainer>
            )}
            {children}
          </StyedPatientSummaryRow>
        </MiddleContainer>
      </Stack>
      <ToastContainer limit={3} style={{ width: 'fit-content' }} />
    </Fragment>
  );
};

const routedComponent = withRouter(OuterContainer);
export default routedComponent;
