// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import OuterContainer from '../PatientSummary/OuterContainer';
import { Collapse, IconButton, Typography, useTheme } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import find from 'lodash/find';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { useErrorModalContext } from 'op-contexts';
import { ROPatientContextProvider } from '../PatientSummary/context';
import { GET_OUTCOMES_BY_DIAGNOSIS, CREATE_OR_UPDATE_OUTCOME } from './Queries';
import {
  OutcomesWrapper,
  OutcomesContainer,
  Container,
  ListItem,
  ListItemContainer,
  OutcomeCardContainer,
  ModalErrorMessage,
  ModalFieldLabel,
  ModalDiagnosisTitle,
  ModalFormLabel,
  ModalRowFormControl,
  ModalTextField,
  ModalTextFieldLabel,
  ModalRowValidationMessage,
  ListItemsContainer,
  ListItemDiagnosisTypography,
  ListItemDiagnosisDateTypography,
  BackButtonWrapper,
} from './StyledComponents';
import OutcomeTimePointCard from './OutcomeTimePointCard';
import { LoadingSpinner } from 'shared-components/components';
import { OutcomeInterface } from './Interfaces';
import { OUTCOME_STATUS } from './Constants';
import moment from 'moment';
import { Stack } from '@mui/material';

const MAX_SCREEN_WIDTH = 1920;
const SMALL_SCREEN_WIDTH = 1280;

interface ROPatientOutcomesNavType {
  id: string;
}

const OutcomesPage = (): JSX.Element => {
  const windowSize = useWindowSize();
  const history = useHistory();
  const theme = useTheme();

  let outcomeCardCount: number;

  if (windowSize.width >= MAX_SCREEN_WIDTH) {
    outcomeCardCount = 5;
  } else if (windowSize.width >= SMALL_SCREEN_WIDTH) {
    outcomeCardCount = 4;
  } else {
    outcomeCardCount = 3;
  }

  const match = useRouteMatch<ROPatientOutcomesNavType>();
  const { id: patientId } = match.params;
  const { setError } = useErrorModalContext();

  const {
    data: diagnosisListData,
    loading: diagnosisListLoading,
    error: diagnosisListError,
  } = useQuery(GET_OUTCOMES_BY_DIAGNOSIS, {
    variables: { patientId },
  });

  const [createOrUpdateOutcome, { error: createOrUpdateOutcomeError }] = useMutation(CREATE_OR_UPDATE_OUTCOME, {
    refetchQueries: [{ query: GET_OUTCOMES_BY_DIAGNOSIS, variables: { patientId: patientId } }],
  });

  useEffect(() => {
    if (diagnosisListError || createOrUpdateOutcomeError) return setError();
  }, [diagnosisListError, createOrUpdateOutcomeError]);

  const [closed, setClosed] = React.useState({});

  const handleClick = (index: number) => {
    const copyOpen = { ...closed };
    // @ts-ignore
    copyOpen[index] = !!!closed[index];
    setClosed(copyOpen);
  };

  const [showAddOutcomeModal, setShowAddOutcomeModal] = useState(false);

  const [otherTimePoint, setOtherTimePoint] = useState<string | undefined>(undefined);
  const [selectedDiagnosisID, setSelectedDiagnosisID] = useState();

  const [validationMessage, setValidationMessage] = useState<string>('');

  const limitToOneDecimal = (str: string): string => {
    const regex = /^-?\d*\.?\d{0,1}$/; // Check whether the value only has one decimal place

    if (regex.test(str)) {
      return str;
    } else {
      // Cuts the string to one decimal place
      return str.replace(/(\.\d{1})\d+/, '$1');
    }
  };

  const validateOtherTimePoint = (timePoint: string, defaultTimePoints: number[]): boolean => {
    const existingTimePoints = getExistingTimePointsForDiagnosis().concat(defaultTimePoints);
    if (timePoint) {
      const valueInFloat = parseFloat(timePoint);
      if (existingTimePoints.includes(valueInFloat)) {
        setValidationMessage('This outcome already exists, enter another time point');
        return false;
      } else if (valueInFloat < 1) {
        setValidationMessage('The time point cannot be less than 1 year, enter another time point');
        return false;
      } else {
        setValidationMessage('');
        return true;
      }
    } else {
      setValidationMessage('This field is required');
      return false;
    }
  };

  const handleOnChange = (modalValue: string) => {
    const timePoint = limitToOneDecimal(modalValue);
    setOtherTimePoint(timePoint);
  };

  const handleClose = (): void => {
    setShowAddOutcomeModal(false);
    setOtherTimePoint(undefined);
    setValidationMessage('');
  };

  const handleContinue = (): void => {
    const validTimepoint = validateOtherTimePoint(otherTimePoint ?? '', diagnosisListData.defaultTimePoints);
    if (validTimepoint) {
      if (otherTimePoint) {
        const valueInFloat = parseFloat(otherTimePoint);
        if (!isNaN(valueInFloat)) {
          createOrUpdateOutcome({
            variables: {
              timePoint: valueInFloat,
              diagnosisId: selectedDiagnosisID,
            },
          })
            .then((response) => {
              handleClose();

              // Navigate to form after creating a new custom time point
              const outcomeId = response?.data?.createOrUpdateOutcome?.outcome?.id;
              const timePoint = response?.data?.createOrUpdateOutcome?.outcome?.timePoint;

              if (outcomeId && timePoint) {
                history.push(`/radiation/patient/${patientId}/outcomes/${outcomeId}`, {
                  timePoint,
                  diagnosisId: selectedDiagnosisID,
                });
              }
            })
            .catch(() => {
              setError();
            });
        }
      }
    }
  };

  const getExistingTimePointsForDiagnosis = () => {
    const outcomes = find(diagnosisListData.outcomesByDiagnosis, (outcome) => {
      return outcome.diagnosisId === selectedDiagnosisID;
    }).outcomes;
    const existingTimePoints = outcomes.map((item: any) => item.timePoint);

    return existingTimePoints;
  };

  const renderAddOutcomeModal = () => {
    const diagnosis =
      find(diagnosisListData.outcomesByDiagnosis || [], (outcome) => {
        return outcome.diagnosisId === selectedDiagnosisID;
      })?.diagnosis || '';

    return (
      <Modal width={'small'} id={'outcome-modal'} open>
        <ModalHeader>
          <Typography variant="h6" fontWeight={600}>
            Add Outcome
          </Typography>
        </ModalHeader>
        <ModalBody sx={{ padding: '0px 24px !important' }}>
          <ModalDiagnosisTitle data-cy="outcome-modal-title">{diagnosis}</ModalDiagnosisTitle>
          <Container>
            <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
              <Stack>
                <ModalFormLabel>
                  <ModalFieldLabel color={theme.palette.text.primary}>Other time point</ModalFieldLabel>
                  <ModalFieldLabel color={theme.palette.error.main}>*</ModalFieldLabel>
                </ModalFormLabel>
                <Stack>
                  <ModalRowFormControl>
                    <ModalTextField
                      id={'other-time-point-text-field'}
                      error={!!validationMessage}
                      onChange={(event: any) => handleOnChange(event.target.value)}
                      value={otherTimePoint}
                      type={'number'}
                      placeholder="Enter years e.g. 1.2"
                      required
                      onKeyPress={(event: any) => {
                        if (event.key === 'e' || event.key === '-') {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        step: '0.1',
                        min: '1',
                      }}
                    />
                    <ModalTextFieldLabel>Years</ModalTextFieldLabel>
                  </ModalRowFormControl>
                  <ModalRowValidationMessage>
                    <ModalErrorMessage data-cy="modal-validation-message">{validationMessage}</ModalErrorMessage>
                  </ModalRowValidationMessage>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </ModalBody>
        <ModalFooter>
          <BackButtonWrapper>
            <Button mode="outlined" size="auto" onClick={handleClose} data-cy={'add-outcome-back-button'}>
              Back
            </Button>
          </BackButtonWrapper>
          <Button mode="contained" size="small" onClick={handleContinue} data-cy={'add-outcome-continue-button'}>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <OutcomesWrapper style={{ height: '720px' }}>
          <OutcomesContainer>
            {diagnosisListLoading ? (
              <LoadingSpinner
                loadingText={'Loading Outcomes Dashboard'}
                subtitle={'Please wait while we set things up for you'}
              />
            ) : (
              <Container>
                <Typography variant="h6">Outcomes</Typography>
                <React.Fragment>
                  {showAddOutcomeModal && renderAddOutcomeModal()}
                  {diagnosisListData?.outcomesByDiagnosis && diagnosisListData.outcomesByDiagnosis.length > 0 ? (
                    <ListItemsContainer>
                      {diagnosisListData.outcomesByDiagnosis.map((outcomeByDiagnosis: any, index: number) => {
                        const {
                          diagnosis,
                          diagnosisId,
                          diagnosisDate,
                          outcomes: existingOutcomes,
                        } = outcomeByDiagnosis;

                        // Combine existing outcomes with default outcomes
                        const outcomes = diagnosisListData?.defaultTimePoints
                          ?.reduce(
                            (outcomes: OutcomeInterface[], timePoint: number) => {
                              if (existingOutcomes.find((outcome: OutcomeInterface) => outcome.timePoint === timePoint))
                                return outcomes;
                              return [
                                ...outcomes,
                                {
                                  timePoint: timePoint,
                                  status: OUTCOME_STATUS.NOT_STARTED,
                                  active: true,
                                  lastModified: 'not recorded',
                                },
                              ];
                            },
                            [...existingOutcomes],
                          )
                          .sort((a: OutcomeInterface, b: OutcomeInterface) => a.timePoint - b.timePoint);

                        return (
                          <React.Fragment key={`${diagnosisId}-diagnosis-container`}>
                            <ListItemContainer
                              key={`${diagnosisId}-${diagnosis}-diagnosis-container`}
                              data-cy={`${diagnosisId}-${diagnosis}-diagnosis-container`}>
                              <ListItem data-cy={`list-item-${index}`} onClick={() => handleClick(index)}>
                                <IconButton
                                  size="small"
                                  style={{
                                    //@ts-ignore
                                    transform: !closed[index] ? 'rotate(90deg)' : '',
                                    transition: 'all 100ms linear',
                                    outline: '0',
                                    marginLeft: '14px',
                                    marginRight: '6px',
                                  }}>
                                  <KeyboardArrowRight style={{ color: theme.palette.primary.dark }} />
                                </IconButton>
                                <ListItemDiagnosisTypography>{diagnosis}</ListItemDiagnosisTypography>
                                <ListItemDiagnosisDateTypography>{`Primary Diagnosis Date: ${
                                  moment(diagnosisDate).format(CurrentAppConfig.OutcomesPage.DateFormat) ||
                                  'Not recorded'
                                }`}</ListItemDiagnosisDateTypography>
                              </ListItem>
                            </ListItemContainer>
                            <Collapse
                              style={{ minHeight: 'none!important', marginBottom: '8px', marginTop: '8px' }}
                              // @ts-ignore
                              in={!closed[index]}
                              timeout="auto"
                              unmountOnExit>
                              <OutcomeCardContainer
                                key={`${index}`}
                                style={{
                                  paddingBottom:
                                    diagnosisListData.outcomesByDiagnosis.length - 1 === index ? 16 : undefined,
                                }}>
                                {outcomes.map(
                                  ({ id, timePoint, lastModified, status }: OutcomeInterface, outcomeIndex: number) => {
                                    if (lastModified !== 'not recorded')
                                      lastModified = moment
                                        .utc(lastModified)
                                        .local()
                                        .format(CurrentAppConfig.OutcomesPage.DateFormat);
                                    const onClick = () => {
                                      if (status !== OUTCOME_STATUS.NOT_STARTED) {
                                        history.push(`/radiation/patient/${patientId}/outcomes/${id}`, {
                                          timePoint,
                                          diagnosisId,
                                        });
                                        return;
                                      }
                                      createOrUpdateOutcome({
                                        variables: {
                                          timePoint,
                                          diagnosisId,
                                        },
                                      })
                                        .then((response) => {
                                          const outcomeId = response.data.createOrUpdateOutcome.outcome.id;
                                          const timePoint = response.data.createOrUpdateOutcome.outcome.timePoint;
                                          history.push(`/radiation/patient/${patientId}/outcomes/${outcomeId}`, {
                                            timePoint,
                                            diagnosisId,
                                          });
                                        })
                                        .catch(() => {
                                          setError();
                                        });
                                    };
                                    return (
                                      <OutcomeTimePointCard
                                        id={id}
                                        key={`${id}-${outcomeIndex}`}
                                        index={index}
                                        outcomeIndex={outcomeIndex}
                                        outcomeCardCount={outcomeCardCount}
                                        diagnosisId={diagnosisId}
                                        timePoint={timePoint}
                                        lastModified={lastModified}
                                        status={status}
                                        onClick={onClick}
                                      />
                                    );
                                  },
                                )}
                                <OutcomeTimePointCard
                                  id={'other'}
                                  index={index}
                                  outcomeIndex={outcomes.length + 1}
                                  outcomeCardCount={outcomeCardCount}
                                  diagnosisId={diagnosisId}
                                  timePoint={0}
                                  lastModified={''}
                                  status={''}
                                  onClick={() => {
                                    setSelectedDiagnosisID(diagnosisId);
                                    setShowAddOutcomeModal(true);
                                  }}
                                />
                              </OutcomeCardContainer>
                            </Collapse>
                          </React.Fragment>
                        );
                      })}
                    </ListItemsContainer>
                  ) : (
                    <Typography variant="body1">No diagnosis recorded, therefore no follow up available.</Typography>
                  )}
                </React.Fragment>
              </Container>
            )}
          </OutcomesContainer>
        </OutcomesWrapper>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default OutcomesPage;
