// eslint-disable-next-line no-use-before-define
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { useFormikContext, FormikProps } from 'formik';
import classNames from 'classnames';
import ROPatientDiagnosisContext from './Context';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  GET_PATIENT_DIAGNOSIS_LIST,
  GET_ORDERED_DIAGNOSIS_LIST,
  CREATE_DIAGNOSIS,
  GET_DIAGNOSIS,
  UPDATE_DIAGNOSIS,
} from './Queries';

import { DiagnosisType, DiagnosisRouterParams, DiagnosisActionType, DiagnosisState } from './Interface';
import ROPatientDiagnosisCurrentCode from './CurrentCode';
import ROPatientCarePlanPageFooter from '../Footer';
import './DiagnosisSelectionPage.scss';
import { useQuery, useMutation } from '@apollo/client';
import { Logger } from 'shared-components/utils';
import { CAREPLAN_PAGES } from '../Constants';
import { UPDATE_CAREPLAN_DIAGNOSIS, CREATE_CAREPLAN } from '../Queries';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { useErrorModalContext } from 'op-contexts';
import { Divider, Typography, useTheme } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
const logger = new Logger('ROPatientDiagnosisSelectionPage');
const PRIMARY_DIAG_HEADING = 'Primary Diagnosis Selection';
const PRIMARY_SUBTITLE = 'Use existing primary diagnosis or record a new primary diagnosis';
const METASTASIS_DIAG_HEADING = 'Metastasis Diagnosis Selection';
const METASTASIS_SUBTITLE = 'Use existing metastasis diagnosis or record a new metastasis diagnosis';
const PRIMARY_DIAG_BTN_TITLE = 'New primary diagnosis';
const METASTASIS_DIAG_BTN_TITLE = 'New metastasis diagnosis';
const PRIMARY_DIAG_SEC_HEADING = 'Existing Primary Diagnosis';
const METASTASIS_DIAG_SEC_HEADING = 'Existing Metastasis Diagnosis';

interface PatientDiagnosisQueryResultData {
  diagnosisList: DiagnosisType[];
}

const ROPatientDiagnosisSelectionPage = (): JSX.Element => {
  const [creatingDiagnosis, setCreatingDiagnosis] = useState(false);
  const { state, dispatch } = useContext(ROPatientDiagnosisContext);
  const match = useRouteMatch<DiagnosisRouterParams>();
  const { id: patientId, diagnosisId, careplanId, page, oncologyType } = match.params;
  const history = useHistory();
  const { setError } = useErrorModalContext();
  const { setFieldValue, values }: FormikProps<any> = useFormikContext();
  const [createDiagnosis] = useMutation(CREATE_DIAGNOSIS);
  const [createCarePlan] = useMutation(CREATE_CAREPLAN);
  const theme = useTheme();
  const {
    data,
    error,
    refetch: refetchDiagnosis,
  } = useQuery(GET_DIAGNOSIS, {
    variables: { diagnosisId },
    skip: diagnosisId === 'create',
    onCompleted: (): void => {
      if (data)
        dispatch({ type: DiagnosisActionType.REPLACE_CURRENT_DIAGNOSIS, payload: { diagnosis: data.diagnosis } });
    },
  });

  useEffect(() => {
    if (diagnosisId !== 'create') {
      refetchDiagnosis();
    }
  }, [diagnosisId]);

  const { diagnosis } = state;
  const secondaryCode =
    diagnosis.diagnosisCode && !diagnosis.isPrimaryDiagnosis ? diagnosis.diagnosisCode.diagnosisCode : '';
  const {
    data: diagnosisListData,
    loading: diagnosisListLoading,
    error: diagnosisListError,
  } = useQuery(GET_PATIENT_DIAGNOSIS_LIST, {
    variables: { patientId, diagnosisType: page, secondaryCode: secondaryCode },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  // prefectch dx list for newPrimary page
  useQuery(GET_ORDERED_DIAGNOSIS_LIST, {
    variables: { isPrimaryDiagnosis: page === 'primary', patientId },
    fetchPolicy: 'network-only',
  });

  const heading = page === 'primary' ? PRIMARY_DIAG_HEADING : METASTASIS_DIAG_HEADING;
  const subtitle = page === 'primary' ? PRIMARY_SUBTITLE : METASTASIS_SUBTITLE;
  const btnTitle = page === 'primary' ? PRIMARY_DIAG_BTN_TITLE : METASTASIS_DIAG_BTN_TITLE;
  const secondaryHeading = page === 'primary' ? PRIMARY_DIAG_SEC_HEADING : METASTASIS_DIAG_SEC_HEADING;
  const newPage = page === 'primary' ? 'newPrimary' : 'newMetastasis';

  const renderNoDiagnosisResults = (): JSX.Element => {
    return (
      <Typography variant="body1" style={{ marginLeft: '9px' }}>
        No existing diagnosis found.
      </Typography>
    );
  };

  const updateExistingCareplanDiagnosis = (
    diagnosis: DiagnosisType,
    diagnosisId: string,
    diagnosisItem: DiagnosisType,
    page: string,
    careplanId: string,
    dispatch: any,
    updateDiagnosis: any,
    updateCarePlanDiagnosis: any,
  ): void => {
    if (!['primary', 'metastasis'].includes(page)) {
      return;
    }
    if (!diagnosisItem.diagnosisCode) {
      return;
    }
    dispatch({
      type: DiagnosisActionType.REPLACE_CURRENT_DIAGNOSIS,
      payload: { diagnosis: { ...diagnosis, ...diagnosisItem } },
    });
    if (page === 'primary') {
      const isPrimaryDiagnosis = diagnosis && diagnosis.isPrimaryDiagnosis === true;
      const newDiagnosisOnExistingCarePlan = careplanId !== 'create' && diagnosisId === 'create';

      if (isPrimaryDiagnosis) {
        updateCarePlanDiagnosis({ variables: { careplanId, diagnosisId: diagnosisItem.id } });
        return;
      }
      if (newDiagnosisOnExistingCarePlan) {
        updateCarePlanDiagnosis({
          variables: { careplanId, diagnosisId: diagnosisItem.id, relatedPrimaryDiagnosisId: '' },
        });
        return;
      }
      updateCarePlanDiagnosis({
        variables: { careplanId, diagnosisId: diagnosisItem.id, relatedPrimaryDiagnosisId: diagnosisItem.id },
      });
      setFieldValue('diagnosis', { ...values.diagnosis, relatedPrimaryDiagnosis: { id: diagnosisItem.id } });
      return;
    }
    if (page === 'metastasis') {
      const primaryDxId = diagnosisItem.relatedPrimaryDiagnosis ? diagnosisItem.relatedPrimaryDiagnosis.id : '';
      updateCarePlanDiagnosis({
        variables: { careplanId, diagnosisId: diagnosisItem.id, relatedPrimaryDiagnosisId: primaryDxId },
      });
      setFieldValue('diagnosis', { ...values.diagnosis, relatedPrimaryDiagnosis: { id: primaryDxId } });
      return;
    }
    return;
  };

  const updateDiagnosisOnClick = (
    diagnosis: DiagnosisType,
    diagnosisId: string,
    diagnosisItem: DiagnosisType,
    page: string,
    careplanId: string,
    dispatch: any,
    updateDiagnosis: any,
    updateCarePlanDiagnosis: any,
  ): void => {
    const attachRelatedPrimaryDiagnosis = (): void => {
      updateDiagnosis({ variables: { id: diagnosisId, relatedPrimaryDiagnosis: diagnosisItem.id } });
      setFieldValue('diagnosis', {
        ...values.diagnosis,
        relatedPrimaryDiagnosis: { id: diagnosisItem.id, diagnosisCode: diagnosisItem.diagnosisCode },
      });
      dispatch({
        type: DiagnosisActionType.SET_DIAGNOSIS,
        payload: {
          diagnosis: {
            ...diagnosis,
            relatedPrimaryDiagnosis: diagnosisItem,
          },
        },
      });
    };

    const attachDiagnosisToCurrentCareplan = (): void => {
      updateExistingCareplanDiagnosis(
        diagnosis,
        diagnosisId,
        diagnosisItem,
        page,
        careplanId,
        dispatch,
        updateDiagnosis,
        updateCarePlanDiagnosis,
      );
    };
    const attachDiagnosisToNewCarePlan = (): void => {
      dispatch({
        type: DiagnosisActionType.SET_DIAGNOSIS,
        payload: { diagnosis: { ...diagnosis, ...diagnosisItem } },
      });
    };
    /*
      1. Primary page
        a) via secondary flow
          --> Update related primary diagnosis ID
        b) Existing careplan
          --> update current careplan with what we have just selected
        c) New careplan
          --> Update context with new diagnosis

      2. Secondary secondary
        a) Existing careplan
          --> update current careplan with what we have just selected
        b) New careplan
          --> Update context with new diagnosis
    */
    if (page === 'primary') {
      const viaSecondary = diagnosis && diagnosis.isPrimaryDiagnosis === false;
      const existingCarePlan = careplanId !== 'create';
      const newCarePlan = careplanId === 'create';

      if (viaSecondary) {
        attachRelatedPrimaryDiagnosis();
        return;
      }
      if (existingCarePlan) {
        attachDiagnosisToCurrentCareplan();
        history.push(
          `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisItem.id}/${page}/`,
        );
        return;
      }
      if (newCarePlan) {
        attachDiagnosisToNewCarePlan();
        history.push(
          `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisItem.id}/${page}/`,
        );
        return;
      }
    }
    if (page === 'metastasis') {
      const existingCarePlan = careplanId !== 'create';
      const newCarePlan = careplanId === 'create';

      if (existingCarePlan) {
        attachDiagnosisToCurrentCareplan();
        history.push(
          `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisItem.id}/${page}/`,
        );
        return;
      }
      if (newCarePlan) {
        attachDiagnosisToNewCarePlan();
        history.push(
          `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisItem.id}/${page}/`,
        );
        return;
      }
    }
    return;
  };
  const renderDiagnosisResults = (
    state: DiagnosisState,
    dispatch: any,
    data: PatientDiagnosisQueryResultData,
    page: string,
    careplanId: string,
    diagnosisId: string,
    updateDiagnosis: any,
    updateCarePlanDiagnosis: any,
  ): JSX.Element | JSX.Element[] | undefined => {
    const { diagnosis } = state;
    return data.diagnosisList
      .filter((diagnosisItem: DiagnosisType) => diagnosisItem.diagnosisCode)
      .map((diagnosisItem: DiagnosisType): JSX.Element => {
        return (
          <ROPatientDiagnosisCurrentCode
            key={diagnosisItem.id}
            active={
              diagnosis.id === diagnosisItem.id ||
              (diagnosis.relatedPrimaryDiagnosis && diagnosis.relatedPrimaryDiagnosis.id === diagnosisItem.id)
            }
            diagnosisCodeSet={diagnosisItem}
            open={true}
            showTitle={true}
            onClick={(): void => {
              // Update current careplan with new diagnosis
              updateDiagnosisOnClick(
                diagnosis,
                diagnosisId,
                diagnosisItem,
                page,
                careplanId,
                dispatch,
                updateDiagnosis,
                updateCarePlanDiagnosis,
              );
            }}
          />
        );
      });
  };

  const renderDiagnosisContents = (
    state: DiagnosisState,
    dispatch: any,
    data: PatientDiagnosisQueryResultData,
    page: string,
    careplanId: string,
    diagnosisId: string,
    updateDiagnosis: any,
    updateCarePlanDiagnosis: any,
  ): JSX.Element | JSX.Element[] | undefined => {
    if (data && data.diagnosisList && data.diagnosisList.length > 0) {
      return renderDiagnosisResults(
        state,
        dispatch,
        data,
        page,
        careplanId,
        diagnosisId,
        updateDiagnosis,
        updateCarePlanDiagnosis,
      );
    } else {
      return renderNoDiagnosisResults();
    }
  };

  const GetPatientDiagnosisListWidget = (): JSX.Element => {
    const match = useRouteMatch<DiagnosisRouterParams>();
    const history = useHistory();
    const { id: patientId, diagnosisId, careplanId, page } = match.params;
    const { state, dispatch } = useContext(ROPatientDiagnosisContext);
    const { diagnosis } = state;

    const { setError } = useErrorModalContext();
    const [updateDiagnosis] = useMutation(UPDATE_DIAGNOSIS, {
      refetchQueries: [{ query: GET_DIAGNOSIS, variables: { diagnosisId } }],
      awaitRefetchQueries: true,
    });
    const [updateCarePlanDiagnosis] = useMutation(UPDATE_CAREPLAN_DIAGNOSIS, {
      refetchQueries: [{ query: GET_DIAGNOSIS, variables: { diagnosisId } }],
      awaitRefetchQueries: true,
      onCompleted: ({ updateCareplanDiagnosis }: any) => {
        history.push(
          `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${updateCareplanDiagnosis.diagnosis}/${page}`,
        );
      },
    });

    useEffect(() => {
      if (diagnosisListError) return setError();
    }, [diagnosisListError]);

    if (diagnosisListLoading && !diagnosisListData) return <LoadingSpinner loadingText="Loading Diagnosis Selection" />;

    return (
      <div
        className={classNames('diagnosis-selection-list', {
          primary: diagnosis.isPrimaryDiagnosis,
        })}>
        <div className="item-list-container">
          <div className="item-list">
            {renderDiagnosisContents(
              state,
              dispatch,
              diagnosisListData,
              page,
              careplanId,
              diagnosisId,
              updateDiagnosis,
              updateCarePlanDiagnosis,
            )}
          </div>
        </div>
      </div>
    );
  };
  const previousURL = (): string => {
    if (page === 'primary') {
      /*
        1. Secondary diagnosis selection code page (ICD)
        2. What are you treating page
      */
      const secondaryDiagnosis = data && !data.diagnosis.isPrimaryDiagnosis;
      if (secondaryDiagnosis) {
        return `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/metastasis/newMetastasis`;
      }
    }
    // Metastasis can only can ge to what are you treating page, the default action of primary is also this page
    return `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/`;
  };

  const [updateCarePlanDiagnosis] = useMutation(UPDATE_CAREPLAN_DIAGNOSIS, {
    onCompleted: ({ updateCareplanDiagnosis }: any): void => {
      history.push(
        `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${updateCareplanDiagnosis.diagnosis}/${page}/${newPage}`,
      );
    },
  });

  const attachDiagnosisToCurrentCareplan = (): void => {
    const isPrimaryDiagnosis = page === 'primary';

    setCreatingDiagnosis(true);
    createDiagnosis({
      variables: {
        patientId: patientId,
        diagnosisInput: { isPrimaryDiagnosis: isPrimaryDiagnosis },
      },
    })
      .then(
        (res: any): void => {
          const newDiagnosisId = res.data.createDiagnosis.diagnosis.id;
          dispatch({
            type: DiagnosisActionType.SET_DIAGNOSIS,
            payload: { diagnosis: { ...res.data.createDiagnosis.diagnosis } },
          });
          // we have an existing careplan with a new diagnosis ID that needs to be linked
          // Update the careplan as the owner of the newly created Dx
          updateCarePlanDiagnosis({
            variables: {
              careplanId,
              diagnosisId: newDiagnosisId,
              isPrimDiagnosisOwner: isPrimaryDiagnosis,
              isMetsDiagnosisOwner: !isPrimaryDiagnosis,
            },
          });
        },
        (error: any): void => {
          logger.info(error);
        },
      )
      .finally(() => setCreatingDiagnosis(false));
  };

  const createDiagnosisOnNewCareplan = (): void => {
    setCreatingDiagnosis(true);
    createDiagnosis({
      variables: {
        patientId: patientId,
        diagnosisInput: { isPrimaryDiagnosis: page === 'primary' },
      },
    })
      .then(
        (res: any): void => {
          const newDiagnosisId = res.data.createDiagnosis.diagnosis.id;
          dispatch({
            type: DiagnosisActionType.SET_DIAGNOSIS,
            payload: { diagnosis: { ...res.data.createDiagnosis.diagnosis } },
          });
          createCarePlan({ variables: { diagnosisId: newDiagnosisId, patientId: patientId } }).then(
            (result: any): void => {
              const careplanId = result.data.createCareplan.careplan.id;
              history.push(
                `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${newDiagnosisId}/${page}/${newPage}`,
              );
            },
          );
        },
        (error: any): void => {
          logger.info(error);
        },
      )
      .finally(() => setCreatingDiagnosis(false));
  };

  const createRelatedPrimaryDiagnosis = () => {
    setCreatingDiagnosis(true);
    createDiagnosis({
      variables: { patientId: patientId, diagnosisInput: { isPrimaryDiagnosis: true } },
    })
      .then(
        (res: any): void => {
          const newDiagnosisId = res.data.createDiagnosis.diagnosis.id;
          updateCarePlanDiagnosis({
            variables: { careplanId, diagnosisId, relatedPrimaryDiagnosisId: newDiagnosisId },
          });
          setFieldValue('diagnosis', { ...values.diagnosis, relatedPrimaryDiagnosis: { id: newDiagnosisId } });
          history.push(
            `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/${page}/${newPage}`,
          );
        },
        (error: any): void => {
          logger.info(error);
        },
      )
      .finally(() => setCreatingDiagnosis(false));
  };
  const createCareplanWrapper = (diagnosisId: string, patientId: string): void => {
    createCarePlan({ variables: { diagnosisId: diagnosisId, patientId: patientId } }).then(
      (result: any): void => {
        const careplanId = result.data.createCareplan.careplan.id;
        history.push(`/${oncologyType}/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.TREATMENT_INFO}`);
      },
      (error: any): void => {
        //Error creating careplan
        logger.error(error);
      },
    );
  };
  const nextURL = (): string => {
    if (page === 'primary') {
      /* 1. Diagnosis code selection page (ICD) - Handled by new button
         2. Staging page
         3. Treatment info page
            a) When we are in create mode, we will create a new diagnosis
            b) When we are part of a workflow, we will reuse what we have
      /*/
      const existingViaSecondary = data && data.diagnosis && !data.diagnosis.isPrimaryDiagnosis;
      const existingPrimaryNoCareplanID = state.diagnosis && state.diagnosis.id && careplanId === 'create';
      const existingPrimaryWithCareplanID = state.diagnosis && state.diagnosis.id && careplanId !== 'create'; // && diagnosisId !== 'create';
      const pendingPrimaryDiagnosis =
        (data &&
          data.diagnosis &&
          data.diagnosis.relatedPrimaryDiagnosis &&
          data.diagnosis.relatedPrimaryDiagnosis.draft) ||
        (data && data.diagnosis && data.diagnosis.isPrimaryDiagnosis && data.diagnosis.draft);
      if (pendingPrimaryDiagnosis) {
        return `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/primary/newPrimary`;
      }
      if (existingViaSecondary) {
        return `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/${CAREPLAN_PAGES.STAGING}`;
      }
      if (existingPrimaryNoCareplanID) {
        createCareplanWrapper(diagnosisId, patientId);
        return 'NOTHING';
      }
      if (existingPrimaryWithCareplanID) {
        return `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.TREATMENT_INFO}`;
      }
    }

    if (page === 'metastasis') {
      /* 1. Diagnosis code selection page (ICD) - handled by new button
         2. Treatment info page
      */

      const existingCarePlan = careplanId !== 'create';
      const newCarePlan = careplanId === 'create';
      const pendingSecondaryDiagnosis =
        data && data.diagnosis && !data.diagnosis.isPrimaryDiagnosis && data.diagnosis.draft;
      /*
        1) Has care plan ID
          --> Treatment info
        2) No careplan ID
          --> create careplan
          --> go to treatment info
      */
      if (pendingSecondaryDiagnosis) {
        return `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/metastasis/newMetastasis`;
      }
      if (newCarePlan) {
        createCareplanWrapper(diagnosisId, patientId);
        return 'NOTHING';
      }
      if (existingCarePlan) {
        return `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.TREATMENT_INFO}`;
      }
    }
    logger.error('nextURL', [
      'Diagnosis Selection Page: Unable to determine the next URL this user should be directed to.',
      data,
    ]);
    return '';
  };

  /**
   * Determines if the current page represents an unchosen primary diagnosis via a secondary diagnosis.
   *
   * @constant {boolean}
   * @description
   * `unchosenPrimaryViaSecondary` is a boolean constant that checks if the current page is set to 'primary',
   * and if the provided data includes a 'diagnosis' object, where 'isPrimaryDiagnosis' is explicitly set to
   * `false`, and there is no related primary diagnosis.
   *
   * This variable is then used to help determine if we can continue on to the next screen.
   *
   * @param {string} page - The current page identifier (e.g., 'primary' or 'secondary').
   * @param {object} data - The data object containing diagnosis information.
   * @param {boolean} data.diagnosis.isPrimaryDiagnosis - Indicates whether the diagnosis is primary (true) or not (false).
   * @param {object|null} data.diagnosis.relatedPrimaryDiagnosis - The related primary diagnosis, if any.
   *
   * @returns {boolean} Returns `true` if the current page represents an unchosen primary diagnosis via a secondary diagnosis;
   * otherwise, returns `false`.
   */
  const unchosenPrimaryViaSecondary =
    page === 'primary' &&
    data &&
    data.diagnosis &&
    data.diagnosis.isPrimaryDiagnosis === false &&
    !data.diagnosis.relatedPrimaryDiagnosis;

  /**
   * Determines whether a selected diagnosis is present in the data.
   *
   * @constant {boolean}
   * @description
   * `noDiagnosisCode` is a boolean constant that evaluates whether a selected diagnosis
   * is available in the provided data. It checks if the data object exists, if it contains
   * a 'diagnosis' property, and if the 'diagnosisCode' within the 'diagnosis' property is not null.
   *
   * This variable is then used to help determine if we can continue on to the next screen.
   *
   * @returns {boolean} Returns `true` if a selected diagnosis is not present; otherwise, `false`.
   */
  const noDiagnosisCode = data && data.diagnosis && data.diagnosis?.diagnosisCode === null;

  return (
    <Fragment>
      <div className="main-container-wrapper">
        <CareplanContainer>
          <div className="page-header">
            <Typography paddingBottom={1} variant="h5">
              {heading}
            </Typography>
            <Typography color={theme.palette.text.secondary} variant="body1">
              {subtitle}
            </Typography>
          </div>
          <div style={{ marginBottom: '16px' }} data-test-id="new-dx-button" className="title-button-row">
            <Button
              variant="outlined"
              data-testid="new-diagnosis-button"
              disabled={creatingDiagnosis}
              startIcon={<AddCircleOutline color="primary" />}
              onClick={(): void => {
                dispatch({
                  type:
                    page === 'primary' ? DiagnosisActionType.SET_NEW_PRIMARY : DiagnosisActionType.SET_NEW_METASTASIS,
                  payload: {},
                });

                if (page === 'primary') {
                  const newDiagnosisOnNewCarePlan = careplanId === 'create' && diagnosisId === 'create';
                  const newDiagnosisViaSecondary =
                    diagnosisId !== 'create' && state.diagnosis && !state.diagnosis.isPrimaryDiagnosis;
                  const newDiagnosisOnExistingCarePlan = careplanId !== 'create' && diagnosisId === 'create';
                  const replaceExistingDiagnosisOnExistingCarePlan =
                    diagnosisId !== 'create' && careplanId !== 'create';
                  const replaceExistingDiagnosisOnNewCarePlan = diagnosisId !== 'create' && careplanId === 'create';

                  if (newDiagnosisViaSecondary) {
                    createRelatedPrimaryDiagnosis();
                    return;
                  }
                  if (newDiagnosisOnNewCarePlan || replaceExistingDiagnosisOnNewCarePlan) {
                    createDiagnosisOnNewCareplan();
                    return;
                  }
                  if (newDiagnosisOnExistingCarePlan || replaceExistingDiagnosisOnExistingCarePlan) {
                    attachDiagnosisToCurrentCareplan();
                    return;
                  }
                }

                if (page === 'metastasis') {
                  const newDiagnosisAndNewCarePlan = careplanId === 'create';
                  if (newDiagnosisAndNewCarePlan) {
                    createDiagnosisOnNewCareplan();
                    return;
                  } else {
                    attachDiagnosisToCurrentCareplan();
                    return;
                  }
                }
                logger.error('Select Diagnosis', [
                  'Diagnosis Selection Page: Unhandled scenario for joining diagnosis.',
                  state,
                ]);
                return;
              }}
              style={{
                border: `2px solid ${theme.palette.primary.main}`,
                borderRadius: '20px',
                borderWidth: '2px',
                fontWeight: 'bold',
              }}
              sx={{
                backgroundColor: theme.palette.primary.contrastText,
              }}>
              {btnTitle}
            </Button>
          </div>
          <Divider sx={{ marginBottom: '16px' }}>OR</Divider>
          <div style={{ marginBottom: '16px' }}>
            <Typography
              variant="h6"
              style={{
                lineHeight: '32px',
              }}>
              {secondaryHeading}
            </Typography>
          </div>
          <div className="create-diagnosis-list">
            <GetPatientDiagnosisListWidget />
          </div>
        </CareplanContainer>
        <ROPatientCarePlanPageFooter
          onBack={(): void => {
            history.push(previousURL());
          }}
          onNext={(): void => {
            const n = nextURL();
            if (n === 'NOTHING') return;
            if (n === '') {
              return;
            }
            history.push(n);
          }}
          nextDisabled={diagnosisId === 'create' || unchosenPrimaryViaSecondary || noDiagnosisCode}
          backDisabled={creatingDiagnosis}
        />
      </div>
    </Fragment>
  );
};

export default ROPatientDiagnosisSelectionPage;
