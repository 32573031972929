import React, { useState } from 'react';
import {
  Table,
  Stack,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  useTheme,
  Checkbox,
} from '@mui/material';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { SearchMultiSelectField } from 'op-components';
import { Careplan } from 'op-pages/RO/Careplan/Interface';
import { getCareplanName, getFormattedDateTime } from './helper';
import { redirectCareplanPath } from 'op-pages/RO/Careplan/Common';
import { useHistory } from 'react-router-dom';
import { patientGenderStockImage } from 'op-utils/PatientHelper';
import { isDemo } from 'op-utils';

import {
  PatientName,
  Status,
  Priority,
  EnhancedTableHead,
  TableData,
  getComparator,
  CareplanName,
  SearchInput,
} from './CareplanTableComponents';
import { GET_CAREPLANS_QUERY } from './graphql/queries';
import { useErrorModalContext } from 'op-contexts';
import { useQuery } from '@apollo/client';
import { CareplanFilter } from 'op-enums';

interface Props {
  careplanFilter: string[];
  statusFilter: string[];
  setCareplanFilter: (val: string[]) => void;
  setStatusFilter: (val: string[]) => void;
}

type Order = 'asc' | 'desc';

interface ConfigFilterOption {
  checked: boolean;
  id: string;
  name: string;
}

interface OptionType {
  label: string;
  value: string;
}

const DEFAULT_ROWS_PER_PAGE = 10;
const ROWS_PER_PAGE_OPTIOSN = [DEFAULT_ROWS_PER_PAGE, 20];

const CareplanTable = ({ statusFilter, careplanFilter, setCareplanFilter, setStatusFilter }: Props) => {
  const { setError } = useErrorModalContext();

  const [searchString, setSearchString] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>('lastModified');

  const theme = useTheme();
  const history = useHistory();

  const variables = {
    page: currentPage + 1, // Django paginator is 1-indexed
    rowsPerPage: rowsPerPage,
    search: searchString,
    status: statusFilter,
    careplanChanged: careplanFilter,
    onlyForRequestingPractitioner: true,
  };
  const { data, error } = useQuery(GET_CAREPLANS_QUERY, {
    variables: variables,
    fetchPolicy: 'network-only',
  });
  const careplans = data?.careplans;
  const careplanCount = data?.careplansCount || 0;
  const rows =
    careplans?.map((careplan: Careplan) => {
      return {
        id: careplan.id,
        patientName: careplan.patient.fullName,
        department: careplan.treatingDepartment || '-',
        careplanName: getCareplanName(careplan) || 'Diagnosis not selected',
        status: careplan.careplanStatus,
        priority: careplan.priority,
        lastModified: getFormattedDateTime(careplan.updatedAt),
        patientId: careplan.patient.id,
        ida: careplan.patient.ida,
        photoUrl: careplan.patient.photoUrl,
        patientGender: careplan.patient.patientGender,
      };
    }) || [];

  const visibleRows = React.useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, careplans]);

  const config = CurrentAppConfig.RadiationDashboard.careplanDashboard;

  const statusFilterOptions = JSON.parse(JSON.stringify(config.careplanStatusFilters)).map(
    (option: ConfigFilterOption): OptionType => {
      return {
        label: option.name,
        value: option.name,
      };
    },
  );

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, DEFAULT_ROWS_PER_PAGE));
    setCurrentPage(0);
  };

  const handleChangePage = (_: any, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openCareplan = (careplanId: string) => {
    const careplan = careplans.find((careplan: Careplan) => careplan.id === careplanId);
    if (careplan) {
      history.push(redirectCareplanPath(careplan, careplan.patient.id));
    }
  };

  const toggleCareplanFilter = () => {
    setCareplanFilter(careplanFilter.includes(CareplanFilter.UNSUBMITTED) ? [] : [CareplanFilter.UNSUBMITTED]);
  };

  if (error) return setError();

  return (
    <Stack padding={2} width={1}>
      <Paper>
        <Stack padding={2}>
          <Typography data-testid="table-title" variant="h5" paddingBottom={2}>
            Careplan Dashboard
          </Typography>
          <Stack direction="row" alignItems="center" gap={2} height={'70px'}>
            <SearchInput
              inputValue={searchString || ''}
              id="patient-gc-search"
              placeholder="Search patient name or patient ID…"
              onSubmitSearch={(val: string) => setSearchString(val)}
            />
            <SearchMultiSelectField
              selectAllEnabled={true}
              selectAllLabel="Select all"
              selectedOptionsUpdated={setStatusFilter}
              resetSearchResults={() => {}}
              defaultSelectedOptions={statusFilter}
              options={statusFilterOptions}
              placeholder="Status filter"
              allSelected={statusFilter?.length === statusFilterOptions?.length}
              allSelectedLabel="All filters"
            />
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Checkbox
                color="primary"
                checked={careplanFilter.includes(CareplanFilter.UNSUBMITTED)}
                onChange={toggleCareplanFilter}
              />
              <Typography sx={{ cursor: 'pointer' }} onClick={toggleCareplanFilter} variant="body1">
                Only show unsubmitted changes
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack maxHeight={'calc(100vh - 293px)'} minHeight={'calc(100vh - 293px)'} overflow="auto" padding={2}>
          {careplanCount && careplans ? (
            <TableContainer>
              <Table>
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {visibleRows.map((row, index) => (
                    <TableRow
                      data-testid={`careplan-table-row-${index}`}
                      key={row.id}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => openCareplan(row.id)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: theme.palette.action.hover,
                        },
                      }}>
                      <TableCell>
                        <PatientName
                          {...row}
                          photoUrl={isDemo ? patientGenderStockImage(row.patientGender) : row.photoUrl}
                        />
                      </TableCell>
                      <TableCell>{row.department}</TableCell>
                      <TableCell>
                        <CareplanName
                          careplan={careplans.find((careplan: Careplan) => careplan.id === row.id)}
                          careplanName={row.careplanName}></CareplanName>
                      </TableCell>
                      <TableCell>
                        <Status careplanStatus={row.status} />
                      </TableCell>
                      <TableCell>
                        <Priority priority={row.priority} />
                      </TableCell>
                      <TableCell>{row.lastModified}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography padding={2} variant="body1" color={theme.palette.text.secondary}>
              No careplans available
            </Typography>
          )}
        </Stack>
        <TablePagination
          sx={{
            '.MuiToolbar-root': {
              'align-items': 'baseline',
            },
            '.MuiTablePagination-displayedRows': {
              'margin-top': '1em',
              'margin-bottom': '1em',
            },
          }}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIOSN}
          count={careplanCount}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Stack>
  );
};

export default CareplanTable;
