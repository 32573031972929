import React, { Fragment } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { GCButton } from 'shared-components/components/FormFields';
import { GCButtonSize } from 'shared-components/enums';
import { GCButtonTextSize } from 'shared-components/components/FormFields/GCButton/GCButton';
import { ButtonType } from 'shared-components/enums';
import { AddOutlineCircle } from 'shared-components/images';
import { StyledArrow, StyledTitle } from './common';
import { Stack, Typography, useTheme, SvgIconTypeMap, styled } from '@mui/material';
import { BallotOutlined as BallotOutlinedIcon } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface Props {
  patientId: any;
  createManagementPlan?: any;
  createReferralExistingService?: any;
  disableCreateButton?: boolean;
  showButtons?: boolean;
}

interface PatientNavOptionType {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  label: string;
  linkTo: string;
  id: string;
}

interface PatientNavLinks {
  option: PatientNavOptionType;
  key: string;
}

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px 16px;
  text-decoration: none;
  height: 63px;
  transition: border-color 0.15s ease-in-out;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const StyledCreateButton = styled('div')`
  margin-bottom: 15px;
  margin-right: 5px;

  .disabled-button {
    color: ${(props) => props.theme.palette.grey[600]} !important;
  }
`;

const NurseSidePatientNav = ({
  patientId,
  createManagementPlan,
  createReferralExistingService,
  disableCreateButton,
  showButtons = false,
}: Props): JSX.Element => {
  const theme = useTheme();
  const BackToDashboard = (): JSX.Element => {
    const url = generatePath('/search');
    return (
      <StyledLink to={url} data-test-id="back-to-search-button">
        <StyledArrow />
        <StyledTitle>Back to dashboard</StyledTitle>
      </StyledLink>
    );
  };

  const BackManagementPlan = (): JSX.Element => {
    const url = generatePath('/patient/:patientId/management', { patientId });
    return (
      <StyledLink to={url} data-test-id="back-to-management-button">
        <StyledArrow />
        <StyledTitle>Management plans</StyledTitle>
      </StyledLink>
    );
  };

  const PatientNavLinks = (props: PatientNavLinks): JSX.Element => {
    const { linkTo, label, id, Icon } = props.option;
    const location = useLocation();
    const splitPath = location.pathname.split('/');
    const isActive = splitPath[splitPath.length - 1] === id;
    const url = generatePath(linkTo, { patientId });
    const active = isActive ? 'active' : '';
    const color = isActive ? theme.palette.primary.contrastText : theme.palette.text.primary;

    // TODO: turn these back into styled components
    return (
      <div
        className={`ro-side-patient-nav-item-row ${active}`}
        style={{
          backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
        }}>
        <Link to={url} className="ro-side-patient-nav-item" style={{ gap: '8px' }}>
          <Icon htmlColor={color} />
          <Typography data-testid="side-nav-title" variant="subtitle1" color={color}>
            {label}
          </Typography>
        </Link>
      </div>
    );
  };

  return (
    <Fragment>
      {showButtons ? <BackToDashboard /> : <BackManagementPlan />}

      <Stack>
        {showButtons && (
          <Stack sx={{ alignItems: 'center' }}>
            <Stack className="ro-side-patient-nav pso" marginBottom={2}>
              <PatientNavLinks
                option={{
                  label: 'Management Plan',
                  linkTo: '/patient/:patientId/management',
                  id: 'management',
                  Icon: BallotOutlinedIcon,
                }}
                key="management"
              />
            </Stack>
            <StyledCreateButton>
              <GCButton
                id={'mx-create-plan-button'}
                title={'Create plan'}
                disabled={disableCreateButton}
                type={ButtonType.WHITE}
                buttonSize={GCButtonSize.MEDIUM}
                textSize={GCButtonTextSize.SMALLER}
                lhsIcon={<AddOutlineCircle />}
                onClick={(): void => createManagementPlan()}
              />
            </StyledCreateButton>
            <StyledCreateButton>
              <GCButton
                id={'mx-add-referral-button'}
                title={'Add referral'}
                type={ButtonType.WHITE}
                buttonSize={GCButtonSize.MEDIUM}
                textSize={GCButtonTextSize.SMALLER}
                lhsIcon={<AddOutlineCircle />}
                onClick={(): void => createReferralExistingService()}
              />
            </StyledCreateButton>
          </Stack>
        )}
      </Stack>
    </Fragment>
  );
};

export default NurseSidePatientNav;
