import { useState } from 'react';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';

interface PropsType {
  open: boolean;
  setOpen: (value: boolean) => void;
  tooltip?: TooltipInterface;
  modalWidth?: 'small' | 'medium' | 'large';
}

export function TooltipModal(props: PropsType): JSX.Element {
  const { open, setOpen, tooltip, modalWidth = 'small' } = props;

  return (
    <Modal width={modalWidth} open={open} onClose={() => setOpen(false)}>
      <ModalHeader>{tooltip?.header}</ModalHeader>
      <ModalBody>
        {/* HTML from Intake helpers file */}
        <span dangerouslySetInnerHTML={{ __html: tooltip?.body || '' }}></span>
      </ModalBody>
      <ModalFooter>
        <Button mode="contained" size="small" onClick={() => setOpen(false)} data-cy={'form-builder-tooltip-modal'}>
          Back
        </Button>
      </ModalFooter>
    </Modal>
  );
}

interface TooltipInterface {
  header: string;
  body: string;
}

interface ToolTipProps {
  tooltip?: TooltipInterface;
}

export function FormRowToolTip(props: ToolTipProps): JSX.Element {
  const { tooltip } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <HelpOutlineIcon className="tooltip-icon" onClick={() => setOpen(true)} />
      {open && <TooltipModal open={open} tooltip={tooltip} setOpen={setOpen} />}
    </>
  );
}
