import moment from 'moment';
import { Region } from 'shared-components/enums';
import { ListData, SelectOptions } from 'shared-components/interfaces';
import { DistressThermometerVersion } from 'op-enums';
import { PatientNote } from 'op-components/RO/Notes/Notes';
import { useContext } from 'react';
import { TenantContext } from 'op-contexts/index';
import { CGY_TO_GY, GY_TO_CGY, DoseUnit } from 'op-enums';

export const mapListData = (listData: ListData[]): SelectOptions[] =>
  listData?.map((data: ListData) => {
    return { label: data.name, value: data.name };
  }) || [];

export const sortRefDataById = (a: ListData, b: ListData) => {
  /**
   * This is a comparison function to be used with the sort() function for a ListData
   */
  let returnVal = 0;
  if (a.id > b.id) {
    returnVal = 1;
  } else if (a.id < b.id) {
    returnVal = -1;
  }
  return returnVal;
};

export const snakeToCamel = (str: string): string => {
  return str.replace(/([-_][a-z])/g, (group: string): string => {
    return group.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const isEmpty = (str: string): boolean => {
  return !str || 0 === str.length;
};

export const isBlank = (str: string): boolean => {
  return !str || /^\s*$/.test(str);
};

export const groupBy = (items: any, key: any) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const wrapURLs = (text: string, newWindow: boolean): string => {
  const regex = /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi;

  return (text || '').replace(regex, (match, space, url) => {
    let hyperlink = url;

    if (!hyperlink.match('^https?://')) {
      hyperlink = 'http://' + hyperlink;
    }

    return space + `<a href="${hyperlink}" ${newWindow ? 'target="_blank"' : ''}>${url}</a>`;
  });
};

export const generateGreetingText = (): string => {
  const currHour = parseInt(moment().format('H'));
  let greeting = 'Good evening,';

  if (currHour >= 4 && currHour <= 11) {
    greeting = 'Good morning,';
  } else if (currHour > 11 && currHour <= 16) {
    greeting = 'Good afternoon,';
  }

  return greeting;
};

export const isUs = (): boolean => import.meta.env.REACT_APP_REGION === Region.US;
// Used to configure the app to use the demo features
export const isDemo: boolean = import.meta.env.REACT_APP_REGION === Region.DEMO;

export const appendZeroInFront = (valueToPad: string): string =>
  valueToPad?.length === 1 ? `0${valueToPad}` : valueToPad;

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const TO_FORM_DISTRESS_EOT = 'Distress Thermometer EOT';

export const toFormToVersion = (toForm: string): string => {
  if (toForm === TO_FORM_DISTRESS_EOT) return DistressThermometerVersion.EOT;
  return DistressThermometerVersion.INITIAL;
};

/**
 * Generates a formatted creation date (Prioritizes approvedAt/editedAt dates over createdAt) string for a patient note.
 *
 * @param {PatientNote} note - Instance of a note
 * @param {string} timezone - Practitioner's timezone for formatting the timestamp.
 * @param {string} format - Date formatting
 * @returns {string} Formatted creation date(approvedAt > editedAt > createdAt)
 */
export const formatNoteCreationDate = (note: PatientNote, timezone: string, format: string): string => {
  const noteDateTime = moment(note.approvedAt).isValid()
    ? moment(note.approvedAt)
    : moment(note.editedAt).isValid()
    ? moment(note.editedAt)
    : moment(note.createdAt);
  return moment(noteDateTime).tz(timezone).format(format).toString();
};

const roundToTwoDp = (num: number) => {
  return Math.round(num * 100 + Number.EPSILON) / 100;
};

const roundToInteger = (num: number) => {
  return Math.round(num);
};

interface TenantContextType {
  doseUnit: string;
  ariaDoseUnit: string;
  mosaiqDoseUnit: string;
}

export const doseRounding = (dose: number, isAria: boolean, tenantContenxt: TenantContextType): number => {
  const { doseUnit, ariaDoseUnit, mosaiqDoseUnit } = tenantContenxt;
  const emrUnit = isAria ? ariaDoseUnit : mosaiqDoseUnit;
  const roundMethod = doseUnit === DoseUnit.CGY ? roundToInteger : roundToTwoDp;

  if (doseUnit === emrUnit) return roundMethod(dose);
  if (doseUnit === DoseUnit.CGY && emrUnit === DoseUnit.GY) return roundMethod(dose * GY_TO_CGY);
  if (doseUnit === DoseUnit.GY && emrUnit === DoseUnit.CGY) return roundMethod(dose * CGY_TO_GY);
  return dose;
};

export const display = (field: string | null): string => (field ? field : '-');

export const sortByNewestToOldest = (input: any): any => {
  // Depends on sortDate
  return [...input].sort((a: any, b: any) =>
    moment(b.sortDate, 'DD/MM/YY').diff(moment(a.sortDate, 'DD/MM/YY'), 'days'),
  );
};

export const computeGroupTotal = (doses: Array<any>): any => {
  const tenantContext = useContext(TenantContext);

  // All cards should have the same TotalDoseLimit. We will just take the first one
  const totalDoseLimit = doseRounding(doses[0].totalDosageLimit, true, tenantContext);

  let totalDeliveredDose = 0.0;
  let totalDoseMax = 0.0;
  doses.forEach((dose: any) => {
    totalDeliveredDose += dose.deliveredDosageQuantity;
    totalDoseMax += dose.plannedDosageQuantity;
  });
  totalDeliveredDose = doseRounding(totalDeliveredDose, true, tenantContext);
  totalDoseMax = doseRounding(totalDoseMax, true, tenantContext);

  return {
    totalDeliveredDose: totalDeliveredDose === totalDoseMax ? totalDoseMax : totalDoseLimit,
    totalDoseMax: totalDoseMax,
  };
};
