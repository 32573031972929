// eslint-disable-next-line no-use-before-define
import React from 'react';
import classNames from 'classnames';

import './GCButton.scss';

import { ButtonType, Region } from 'shared-components/enums';
import { CircularProgress, useTheme } from '@mui/material';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;
interface Props {
  title: string;
  type: ButtonType;
  name?: string;
  disabled?: boolean;
  loading?: boolean;
  extraClass?: string;
  inputType?: 'submit' | 'button' | 'reset';
  onClick?: (event: any) => void;
}
/**
 * GC styled button component.
 */
const GCButton = (props: Props) => {
  const {
    onClick,
    name,
    inputType = 'button',
    loading = false,
    type = ButtonType.WHITE,
    disabled = false,
    extraClass,
    title,
  } = props;

  const theme = useTheme();

  const spinner = (
    <CircularProgress
      color="primary"
      sx={{
        maxWidth: '30px',
        maxHeight: '30px',
        marginTop: '-5px',
      }}
    />
  );
  const contents = loading ? spinner : title;

  return (
    <button
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      disabled={disabled}
      name={name}
      style={{
        backgroundColor: type === ButtonType.GREEN ? theme.palette.primary.main : 'default',
        color: type === ButtonType.GREEN ? theme.palette.primary.contrastText : 'default',
        cursor: 'pointer',
      }}
      className={classNames('default-button', extraClass, {
        'white-button': type === ButtonType.WHITE,
        'green-button': type === ButtonType.GREEN,
        'warning-button': type === ButtonType.WARNING,
        'disabled-button': type === ButtonType.DISABLED,
        'UK-gc-button-style': region === Region.UK,
      })}
      type={inputType}>
      {contents}
    </button>
  );
};

export default GCButton;
