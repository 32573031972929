import { useMutation, useQuery } from '@apollo/client';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'gc-ui';
import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { GET_LOCATION_PROFILE, UPDATE_CENTER_MUTATION } from 'op-components/PatientCard/PatientCardQueries';
import { FormType } from 'op-enums';
import { RELEASE_LOCK_MUTATION } from 'op-graphql';
import { LocationListData } from 'op-interfaces/Locations';
import { PatientSearchQueryItem } from 'op-interfaces/PatientInterfaces';
import React, { useMemo, useState } from 'react';
import { FormRow } from 'shared-components/components';
import { SelectField, TextAreaField, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { SelectOptions } from 'shared-components/interfaces';

import { ExtendLock } from 'op-components/index';
import { CREATE_SUBMISSION } from 'op-components/OP/PatientSearch/PatientSearchQueries';
import PatientCardModalHeader from 'op-components/PatientCard/PatientCardModalHeader';
import { UPDATE_PATIENT_FORM_VISIBILITY } from 'op-graphql/queries';
import { EMAIL_DUPLICATE_VALIDATION } from 'op-pages/Shared/constants';
import { isUs } from 'op-utils/helpers';
import { Logger } from 'shared-components/utils';
import { styled } from '@mui/system';
import './PatientAccessModal.scss';
import { GET_PATIENT_PIN, SEND_HOME_REGO_INVITATION, UPDATE_REGISTRATION_TYPE } from './PatientAccessModalQueries';
import { generateValidationSchema } from './validation';
import { Typography, Stack } from '@mui/material';

const logger = new Logger('PatientAccessModal');

const StyledPinSpan = styled((props: any) => <span {...props} />)`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: ${(props) => props.theme.palette.text.primary};
`;

export enum AccessType {
  IN_CLINIC = 'inClinic',
  AT_HOME = 'atHome',
}

enum FormPacks {
  REGO = 'rego',
  HA = 'ha',
  REGO_HA = 'rego_ha',
  REGO_HA_DT = 'rego_ha_dt',
  DT_EOT = 'dt_eot',
}

interface IPatientAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: PatientSearchQueryItem;
}

interface IPatientAccessModalFormikValues extends PatientSearchQueryItem {
  selectedFormPack: string;
}

const PatientAccessModal = (props: IPatientAccessModalProps): JSX.Element => {
  const { isOpen = false, onClose, patient } = props;

  const componentClass = 'patient-access-modal';
  const patientId = patient.id;

  const initialValues: IPatientAccessModalFormikValues = {
    selectedFormPack: '',
    ...patient,
  };

  const [isGetPinBtnEnabled, setIsGetPinBtnEnabled] = useState<boolean>(true);
  const dtEnabled = useMemo<boolean>(
    () => patient?.userProfile?.patientFeatures?.distressThermometerEnabled,
    [patient],
  );
  const dtEotEnabled = useMemo<boolean>(
    () => patient?.userProfile?.patientFeatures?.distressThermometerEotEnabled,
    [patient],
  );

  const generateFormPackOptions = (accessType: AccessType): SelectOptions[] => {
    if (isUs()) {
      return [
        { label: 'Registration Form Only', value: FormPacks.REGO },
        { label: 'Health Assessment Form Only', value: FormPacks.HA },
        { label: 'Registration Forms Pack', value: FormPacks.REGO_HA },
      ];
    } else {
      const formPackOptions: SelectOptions[] = [
        { label: 'Registration Forms Pack', value: FormPacks.REGO_HA },
        { label: 'Registration Forms Pack + Distress Thermometer', value: FormPacks.REGO_HA_DT },
        { label: 'Distress Thermometer EOT', value: FormPacks.DT_EOT },
      ]
        .filter((item: SelectOptions) => {
          if (!dtEnabled) return item.value !== FormPacks.REGO_HA_DT;
          return item;
        })
        .filter((item: SelectOptions) => {
          if (!dtEotEnabled) return item.value !== FormPacks.DT_EOT;
          return item;
        });
      if (accessType === AccessType.AT_HOME) return formPackOptions.filter((item) => item.value !== FormPacks.DT_EOT);

      return formPackOptions;
    }
  };

  const inClinicFormPackOptions = generateFormPackOptions(AccessType.IN_CLINIC);
  const atHomeFormPackOptions = generateFormPackOptions(AccessType.AT_HOME);

  const [getPatientPin, { data: getPatientPinData, loading: getPatientPinLoading }] = useMutation(GET_PATIENT_PIN);
  const [saveHorizonCenterId] = useMutation(UPDATE_CENTER_MUTATION);
  const [createSubmission] = useMutation(CREATE_SUBMISSION);
  const [releaseLock] = useMutation(RELEASE_LOCK_MUTATION);
  const [updatePatientFormVisibility] = useMutation(UPDATE_PATIENT_FORM_VISIBILITY);
  const [updateRegistrationType] = useMutation(UPDATE_REGISTRATION_TYPE);
  const [sendHomeRegoInvitation] = useMutation(SEND_HOME_REGO_INVITATION);

  const { data: psoLocationData } = useQuery(GET_LOCATION_PROFILE, { variables: { patientId } });

  const primaryDepartmentOptions: SelectOptions[] = useMemo(() => {
    if (psoLocationData) {
      return (
        psoLocationData?.locationsPsoSystem?.map((location: LocationListData) => ({
          label: location.alias,
          value: location.id,
        })) ?? []
      );
    }
    return [];
  }, [psoLocationData]);

  /**
   * Handle formik form submission
   * @param values Formik Submission Values
   * @param helpers Formik Helpers
   */
  const handleFormikSubmit = (
    values: IPatientAccessModalFormikValues,
    helpers: FormikHelpers<IPatientAccessModalFormikValues>,
  ) => {
    const toForm = values.selectedFormPack === FormPacks.DT_EOT ? FormType.DISTRESS_EOT : FormType.REGISTRATION;
    const accessType = values.userProfile.registrationAccessType;

    const postSubmission = () => {
      /** Set profile registration access type */
      updateRegistrationType({
        variables: {
          patientId,
          registrationAccessType: accessType,
        },
      });

      /** Setting patient visilibity of forms */
      const defaultPatientFormVisibilityVariables = {
        patientId,
        showDistressThermometer: false,
        showRegistration: false,
        showHealthAssessment: false,
      };

      switch (values.selectedFormPack) {
        case FormPacks.REGO:
          updatePatientFormVisibility({
            variables: {
              ...defaultPatientFormVisibilityVariables,
              showRegistration: true,
            },
          });
          break;
        case FormPacks.HA:
          updatePatientFormVisibility({
            variables: {
              ...defaultPatientFormVisibilityVariables,
              showHealthAssessment: true,
            },
          });
          break;
        case FormPacks.REGO_HA:
          updatePatientFormVisibility({
            variables: {
              ...defaultPatientFormVisibilityVariables,
              showRegistration: true,
              showHealthAssessment: true,
            },
          });
          break;
        case FormPacks.REGO_HA_DT:
          updatePatientFormVisibility({
            variables: {
              ...defaultPatientFormVisibilityVariables,
              showDistressThermometer: true,
              showRegistration: true,
              showHealthAssessment: true,
            },
          });
          break;
        // case FormPacks.DT_EOT: // NOTE: DT EOT visibility is generated in a different way
        default:
          updatePatientFormVisibility({
            variables: defaultPatientFormVisibilityVariables,
          });
          break;
      }
    };

    if (accessType === AccessType.IN_CLINIC) {
      setIsGetPinBtnEnabled(false);
      getPatientPin({
        variables: {
          patientId,
          toForm,
        },
      })
        .then(() => {
          postSubmission();
          releaseLock({ variables: { accessPatientId: patientId } });
        })
        .finally(() => {
          setTimeout(() => {
            setIsGetPinBtnEnabled(true);
          }, 3000);
        });
    } else if (accessType === AccessType.AT_HOME) {
      sendHomeRegoInvitation({
        variables: {
          patientId,
          toForm,
          recipientEmail: values.email,
          recipientPhone: values.primaryPhone,
        },
      })
        .then((results: any) => {
          /** While dev-ugging, view home reg invite in the console */
          logger.debug('sendHomeRegistrationEmail invite link', results.data.sendHomeRegistrationInvitation.inviteLink);

          /** This is to generate FHIR outbounds for primaryPhone and email */
          createSubmission({
            variables: {
              patientID: patientId,
              includePdf: false,
              updateFormStatus: false,
              fromHomeRego: true,
            },
          });
        })
        .then(() => {
          postSubmission();
          handleCloseModal();
        })
        .catch((err) => {
          if (err.toString().includes('More than one email found')) {
            helpers.setFieldError('email', EMAIL_DUPLICATE_VALIDATION);
            helpers.setSubmitting(false);
          }
        });
    }
  };

  const handleCloseModal = () => {
    releaseLock({ variables: { accessPatientId: patientId } });
    onClose();
  };

  return (
    <Modal
      maxWidth="sm"
      id={`${componentClass}-${patientId}`}
      open={isOpen}
      centered
      onClose={handleCloseModal}
      PaperProps={{ style: { padding: '16px', minWidth: '700px' } }}
      className={`${componentClass}`}>
      <Stack direction="column" gap="8px" lineHeight="1.4rem">
        <Typography variant="subtitle1" fontWeight={600} className="modal-title">
          Patient Form Access
        </Typography>
        <PatientCardModalHeader patientId={patientId} />
      </Stack>
      <Formik
        initialValues={initialValues}
        validate={(values: FormikValues) => generateValidationSchema(values, primaryDepartmentOptions)}
        validateOnChange={false}
        validateOnBlur
        onSubmit={handleFormikSubmit}>
        {({ submitForm, handleSubmit, submitCount, values, setFieldValue, resetForm }: FormikProps<any>) => {
          /** Set defaults */
          const selectedAccessType = values.userProfile.registrationAccessType || AccessType.AT_HOME;
          if (!values.userProfile.registrationAccessType)
            setFieldValue('userProfile.registrationAccessType', AccessType.AT_HOME);

          const primaryButtonText = (): string => {
            if (selectedAccessType === AccessType.IN_CLINIC) return 'Get PIN';
            else if (selectedAccessType === AccessType.AT_HOME) return 'Send Link';
            return 'Continue';
          };

          const handleOnAccessTypeChange = (accessTypeValue: any) => {
            resetForm({
              values: {
                ...values,
                userProfile: {
                  ...values.userProfile,
                  registrationAccessType: accessTypeValue,
                },
              },
            });
          };

          return (
            <Form onSubmit={handleSubmit}>
              {patientId && selectedAccessType === AccessType.AT_HOME && <ExtendLock accessPatientId={patientId} />}
              <ModalBody>
                <FormRow
                  fieldLabel={'Access type'}
                  fieldName={'userProfile.registrationAccessType'}
                  labelClass={'label-form-row'}>
                  <Field
                    name="userProfile.registrationAccessType"
                    component={ToggleButtonGroupField}
                    conditionalHandleChange={handleOnAccessTypeChange}
                    options={[
                      { value: AccessType.IN_CLINIC, label: 'In Clinic' },
                      { value: AccessType.AT_HOME, label: 'At Home' },
                    ]}
                    alternateStyle
                  />
                </FormRow>
                <FormRow
                  fieldLabel={'Patient primary department'}
                  fieldName={'horizonCenterId'}
                  labelClass="label-form-row">
                  <Field
                    name="horizonCenterId"
                    component={SelectField}
                    placeholder="Please select"
                    disabled={!isGetPinBtnEnabled}
                    updateMutation={(value: any) => {
                      saveHorizonCenterId({ variables: { id: patientId, horizonCenterId: value } });
                    }}
                    options={primaryDepartmentOptions}
                  />
                </FormRow>

                <FormRow fieldLabel={'Select form(s)'} fieldName={'selectedFormPack'} labelClass="label-form-row">
                  <Field
                    name="selectedFormPack"
                    component={SelectField}
                    placeholder="Please select"
                    disabled={!isGetPinBtnEnabled}
                    updateMutation={() => {}}
                    options={
                      selectedAccessType === AccessType.IN_CLINIC ? inClinicFormPackOptions : atHomeFormPackOptions
                    }
                    style={{ maxWidth: '320px' }}
                  />
                </FormRow>

                {selectedAccessType === AccessType.IN_CLINIC && submitCount > 0 && (
                  <FormRow
                    fieldLabel={'One time Access PIN'}
                    fieldName="pin-text"
                    labelClass="label-form-row pin-label">
                    {getPatientPinLoading && (
                      <div style={{ lineHeight: '58px' }}>
                        <div className="spinner-border" role="status" style={{ alignSelf: 'center' }}>
                          <div className="sr-only" />
                        </div>
                      </div>
                    )}
                    {!getPatientPinLoading && getPatientPinData && (
                      <StyledPinSpan>{getPatientPinData?.getPin?.pin}</StyledPinSpan>
                    )}
                  </FormRow>
                )}

                {selectedAccessType === AccessType.AT_HOME && (
                  <>
                    <FormRow fieldLabel={'Mobile'} fieldName={'primaryPhone'} labelClass="label-form-row">
                      <Field name="primaryPhone" component={TextAreaField} id="primaryPhone" />
                    </FormRow>
                    <FormRow fieldLabel={'Email'} fieldName={'email'} labelClass="label-form-row">
                      <Field name="email" component={TextAreaField} id="email" />
                    </FormRow>
                  </>
                )}
              </ModalBody>
              <ModalFooter className={`${componentClass}-footer`}>
                <Button id={`${componentClass}-close-btn`} onClick={handleCloseModal} mode="outlined">
                  {'Close'}
                </Button>
                <Button
                  id={`${componentClass}-submit-btn`}
                  onClick={submitForm}
                  busy={getPatientPinLoading}
                  disabled={getPatientPinLoading || !isGetPinBtnEnabled}>
                  {primaryButtonText()}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PatientAccessModal;
