export interface DoseSiteSummaryType {
  categorySiteGroup: string | null;
  deliveredDosageQuantity: number | null;
  deliveredDosageRatio: number | null;
  endDate: string | null;
  firstTreatment: string;
  id: string | null;
  identifierSite: string | null;
  isAria: boolean;
  latestTreatment: string | null;
  diagnosisId: string | null;
  medicationReference: string | null;
  perFractionDoseQuantity: number | null;
  plannedDosageQuantity: number;
  plannedDosageRatio: number | null;
  remainingDoseQuantity: number | null;
  rxFxUniformQuantity: number | null;
  status: string | null;
  statusReason: string | null;
  technique: string | null;
  totalDosageLimit: number | null;
}

export interface OnTreatmentReviewType {
  careplan: string;
  clinicalExamination: string;
  dss: string;
  id: string;
  imageReview: string;
  lastSubmittedAt: string | null;
  managementDecision: string;
  responseToTreatment: string;
  reviewDate: string;
  status: string;
  submittedAt: string;
  treatmentFractionsReviewed: string;
  currentChanges: boolean;
}

export enum BannerType {
  DRAFT = 'draft',
  UNSUBMITTED = 'unsubmitted',
  SUBMITTED = 'submitted',
  NONE = 'none',
}
