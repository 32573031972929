import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form as FormikForm, Formik } from 'formik';
import { generatePath } from 'react-router';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralError } from 'shared-components/components';
import { styled } from '@mui/system';

import { DTRatingItem } from 'op-classes';
import { FormContainer, NAPageContainer } from 'op-components';
import { MandatoryPatient } from 'op-interfaces';
import { deletePreCtModal } from 'op-pages/OP/PatientFormSummary/PatientFormSummary';
import {
  CREATE_AMENDED_PRE_CT_TRIAGE,
  DELETE_PRE_CT_CHART_CHECK,
  DELETE_PRE_CT_TRIAGE,
  QUERY_NURSE,
} from 'op-pages/OP/PatientFormSummary/PatientFormSummaryQueries';
import { Modal, ModalContentSubmissionError, SCStepper } from 'shared-components/components';
import { Logger } from 'shared-components/utils';

import { PreCtChartCheckData } from '../PreCtChartCheck/interfaces/PreCtChartCheckInterfaces';
import { AdditionalNotes, ModalContentMissingQcl, ModalContentSubmit, SideBar } from '../shared/components';
import { sharedContent } from '../shared/constants';
import { LocationListData, LoggedInUser } from '../shared/interfaces';
import { GET_ACTIVE_QCLS } from '../shared/queries';
import { fieldsToValidateOnContinue, reviewInfo, stepperTitle } from './constants';
import FormContext from './contexts';
import { HealthAssessmentData, PreCtTriageData, RegistrationFormData } from './interfaces/PreCtTriageInterfaces';
import CognitiveImpairmentRisk from './pages/CognitiveImpairmentRisk';
import MalnutritionRisk from './pages/MalnutritionRisk';
import Pain from './pages/Pain';
import ReviewAndSubmit from './pages/ReviewAndSubmit';
import SocialCircumstances from './pages/SocialCircumstances';
import Summary from './pages/Summary/Summary';
import TransportMode from './pages/TransportMode';
import Wellbeing from './pages/Wellbeing';
import { patientSummaryLink, routes } from './routes';
import { validationSchema } from './validation';

const logger = new Logger('Triage');

const Wrapper = styled('div')`
  height: 100%;
`;

const Form = styled(FormikForm)`
  height: 100%;
`;

const StepperPlaceholder = styled('div')`
  min-width: 15%;
  background-color: ${(props) => props.theme.palette.grey[100]};
  min-height: calc(100% - 60px);
  height: auto;
  box-shadow: inset -1px 0px 1px rgba(0, 0, 0, 0.2);
`;

interface Props {
  distressThermometerData: DTRatingItem;
  registrationFormData: RegistrationFormData;
  healthAssessmentData: HealthAssessmentData;
  patientDetailsData: MandatoryPatient;
  preCtTriageData: PreCtTriageData;
  loggedInUserData: LoggedInUser;
  allowedLocationsData: LocationListData[];
  latestChartCheckData: PreCtChartCheckData;
  fetchLatestChartCheck: () => void;
}

interface FormikProps {
  setTouched: any;
  values: any;
  errors: any;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: any;
}

const PreCtTriage = ({
  distressThermometerData,
  registrationFormData,
  healthAssessmentData,
  patientDetailsData,
  preCtTriageData,
  loggedInUserData,
  allowedLocationsData,
  latestChartCheckData,
  fetchLatestChartCheck,
}: Props): JSX.Element => {
  const [fieldsVisited, setFieldsVisited] = useState({});
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [openSubmitErrorModal, setOpenSubmitErrorModal] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { patientId, formId } = useParams<{ patientId: string; formId: string }>();
  const formContext = useContext(FormContext);
  const { updatePreCtTriage, submitPreCtTriage, refetchPreCtTriage } = formContext.mutations;
  const {
    nurseLocation,
    unintentionalWeightLoss,
    hasDecreasedAppetite,
    isAlertAndOrientated,
    hasAlteredCognitiveState,
    hasCurrentPain,
    painScore,
    isAbleToLieFlat,
    isAbleToClimbStairs,
    transportMode,
    otherTransport,
    socialConsiderations,
    livesAlone,
    livesWith,
    isCarer,
    carerSpecification,
    usualResidence,
    usualResidenceSpecify,
    staysAtUsualResidence,
    otherResidence,
    needsAssistanceAdls,
    assistance,
    assistanceSpecify,
    hasFamilyOrFriend,
    familyOrFriendSpecification,
    existingSupportService,
    existingSupportSpecification,
    hasSensoryImpairment,
    sensoryImpairments,
    sensoryImpairmentsSpecification,
    otherInformation,
    hasPatientCarerConcern,
    pelvicFitness,
    hasHighRiskFactors,
    howFactorsWereIdentified,
    additionalNotes,
    updatedBy,
    lastUpdateUser,
    isLocked,
    isAmendment = false,
    requiresWellbeingMxPlan,
    englishSecondLanguage,
    primaryLanguage,
    requireInterpreter,
  } = preCtTriageData || {};

  const incomingValues = {
    nurseLocation: nurseLocation ? nurseLocation.toString() : '',
    isAmendment,
    lastUpdateUser,
    unintentionalWeightLoss,
    hasDecreasedAppetite,
    isAlertAndOrientated,
    hasAlteredCognitiveState,
    hasCurrentPain,
    painScore,
    isAbleToLieFlat,
    isAbleToClimbStairs,
    transportMode,
    otherTransport,
    socialConsiderations,
    livesAlone,
    livesWith,
    isCarer,
    carerSpecification,
    usualResidence,
    usualResidenceSpecify,
    staysAtUsualResidence,
    otherResidence,
    needsAssistanceAdls,
    assistance,
    assistanceSpecify,
    hasFamilyOrFriend,
    familyOrFriendSpecification,
    existingSupportService,
    existingSupportSpecification,
    hasSensoryImpairment,
    sensoryImpairments,
    sensoryImpairmentsSpecification,
    englishSecondLanguage,
    primaryLanguage,
    requireInterpreter,
    otherInformation,
    hasPatientCarerConcern,
    pelvicFitness,
    hasHighRiskFactors,
    howFactorsWereIdentified,
    additionalNotes,
    updatedBy,
    requiresWellbeingMxPlan,
  };

  const handleMutation = (fieldName: string, value: string | number | boolean | null | string[]): void => {
    updatePreCtTriage({ variables: { id: formId, [fieldName]: value } });
  };

  const generateEmail = () => {
    window.location.href = `mailto:${sharedContent.reviewAndSubmit.submissionError.emailLink}`;
  };

  const submitForm = (): void => {
    submitPreCtTriage({
      variables: { id: formId },
    })
      .then(() => {
        setOpenSubmitModal(false);
        setSubmitDisabled(false);
        history.push(`/patient/${patientId}/summary`);
      })
      .catch((error: any) => {
        setOpenSubmitModal(false);
        setOpenSubmitErrorModal(true);
        setSubmitDisabled(false);
        logger.error(error);
      });
  };

  const sidebarVariables = {
    formId,
    patientId,
  };

  const stepperArray = Object.values<{ sectionTitle: string; name: string; path: string }>(routes).map((object) => ({
    sectionTitle: `${object.sectionTitle}`,
    name: `${object.name}`,
    path: `${generatePath(object.path, sidebarVariables)}`,
  }));

  const patientState = patientDetailsData.userProfile && patientDetailsData.userProfile.systemState;

  const { firstName, lastName } = loggedInUserData;
  const submittedByNurse = `${firstName} ${lastName}`;

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deletePreCtChartCheck] = useMutation(DELETE_PRE_CT_CHART_CHECK, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patientId } }],
  });
  const [deletePreCtTriage] = useMutation(DELETE_PRE_CT_TRIAGE, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patientId } }],
  });
  const [createAmendedPreCtTriage] = useMutation(CREATE_AMENDED_PRE_CT_TRIAGE, {
    refetchQueries: [
      { query: QUERY_NURSE, variables: { id: patientId } },
      { query: GET_ACTIVE_QCLS, variables: { patientId: patientId, isAmendment: true } },
    ],
    fetchPolicy: 'no-cache',
  });
  const { pathname } = window.location;
  const currentPageVisited = pathname.substring(pathname.lastIndexOf('/') + 1);
  const currentPage = window.location.pathname.split('/').slice(-1)[0];

  useEffect(() => {
    //@ts-ignore
    const fields = fieldsToValidateOnContinue[currentPageVisited].reduce((allFieldsTouched: any, field: any) => {
      allFieldsTouched[field] = true;
      return allFieldsTouched;
    }, {});

    setFieldsVisited({ ...fieldsVisited, ...fields });
  }, [window.location.pathname]);

  useEffect(() => {
    fetchLatestChartCheck();
    if (isLocked && !location.pathname.includes('review-and-submit')) {
      history.push(
        generatePath(routes.reviewAndSubmit.path, {
          patientId,
          formId,
        }),
      );
    }
  }, []);

  if (!preCtTriageData) return <GeneralError />;

  return (
    <Wrapper>
      <Formik initialValues={incomingValues} onSubmit={() => {}} validationSchema={validationSchema(patientState)}>
        {({ values, errors, setTouched, handleChange, setFieldValue }: FormikProps) => {
          const createAmendmentAndRedirect = async () => {
            await createAmendedPreCtTriage({ variables: { id: formId } })
              .then(({ data }: any) =>
                history.push(
                  generatePath(routes.malnutritionRisk.path, {
                    patientId,
                    formId: data.createAmendedPreCtTriage.preCtTriage.id,
                  }),
                ),
              )
              .then(() => {
                refetchPreCtTriage();
              })
              .then(() => setFieldValue('additionalNotes', ''));
          };

          const handlePagesVisitedValidation = () => {
            setTouched(fieldsVisited);
          };

          const formData = { id: formId, title: 'Initial Triage', isAmendment: isAmendment };

          const formStandardValues = {
            updatedBy,
            isAmendment: Boolean(preCtTriageData.isAmendment),
            lastUpdateUser,
            patient: patientDetailsData,
            reviewInfo: reviewInfo,
            saveAndExitLink: generatePath(patientSummaryLink, { patientId }),
            setDeleteModalIsOpen: setDeleteModalIsOpen,
            createAmendmentAndRedirect: () => createAmendmentAndRedirect(),
            handleValidation: () => handlePagesVisitedValidation(),
            deleteModal: () =>
              deletePreCtModal(
                deletePreCtChartCheck,
                deletePreCtTriage,
                deleteModalIsOpen,
                setDeleteModalIsOpen,
                formData,
                patientId,
                history,
                true,
              ),
          };
          const formContainerData = {
            malnutritionRisk: {
              pageNumber: 1,
              sectionTitle: routes.malnutritionRisk.sectionTitle,
              continueLink: generatePath(routes.cognitiveImpairmentRisk.path, {
                patientId,
                formId,
              }),
            },
            cognitiveImpairmentRisk: {
              pageNumber: 2,
              sectionTitle: routes.cognitiveImpairmentRisk.sectionTitle,
              continueLink: generatePath(routes.pain.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.malnutritionRisk.path, { patientId, formId }),
            },
            pain: {
              pageNumber: 3,
              sectionTitle: routes.pain.sectionTitle,
              continueLink: generatePath(routes.transportMode.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.cognitiveImpairmentRisk.path, {
                patientId,
                formId,
              }),
            },
            transportMode: {
              pageNumber: 4,
              sectionTitle: routes.transportMode.sectionTitle,
              continueLink: generatePath(routes.socialCircumstances.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.pain.path, {
                patientId,
                formId,
              }),
            },
            socialCircumstances: {
              pageNumber: 5,
              sectionTitle: routes.socialCircumstances.sectionTitle,
              continueLink: generatePath(routes.wellbeingScreen.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.transportMode.path, {
                patientId,
                formId,
              }),
            },
            wellbeingScreen: {
              pageNumber: 6,
              sectionTitle: routes.wellbeingScreen.sectionTitle,
              continueLink: generatePath(routes.summary.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.socialCircumstances.path, {
                patientId,
                formId,
              }),
            },
            summary: {
              pageNumber: 7,
              sectionTitle: routes.summary.sectionTitle,
              continueLink: generatePath(routes.reviewAndSubmit.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.wellbeingScreen.path, {
                patientId,
                formId,
              }),
            },
            reviewAndSubmit: {
              pageNumber: 8,
              sectionTitle: routes.reviewAndSubmit.sectionTitle,
              backLink: generatePath(routes.summary.path, {
                patientId,
                formId,
              }),
            },
          };
          return (
            <Form>
              <NAPageContainer>
                <StepperPlaceholder>
                  <SideBar sidebarVariables={sidebarVariables}>
                    <SCStepper
                      stepperTitle={stepperTitle}
                      data={stepperArray}
                      currentPage={currentPage}
                      handleValidation={handlePagesVisitedValidation}
                      isLocked={isLocked}
                    />
                  </SideBar>
                </StepperPlaceholder>
                <Switch>
                  <Route
                    path={routes.malnutritionRisk.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.malnutritionRisk}>
                        <MalnutritionRisk
                          allowedLocations={allowedLocationsData}
                          lastUpdateUserData={lastUpdateUser}
                          handleMutation={handleMutation}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.cognitiveImpairmentRisk.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.cognitiveImpairmentRisk}>
                        <CognitiveImpairmentRisk handleMutation={handleMutation} lastUpdateUserData={lastUpdateUser} />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.pain.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.pain}>
                        <Pain
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.transportMode.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.transportMode}>
                        <TransportMode
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          patientState={patientState}
                          registrationFormData={registrationFormData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.socialCircumstances.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.socialCircumstances}>
                        <SocialCircumstances
                          typeOfResidenceRefData={formContext.typeOfResidenceRefData}
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          registrationFormData={registrationFormData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.wellbeingScreen.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.wellbeingScreen}>
                        <Wellbeing
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          distressThermometerData={distressThermometerData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.summary.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.summary}>
                        <Summary
                          handleMutation={handleMutation}
                          patientState={patientState}
                          lastUpdateUserData={lastUpdateUser}
                          latestChartCheckData={latestChartCheckData}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.reviewAndSubmit.path}
                    render={(): JSX.Element => (
                      <FormContainer
                        isLocked={isLocked}
                        {...formStandardValues}
                        {...formContainerData.reviewAndSubmit}
                        reviewInfo={reviewInfo}
                        handleValidation={handlePagesVisitedValidation}
                        saveAndExitLink={generatePath(patientSummaryLink, { patientId })}
                        submitForm={() => setOpenSubmitModal(true)}>
                        <ReviewAndSubmit
                          patient={patientDetailsData}
                          isLocked={isLocked}
                          allowedLocations={allowedLocationsData}
                        />
                        <Modal
                          isOpen={openSubmitModal}
                          title={
                            errors.nurseLocation
                              ? sharedContent.reviewAndSubmit.missingQcl.title
                              : sharedContent.reviewAndSubmit.submit.title
                          }
                          dismissFunction={() => setOpenSubmitModal(false)}
                          leftButtonText={sharedContent.generic.label.cancel}
                          leftButtonHandleClick={(event: MouseEvent): void => {
                            event.preventDefault();
                            setOpenSubmitModal(false);
                          }}
                          rightButtonText={
                            errors.nurseLocation
                              ? sharedContent.reviewAndSubmit.missingQcl.returnToField
                              : sharedContent.generic.label.submit
                          }
                          rightButtonLink={
                            errors.nurseLocation && generatePath(routes.malnutritionRisk.path, { patientId, formId })
                          }
                          rightButtonDisabled={submitDisabled}
                          rightButtonHandleClick={(event: MouseEvent) => {
                            if (errors.nurseLocation) {
                              setOpenSubmitModal(false);
                            } else {
                              event.preventDefault();
                              setSubmitDisabled(true);
                              submitForm();
                            }
                          }}>
                          {errors.nurseLocation ? (
                            <ModalContentMissingQcl sectionName={routes.malnutritionRisk.sectionTitle} />
                          ) : (
                            <ModalContentSubmit
                              isAmendment={isAmendment}
                              submittedByNurse={submittedByNurse}
                              formName="Triage"
                            />
                          )}
                        </Modal>
                        <Modal
                          centeredButton
                          isOpen={openSubmitErrorModal}
                          dismissFunction={() => setOpenSubmitErrorModal(false)}
                          title={sharedContent.reviewAndSubmit.submissionError.title}
                          rightButtonText={sharedContent.reviewAndSubmit.submissionError.contactText}
                          rightButtonLink={generatePath(patientSummaryLink, { patientId })}
                          rightButtonHandleClick={(): void => {
                            generateEmail();
                          }}>
                          <ModalContentSubmissionError
                            text={sharedContent.reviewAndSubmit.submissionError.mainText}
                            emailLink={sharedContent.reviewAndSubmit.submissionError.emailLink}
                          />
                        </Modal>
                      </FormContainer>
                    )}
                  />
                </Switch>
                <AdditionalNotes
                  name="additionalNotes"
                  handleMutation={handleMutation}
                  value={values.additionalNotes}
                  handleChange={handleChange}
                  isLocked={isLocked}
                />
              </NAPageContainer>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default PreCtTriage;
