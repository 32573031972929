// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { ComposedModal } from 'gc-ui';
import { WarningSharp } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { GET_PATIENT_NOTES } from 'op-components/RO/Notes/Notes';
import { CAREPLAN_STATUS } from 'op-pages/RO/Careplan/Common';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { Careplan, SiteGroupSet } from 'op-pages/RO/Careplan/Interface';
import { SOFT_DELETE_CAREPLAN } from 'op-pages/RO/Careplan/Queries';
import { GET_PATIENT_CAREPLAN_LIST } from 'op-pages/RO/PatientSummary/PatientProfileQueries';
import React, { useState } from 'react';
import { Slide, toast } from 'react-toastify';
import { Popover } from 'shared-components/components';
import { CheckboxField } from 'shared-components/components/UIFormComponents';
import GCButton from 'shared-components/components/UIFormComponents/GCButton';
import { Region } from 'shared-components/enums';
import { styled } from '@mui/system';
import { generateCareplanName, getSiteGroupRepresentation } from './common';
import { useTheme } from '@mui/material';

export interface DeletePopoverProps {
  careplan: Careplan;
  patientId: any;
  handlePopUpClose: () => void;
  anchorEl: any;
  isPopUpOpen: boolean;
}

const Container = styled('div')`
  display: flex;
`;

const Description = styled('div')`
  margin-bottom: 16px;
`;

const ButtonsContainer = styled('section')`
  display: flex;
  width: 100%;
  margin-bottom: 0;
  justify-content: flex-end;
`;

const StyledCareplanName = styled('span')`
  font-weight: bold;
  text-transform: uppercase;
`;

const List = styled('ul')`
  margin: 8px auto 16px 0;
  list-style: none;
  li::before {
    margin-left: 0;
    content: '\\2022';
    width: 1em;
    font-size: 32px;
    font-weight: bold;
    vertical-align: middle;
    color: ${(props) => props.theme.palette.primary.main};
  }
  li {
    margin-bottom: 0;
  }
`;

const BoldUnderline = styled('b')`
  text-decoration: underline;
`;

const AcknowledgeText = styled('div')`
  margin-bottom: 8px;
  font-weight: bold;
`;

const DeleteCareplan = ({
  careplan,
  patientId,
  handlePopUpClose,
  anchorEl,
  isPopUpOpen,
}: DeletePopoverProps): JSX.Element => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [voidAcknowledged, setVoidAcknowledged] = useState(false);
  const isUsRegion = import.meta.env.REACT_APP_REGION === Region.US;
  const theme = useTheme();

  const [softDeleteCareplan] = useMutation(SOFT_DELETE_CAREPLAN, {
    refetchQueries: [
      { query: GET_PATIENT_CAREPLAN_LIST, variables: { patientId } },
      { query: GET_PATIENT_NOTES, variables: { patientId } },
    ],
  });

  const [softDeleteCareplanDraft] = useMutation(SOFT_DELETE_CAREPLAN, {
    refetchQueries: [{ query: GET_PATIENT_CAREPLAN_LIST, variables: { patientId } }],
  });

  const POPUP = {
    HEADER: `${careplan.careplanStatus === CAREPLAN_STATUS.draft ? 'Discard Draft' : 'Void'} Careplan`,
    DESCRIPTION:
      careplan.careplanStatus === CAREPLAN_STATUS.draft
        ? 'All data will be deleted. Once deleted careplan data cannot be recovered.'
        : 'Would you like to proceed with this action?',
  };

  const DIALOG = {
    HEADER: 'Void Careplan',
  };

  const handleDiscardCareplan = () => {
    softDeleteCareplanDraft({ variables: { id: careplan.id } });
  };

  const handleModalOpen = () => {
    handlePopUpClose();
    setIsModalOpen(true);
  };

  const handleVoidCareplan = async () => {
    await softDeleteCareplan({ variables: { id: careplan.id } });
    const NotificationMessage = () => (
      <>
        <strong style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }} className="title">
          Careplan Voided
        </strong>
        {careplan.sitegroupSet.map((siteGroup: SiteGroupSet, index: React.Key | null | undefined) => {
          return (
            <div style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }} key={index}>
              {getSiteGroupRepresentation(siteGroup)}
            </div>
          );
        })}
      </>
    );

    toast.dark(<NotificationMessage />, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Slide,
      progress: undefined,
    });
  };

  return (
    <>
      <Container>
        <Popover
          id={`dd-popover-${careplan.id}`}
          title={POPUP.HEADER}
          open={isPopUpOpen}
          anchorEl={anchorEl}
          handleClickAway={handlePopUpClose}
          fadeTimeout={350}
          placement="bottom-end">
          <Description>{POPUP.DESCRIPTION}</Description>
          <ButtonsContainer>
            <Button data-testid="dialog-cancel-btn" onClick={handlePopUpClose}>
              Cancel
            </Button>
            <Button
              data-testid="dialog-discard-btn"
              variant="outlined"
              onClick={careplan.careplanStatus === CAREPLAN_STATUS.draft ? handleDiscardCareplan : handleModalOpen}
              color="error"
              autoFocus>
              Yes
            </Button>
          </ButtonsContainer>
        </Popover>
      </Container>
      <ComposedModal
        isOpen={modalIsOpen}
        dismissFunction={() => {
          setIsModalOpen(false);
        }}
        width="664px"
        headerText={DIALOG.HEADER}
        icon={<WarningSharp style={{ fill: theme.palette.warning.dark }}></WarningSharp>}
        secondaryRightButton={{
          buttonText: 'Cancel',
          onClick: (e: any): void => {
            e.preventDefault();
            setIsModalOpen(false);
          },
        }}
        primaryRightButton={{
          buttonText: 'Void Careplan',
          type: voidAcknowledged ? 'DESTRUCTIVE' : 'DISABLED',
          disabled: !voidAcknowledged,
          onClick: (e: any): void => {
            e.preventDefault();
            handleVoidCareplan();
            setIsModalOpen(false);
          },
        }}>
        <div>
          <Description>
            <strong>Careplan: </strong>
            <StyledCareplanName>{generateCareplanName(careplan.sitegroupSet, ' + ')}</StyledCareplanName>
          </Description>
          <Description>
            <div>This action should only be completed when:</div>
            <List>
              <li>
                The careplan is associated with an <BoldUnderline>incorrect patient or created in error</BoldUnderline>
              </li>
              <li>
                AND the patient has <BoldUnderline>NOT started</BoldUnderline> treatment
              </li>
            </List>
            <div>{CurrentAppConfig.CareplanVoidDescription}</div>
          </Description>
          {!isUsRegion && (
            <Description>
              If you need to revert this action please contact support:{' '}
              <b>
                <a href={`mailto:${CurrentAppConfig.SupportEmail}`}>{CurrentAppConfig.SupportEmail}</a>
              </b>
            </Description>
          )}
        </div>
        <AcknowledgeText>Acknowledgement is required prior to voiding careplan*</AcknowledgeText>
        <CheckboxField
          name="voidAcknowledgement"
          label="I acknowledge"
          onClick={() => setVoidAcknowledged(!voidAcknowledged)}
          width={'100%'}
        />
      </ComposedModal>
    </>
  );
};

export default DeleteCareplan;
