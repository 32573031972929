// PX Enums
import { NavigationType, NavigationDropDownMenuType, SubmitStatus } from './px_enums';

export enum BASE_REGISTRATION_PAGES {
  BASIC = 'Basic',
  CONTACT = 'Contact',
  ADDRESS = 'Address',
  ALT_CONTACT = 'AltContact',
  GP_INFO = 'GpInfo',
  INSURANCE = 'Insurance',
  MEDICARE = 'Medicare',
  DEMOGRAPHICS = 'Demographics',
  INFO_NOTICE = 'InfoNotice',
  PREFERENCES = 'Preferences',
}

export enum OPCLINIC_REGISTRATION_PAGES {
  OPCLINIC = 'OPClinic',
  OPPATIENT = 'OPPatient',
  OPINSURANCE = 'OPInsurance',
  OPREFERRAL = 'OPReferral',
  OPBOOKAPPOINTMENTS = 'OPBookAppointments',
  OPSUMMARY = 'OPSummary',
}

export enum DT_CATEGORY {
  PRACTICAL = 'practical',
  SOCIAL = 'social',
  EMOTIONAL = 'emotional',
  SPIRITUAL = 'spiritual',
  PHYSICAL = 'physical',
  OTHER = 'other',
  NONE = 'none',
}

export enum NavigatorDirection {
  BACK,
  FORWARD,
}

export enum FormType {
  REGISTRATION = 'Patient Registration',
  DISTRESS = 'Distress Thermometer',
  DISTRESS_EOT = 'Distress Thermometer EOT',
}

export enum HAMutationType {
  GET_HEALTH_ASSESSMENT_MUTATION,
  UPDATE_TREATMENT,
  UPDATE_REACTION,
}

export enum FormStatus {
  CREATED = 'filterCreatedInMosaiq',
  REG_REVIEW_REQUIRED = 'filterReviewRequired',
  REG_SUBMITTED = 'filterSubmittedToMosaiq',
  IN_PROGRESS = 'filterInProgress',
  IN_PROGRESS_AMENDED = 'filterInProgressAmendment',
}

export enum ReferralType {
  GP = 'General Practitioner',
  SPECIALIST = 'Specialist',
}

export const RoleType = {
  NURSE: 'nurse',
  PSO: 'pso',
  SUPERUSER: 'superuser',
  RO: 'ro',
  MO: 'mo',
  DOCTOR: 'doctor',
  CAREPLANVIEWER: 'CarePlanViewerUser',
  OTHER: 'other',
};

export const OncologyTypes = {
  RO: 'radiation',
  MO: 'medonc',
};

export const FormTypeId = {
  REGISTRATION_FORM: 'registration-form',
  HEALTH_ASSESSMENT: 'health-assessment',
  DISTRESS_THERMOMETER: 'distress-thermometer',
  DISTRESS_THERMOMETER_EOT: 'distress-thermometer-eot',
  COVID_SCREENING: 'covid-screening',
  THERANOSTICS: 'theranostics',
};

export enum DistressThermometerVersion {
  INITIAL = 'INITIAL',
  EOT = 'EOT',
}

export enum CareplanStatusFilter {
  DRAFT = 'Draft',
  PRESCRIPTION = 'Prescription',
  SIMULATION = 'Simulation',
  PLAN_AIM = 'Plan Aim',
  CPOT = 'CPOT',
  PRESCRIPTION_DIRECTIVE = 'Prescription Directive',
}

export const CareplanFilter = {
  UNSUBMITTED: 'Unsubmitted changes',
  OTHER: 'Other',
};

export enum RoDashboardTabs {
  CLINIC_LIST = 'clinicList',
  CAREPLANS = 'careplans',
  APPOINTMENTS = 'dailyAppointments',
  CAREPLAN_VIEWER = 'careplanviewer',
}

export enum AuthorizedICDTreatingDepartments {
  CROMWELL = 'GenesisCare, Cromwell',
}

export { NavigationType, NavigationDropDownMenuType, SubmitStatus };

export enum DoseUnit {
  CGY = 'cGy',
  GY = 'Gy',
}
export const GY_TO_CGY = 100;
export const CGY_TO_GY = 0.01;
