import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { FormContainer, ModalSubmitForm } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import { FormStatus } from 'op-enums';
import React, { useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'shared-components/components';
import { Edit } from 'shared-components/images';
import { styled } from '@mui/system';
import {
  extraInformation,
  HeaderSubTitle,
  HeaderTitle,
  PSO_SUMMARY_LINK,
  registrationPath,
  sharedFormContainerProps,
  SPECIFY_MARITAL_FIELDS,
} from '../Helper';
import { FormProps, PatientReviewAndSubmit, Profile, User } from '../interfaces';
import {
  ADDRESS_FIELDS,
  BASIC_FIELDS,
  CONTACT_FIELDS,
  DEMOGRAPHICS_FIELDS,
  EMERGENCY_CONTACT_FIELDS,
  FEEDBACK_FIELDS,
  FORM_TEXT,
  INSURANCE_FIELDS,
  REFERRER_FIELDS,
  SOCIAL_GEOGRAPHIC_FIELDS,
} from './constants';
import { Field, HeaderProps, RowBlockProps, RowProps } from './interfaces';
import { REVIEW_AND_SUBMIT_QUERY, SUBMIT_FORM, SUBMIT_FORM_PSO } from './ReviewAndSubmitQueries';

const StyledCard = styled('div')`
  padding: 16px 24px 24px 24px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 8px rgba(113, 110, 106, 0.4);
`;

const StyledHeader = styled('div')`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 23px;
  line-height: 28px;
`;

const StyledHeaderRow = styled('div')`
  line-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  .ro-button-style .form-fields-default-button .default-gc-button {
    margin-right: 0;
  }
`;

const StyledRow = styled('div')`
  line-height: 28px;
  display: flex;
  flex-direction: row;
`;

const StyledField = styled('label')`
  flex-grow: 1;
  width: 50%;
  padding-right: 16px;
`;

const StyledInfoField = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 50%;
  justify-content: flex-start;
  line-height: 28px;
  align-items: flex-start;
  margin-bottom: 13px;

  label:first-of-type {
    line-height: initial;
    flex-grow: 0;
  }

  div:first-of-type {
    margin-top: 2px;
    line-height: initial;
    flex-grow: 1;
    align-items: start;
  }
`;

const StyledConditionalSection = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]};

  label:first-of-type {
    padding-left: 16px;
  }
`;

const StyledSubHeading = styled('div')`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const StyledDivider = styled('div')`
  border-top: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-top: 24px;
  padding-top: 18px;
`;

const EditIcon = styled(Edit)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;

const StyledButton = styled('button')`
  width: 90px;
  min-width: 90px;
  height: 40px;
  display: flex;
  background: white;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 20px;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 17px;
`;

const StyledButtonText = styled('div')`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.primary};
`;

const dateFields = ['dob', 'spouseDob', 'primaryInsurance.policyDob', 'secondaryInsurance.policyDob'];
const infoFields = ['advancedDirective', 'powerOfAttorney', 'livingWill'];
const checkboxFields = ['agreeToSurvey'];
const UNITED_STATES_OF_AMERICA = 'United States of America';

const formatValue = (name: string, value: string | number | boolean) => {
  if (dateFields.includes(name)) {
    // Format as a nice date
    return value ? moment(value.toString()).format('MM/DD/YYYY') : value ?? '-';
  }
  if (typeof value == 'boolean') {
    if (value) return 'Yes';
    if (value === false && !checkboxFields.includes(name)) return 'No';
    return '-';
  }
  return value?.toString() || '-';
};

const ReviewAndSubmit = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const { patientId: id } = useParams<{ patientId: string }>();

  const { data, error, loading } = useQuery<{ patient: PatientReviewAndSubmit; user: User; profile: Profile }>(
    REVIEW_AND_SUBMIT_QUERY,
    {
      variables: { id },
    },
  );
  const referringPage = sessionStorage.getItem('referringPage');

  const [submitForm] = useMutation(SUBMIT_FORM);

  const [submitFormPso, { loading: mutationLoading }] = useMutation(SUBMIT_FORM_PSO, {
    onCompleted: () => {
      sessionStorage.setItem('referringPage', '');
      history.push(referringPage || `/navigator/patient/${id}/summary`);
    },
  });

  const history = useHistory();

  if (loading || !data || error) return <LoadingSpinner />;

  if (mutationLoading) return <LoadingSpinner loadingText="Submitting registration form" />;

  const { patient, user, profile } = data;
  const healthAssessmentStatus = patient?.healthAssessment?.status === FormStatus.REG_SUBMITTED;
  const showHealthAssessment = patient?.userProfile?.showHealthAssessment;
  const isInClinic = profile?.registrationAccessType === 'inClinic';

  const handleSubmit = () => {
    if (user.isPso) {
      submitFormPso({ variables: { id } });
    } else {
      submitForm({ variables: { id } });
      if (isInClinic && (healthAssessmentStatus || !showHealthAssessment)) return history.push(`/patient/${id}/home`);
      if (healthAssessmentStatus || !showHealthAssessment) return history.push('/patient/registrationComplete');
      history.push(`/patient/${id}/health/info`);
    }
  };

  const Header = ({ title, path }: HeaderProps): JSX.Element => {
    const formattedTtitle = title
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .replace(/\s/g, '-');
    return (
      <StyledHeaderRow>
        <StyledHeader data-test-id={`${formattedTtitle}-header-text`}>{title}</StyledHeader>
        <StyledButton
          data-test-id={`${formattedTtitle}-header-button`}
          onClick={(): void => {
            history.push(`/patient/${id}/registration/${path}`);
          }}>
          <EditIcon />
          <StyledButtonText>Edit</StyledButtonText>
        </StyledButton>
      </StyledHeaderRow>
    );
  };

  const Row = ({ field }: RowProps): JSX.Element => {
    const value = _.get(patient, field.key);

    const styledField = <StyledField data-test-id={`${field.key}`}>{formatValue(field.key, value)}</StyledField>;

    const fieldRow =
      infoFields.includes(field.key) && value === true && !user.isPso ? (
        <StyledInfoField>
          {styledField}
          {extraInformation(field.key)}
        </StyledInfoField>
      ) : (
        styledField
      );

    return (
      <StyledRow>
        <StyledField data-test-id={`${field.key}-label`}>{field.label}</StyledField>
        {fieldRow}
      </StyledRow>
    );
  };

  const RowBlock = ({ fields }: RowBlockProps): JSX.Element => (
    <>
      {fields.map(
        (field: Field, index: number): JSX.Element => (
          <Row key={`${field.label}-${index}`} field={field} />
        ),
      )}
    </>
  );

  const sharedProps = sharedFormContainerProps('Review and Submit', 10, registrationPath(id, 'feedback'), !user.isPso);
  const formContainerProps = {
    ...sharedProps,
    continueLink: '',
    submitButtonText: 'Submit',
    handleShowSaveExitConfirm: () => exitForm(true),
    submitForm: () => setShowSubmitModal(true),
    showPTSummaryNav: user.isPso,
    patient: user.isPso && patient,
    saveAndExitLink: user.isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId: id }),
  };

  return (
    <RegistrationContext.Consumer>
      {(registrationContext) => {
        registrationContext.setRegistrationSummaryVisited(true);
        return (
          <>
            <FormContainer {...formContainerProps}>
              <HeaderTitle data-test-id="us-rego-page-title">{'Review & Submit'}</HeaderTitle>
              <HeaderSubTitle data-test-id="us-rego-page-subtitle">
                Please review the following details and click 'Submit'.
              </HeaderSubTitle>
              {/* Basic details */}
              <StyledCard>
                <Header title={BASIC_FIELDS.title} path={BASIC_FIELDS.path} />
                <RowBlock fields={BASIC_FIELDS.fields} />
              </StyledCard>

              {/* Contact details */}
              <StyledCard>
                <Header title={CONTACT_FIELDS.title} path={CONTACT_FIELDS.path} />
                <RowBlock fields={CONTACT_FIELDS.fields} />
              </StyledCard>

              {/* Address */}
              <StyledCard>
                <Header title={ADDRESS_FIELDS.title} path={ADDRESS_FIELDS.path} />
                <RowBlock fields={ADDRESS_FIELDS.fields} />
                {patient.inHospitalHospiceFacility && (
                  <StyledConditionalSection>
                    <RowBlock fields={ADDRESS_FIELDS.facilityFields} />
                  </StyledConditionalSection>
                )}
              </StyledCard>

              {/* Emergency contact */}
              <StyledCard>
                <Header title={EMERGENCY_CONTACT_FIELDS.title} path={EMERGENCY_CONTACT_FIELDS.path} />
                <RowBlock fields={EMERGENCY_CONTACT_FIELDS.fields} />
              </StyledCard>

              {/* Referrers */}
              <StyledCard>
                <Header title={REFERRER_FIELDS.title} path={REFERRER_FIELDS.path} />
                <RowBlock fields={REFERRER_FIELDS.fields} />
              </StyledCard>

              {/* Insurance */}
              <StyledCard>
                <Header title={INSURANCE_FIELDS.title} path={INSURANCE_FIELDS.path} />
                <StyledSubHeading>Primary Insurance</StyledSubHeading>
                <RowBlock fields={INSURANCE_FIELDS.fields} />
                <StyledDivider />
                <StyledSubHeading>Secondary Insurance</StyledSubHeading>
                <Row field={INSURANCE_FIELDS.secondaryConditional} key={`${INSURANCE_FIELDS.path}-98`} />
                {patient.hasSecondaryInsurance && (
                  <StyledConditionalSection>
                    <RowBlock fields={INSURANCE_FIELDS.secondaryFields} />
                  </StyledConditionalSection>
                )}
                <StyledDivider />
                <StyledSubHeading>Other Benefits</StyledSubHeading>
                <Row key={`${INSURANCE_FIELDS.path}-99`} field={INSURANCE_FIELDS.veteranConditional} />
                {patient.receiveVeteransAdminBenefits && (
                  <StyledConditionalSection>
                    <RowBlock fields={INSURANCE_FIELDS.veteranFields} />
                  </StyledConditionalSection>
                )}
              </StyledCard>

              {/* Demographics */}
              <StyledCard>
                <Header title={DEMOGRAPHICS_FIELDS.title} path={DEMOGRAPHICS_FIELDS.path} />
                <RowBlock fields={DEMOGRAPHICS_FIELDS.fields} />
                {SPECIFY_MARITAL_FIELDS.includes(patient.maritalStatusValue as string) && (
                  <StyledConditionalSection>
                    <RowBlock fields={DEMOGRAPHICS_FIELDS.spouseFields} />
                    {patient.spouseAlternateAddressBool && (
                      <RowBlock fields={DEMOGRAPHICS_FIELDS.spouseAlternateAddressFields} />
                    )}
                  </StyledConditionalSection>
                )}
                <RowBlock fields={DEMOGRAPHICS_FIELDS.raceField} />
                {['Other', 'More than one race'].includes(patient.race) && (
                  <StyledConditionalSection>
                    <RowBlock fields={DEMOGRAPHICS_FIELDS.raceSpecifiedFields} />
                  </StyledConditionalSection>
                )}
                <RowBlock fields={DEMOGRAPHICS_FIELDS.ancestryAndLanguageFields} />
              </StyledCard>

              {/* Social Geographic History */}
              <StyledCard>
                <Header title={SOCIAL_GEOGRAPHIC_FIELDS.title} path={SOCIAL_GEOGRAPHIC_FIELDS.path} />
                <Row key={`${SOCIAL_GEOGRAPHIC_FIELDS.path}-99`} field={SOCIAL_GEOGRAPHIC_FIELDS.countryConditional} />
                {patient.countryOfBirth === UNITED_STATES_OF_AMERICA && (
                  <StyledConditionalSection>
                    <Row
                      key={`${SOCIAL_GEOGRAPHIC_FIELDS.path}-99`}
                      field={SOCIAL_GEOGRAPHIC_FIELDS.stateConditional}
                    />
                  </StyledConditionalSection>
                )}
                <RowBlock fields={SOCIAL_GEOGRAPHIC_FIELDS.fields} />
                {patient.inThisStateAllYear === false && (
                  <StyledConditionalSection>
                    <RowBlock fields={SOCIAL_GEOGRAPHIC_FIELDS.addressFields} />
                  </StyledConditionalSection>
                )}
              </StyledCard>

              {/* Your feedback */}
              <StyledCard>
                <Header title={FEEDBACK_FIELDS.title} path={FEEDBACK_FIELDS.path} />
                <RowBlock fields={[FEEDBACK_FIELDS.heardAboutUs]} />
                {patient.heardAboutUs === 'Other' && (
                  <StyledConditionalSection>
                    <RowBlock fields={[FEEDBACK_FIELDS.heardAboutUsOther]} />
                  </StyledConditionalSection>
                )}
                <RowBlock fields={FEEDBACK_FIELDS.fields} />
              </StyledCard>
            </FormContainer>
            {/* {showSubmitModal && ( */}
            <ModalSubmitForm
              isOpen={showSubmitModal}
              setIsOpen={setShowSubmitModal}
              submitFormMutation={() => {
                handleSubmit();
              }}
              title="Are you sure you want to submit the form?"
              submitText={'Submit'}
              formText={FORM_TEXT}
            />
            {/* )} */}
          </>
        );
      }}
    </RegistrationContext.Consumer>
  );
};

export default ReviewAndSubmit;
