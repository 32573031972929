import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import './OrgansAtRisk.scss';
import OrgansAtRiskPopup from './OrgansAtRiskPopup';
import PrioritisedOrgansAtRiskRow from './PrioritisedOrgansAtRiskRow';
import { DELETE_OAR, OAR_QUERY, ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, REODER_OAR } from './Queries';
import { TenantContext, useErrorModalContext } from 'op-contexts';
import { AddCircleOutline, ErrorOutline } from '@mui/icons-material';
import lodash from 'lodash';
import { Button, CustomTheme, Stack, Typography, useTheme } from '@mui/material';
import type { DropResult, DroppableProvided, DraggableLocation } from '@hello-pangea/dnd';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import type { OAR, OARMap } from './dndreorder';
import { reorderOARMap } from './dndreorder';
import {
  GET_CAREPLAN,
  SITE_GROUP_TEMPLATE_VALUE_VARIATION,
  SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
} from '../../Queries';

export const formatToTwoDecimal = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  useGrouping: false,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const decimalFormatter = (value: any) => (value ? formatToTwoDecimal.format(value) : value);

const OrgansAtRiskModule = (props: any): JSX.Element => {
  const { setError } = useErrorModalContext();

  const theme = useTheme<CustomTheme>();

  const [modalShow, setModalShow] = useState(false);
  const [oarMap, setOarMap] = useState<OARMap>({});
  const [oarModalData, setOarModalData] = useState(null);
  const [editOarIndex, setEditOarIndex] = useState(-1);

  const cpotTriggered = props?.cpotTriggered;
  const { doseUnit } = useContext(TenantContext);

  const { loading, data, error } = useQuery(OAR_QUERY, {
    fetchPolicy: 'network-only',
    variables: { siteGroupId: props.siteGroupId },
  });

  window.console.log(data);

  const [reorderOarMutation] = useMutation(REODER_OAR, {
    refetchQueries: [{ query: OAR_QUERY, variables: { siteGroupId: props.siteGroupId } }],
  });

  useEffect(() => {
    if (!data?.roOrganAtRisk) return;

    // Add an index to each Oar before we split them for the droppables
    // To be used for the add and edit functions
    const indexedOars = data.roOrganAtRisk.map((item: OAR, index: number) => {
      return { ...item, index };
    });

    const priorityList = indexedOars.filter((item: OAR) => item.prioritized);
    const nonPriorityList = indexedOars.filter((item: OAR) => !item.prioritized);
    const newMap = { priority: priorityList, nonPriority: nonPriorityList };

    setOarMap(newMap);
  }, [data]);

  useEffect(() => {
    if (editOarIndex !== -1 && data && data.roOrganAtRisk) {
      setOarModalData(data.roOrganAtRisk[editOarIndex]);
    }
  }, [data]);

  const handleDrag = (result: DropResult): void => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    reorderOarMutation({
      variables: {
        siteGroupId: props.siteGroupId,
        sourcePrioritized: result.source.droppableId === 'priority',
        destinationPrioritized: result.destination.droppableId === 'priority',
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    });

    const source: DraggableLocation = result.source;
    const destination: DraggableLocation = result.destination;
    setOarMap((prevmap: OARMap) => {
      const reorderedMap = reorderOARMap({
        oarMap: prevmap,
        source,
        destination,
      });

      return {
        priority: reorderedMap.priority,
        nonPriority: reorderedMap.nonPriority,
      };
    });
  };

  const { data: templateOars, error: templateOarsError } = useQuery(ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, {
    variables: { siteGroupId: props.siteGroupId },
    skip: loading || !data || cpotTriggered,
  });

  useEffect(() => {
    if (error || templateOarsError) return setError();
  }, [error, templateOarsError]);

  const checkForOganListVariations = (): boolean => {
    // Compares the organs in the site group with the organs in the template
    if (!data || !templateOars || !templateOars.organAtRiskTemplateValuesVariation) return false;
    const templateOrgans = templateOars.organAtRiskTemplateValuesVariation
      .map((organ: any) => {
        return organ.organ;
      })
      .filter(Boolean);

    const groupOrgans = data.roOrganAtRisk.map((organ: OAR) => {
      return organ.organ;
    });

    if (!templateOrgans.length) return false;
    if (groupOrgans.length !== templateOrgans.length) return true;

    return !groupOrgans.every((organ: string) => templateOrgans.includes(organ));
  };

  const setOrganDefaultValue = (
    organ: any,
    prop: string,
    defaultValue: any,
    // ideally compareValues should be of type string[] | null[]
    // due to typescript errors relating to the Array.includes function compiling the first
    // parameter for the search field to be `never`, we have changed it to any[]
    compareValues: any[] = [],
  ): void => {
    if (!organ.hasOwnProperty(prop)) {
      organ[prop] = defaultValue;
      return;
    }
    if (compareValues.includes(organ?.[prop])) organ[prop] = defaultValue;
  };

  const checkForOARVariations = (oar: any): boolean => {
    if (!data || !templateOars?.organAtRiskTemplateValuesVariation || !oar) return false;

    const templateId = templateOars.organAtRiskTemplateValuesVariation.filter((item: any) => Boolean(item.templateId));

    if (templateId.length) {
      return true;
    }

    // Priotisation not to be included in the comparison
    const oarForCompare = lodash.omit(oar, ['prioritized', 'index']);

    const templateOrgan = lodash.omit(
      lodash.clone(
        templateOars.organAtRiskTemplateValuesVariation.find(
          // eslint-disable-next-line eqeqeq
          (item: any) => item.organ == oar.organ,
        ),
      ),
      ['templateId', 'prioritized'],
    );

    if (templateOrgan) {
      // TODO review and explain why what this set function is for
      setOrganDefaultValue(templateOrgan, 'alara', false, []);
      setOrganDefaultValue(oarForCompare, 'alara', false, []);
      setOrganDefaultValue(templateOrgan, 'meanValue', [], [null]);
      setOrganDefaultValue(oarForCompare, 'meanValue', [], [null]);
      setOrganDefaultValue(templateOrgan, 'meanValueUnit', doseUnit, [null]);
      setOrganDefaultValue(oarForCompare, 'meanValueUnit', doseUnit, [null]);
      setOrganDefaultValue(templateOrgan, 'maxValue', [], [null]);
      setOrganDefaultValue(oarForCompare, 'maxValue', [], [null]);
      setOrganDefaultValue(templateOrgan, 'maxValueUnit', doseUnit, [null]);
      setOrganDefaultValue(oarForCompare, 'maxValueUnit', doseUnit, [null]);
      setOrganDefaultValue(templateOrgan, 'volumeConstraintData', [], [null]);
      setOrganDefaultValue(oarForCompare, 'volumeConstraintData', [], [null]);
      setOrganDefaultValue(templateOrgan, 'dConstraintData', [], [null]);
      setOrganDefaultValue(oarForCompare, 'dConstraintData', [], [null]);

      return JSON.stringify(templateOrgan) !== JSON.stringify(oarForCompare);
    }
    return false;
  };

  const openModal = (): void => setModalShow(true);

  const hidePopup = (): void => {
    setModalShow(false);
    setEditOarIndex(-1);
    setOarModalData(null);
  };

  const reloadPopup = () => {
    setOarModalData(data.roOrganAtRisk[editOarIndex]);
  };

  const openAddModal = (organsInUse: any): JSX.Element => {
    return (
      <OrgansAtRiskPopup
        isVisible={modalShow}
        hidePopup={hidePopup}
        popup={openModal}
        siteGroupId={props.siteGroupId}
        careplanId={props.careplanId}
        organsInUse={organsInUse}
      />
    );
  };

  const openEditModal = (organsInUse: any, index: number): JSX.Element => {
    return (
      <OrgansAtRiskPopup
        isVisible={modalShow}
        hidePopup={hidePopup}
        popup={openModal}
        isEdit={true}
        reload={reloadPopup}
        siteGroupId={props.siteGroupId}
        careplanId={props.careplanId}
        index={index}
        data={oarModalData}
        organsInUse={organsInUse}
      />
    );
  };

  const [deleteOARMutation] = useMutation(DELETE_OAR, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: OAR_QUERY,
        variables: { siteGroupId: props.siteGroupId },
      },
      {
        query: SITE_GROUP_TEMPLATE_VALUE_VARIATION,
        variables: { siteGroupId: props.siteGroupId },
      },
      {
        query: SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
        variables: { siteGroupId: props.siteGroupId, volumingPage: true },
      },
      { query: GET_CAREPLAN, variables: { id: props.careplanId } },
    ],
  });

  const deleteOAR = (index: number): void => {
    deleteOARMutation({
      variables: { siteGroupId: props.siteGroupId, index: index },
    });
  };

  if (loading) return <div>Loading...</div>;
  if (!data) return <div>Error loading...</div>;

  const organsInUse = data.roOrganAtRisk.map((oar: OAR) => oar.organ);

  const editPopup = (index: number): void => {
    setModalShow(true);
    setEditOarIndex(index);
    setOarModalData(data.roOrganAtRisk[index]);
  };

  const getConstraints = (item: any): string[] => {
    let constraints: string[] = [];
    if (item.volumeConstraintData)
      constraints = [
        ...item.volumeConstraintData.map(
          (vcd: any): string =>
            `V${decimalFormatter(vcd.firstValue) || ' '}${vcd.firstValueUnit} ${vcd.operator} ${
              (vcd.secondValue && vcd.secondValue.join('/')) || ' '
            }${vcd.secondValueUnit}`,
        ),
      ];
    if (item.dConstraintData)
      constraints = [
        ...constraints,
        ...item.dConstraintData.map(
          (dcd: any): string =>
            `${dcd.dosetoPrefixOperator || 'D'}${decimalFormatter(dcd.firstValue) || ' '}${dcd.firstValueUnit} ${
              dcd.operator
            } ${(dcd.secondValue && dcd.secondValue.join('/')) || ' '}${dcd.secondValueUnit}`,
        ),
      ];

    if (item.maxValue && item.maxValue.length) {
      constraints = [...constraints, `Max ≤ ${(item.maxValue && item.maxValue.join('/')) || ' '}${item.maxValueUnit}`];
    }
    if (item.meanValue && item.meanValue.length) {
      constraints = [
        ...constraints,
        `Mean ≤ ${(item.meanValue && item.meanValue.join('/')) || ' '}${item.meanValueUnit}`,
      ];
    }
    if (item.alara) constraints = ['ALARA'];

    return constraints;
  };

  return (
    <Fragment>
      {checkForOganListVariations() && (
        <Stack direction="row" gap={1} alignItems="center" marginBottom={2}>
          <ErrorOutline color="warning" fontSize="small" />
          <Typography>OAR(s) have varied from template</Typography>
        </Stack>
      )}
      <Stack direction="column">
        {oarMap.priority && oarMap.nonPriority && (
          <DragDropContext onDragEnd={handleDrag}>
            <Stack gap={2} data-test-id="voluming-oar-table" maxWidth="677px">
              {['priority', 'nonPriority'].map((dropzone: string) => (
                <Droppable droppableId={dropzone} key={dropzone}>
                  {(dropProvided: DroppableProvided) => (
                    <Stack
                      padding={1}
                      borderColor={theme.palette.primary.main}
                      bgcolor={theme.palette.background.secondary}
                      ref={dropProvided.innerRef}
                      {...dropProvided.droppableProps}>
                      {dropzone === 'priority' && (
                        <>
                          <Typography variant="subtitle2">Prioritized</Typography>
                          {oarMap.priority.length === 0 && (
                            <Typography color="text.secondary" variant="body2">
                              Drag and drop OARs here to prioritize (optional)
                            </Typography>
                          )}
                        </>
                      )}

                      {oarMap[dropzone].map((oar: OAR, index: number): JSX.Element => {
                        return (
                          <PrioritisedOrgansAtRiskRow
                            key={oar.organ}
                            oar={oar}
                            onEdit={(): void => editPopup(oar.index)}
                            constraints={getConstraints(oar)}
                            siteGroupId={props.siteGroupId}
                            careplanId={props.careplanId}
                            index={index}
                            variations={checkForOARVariations(oar)}
                            onDelete={() => {
                              deleteOAR(oar.index);
                            }}
                          />
                        );
                      })}
                      {dropProvided.placeholder}
                      {dropzone !== 'priority' && (
                        <Stack alignItems={'start'} marginTop={1}>
                          <Button
                            variant="text"
                            color="primary"
                            startIcon={<AddCircleOutline color="primary" />}
                            onClick={openModal}>
                            Add OAR
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Droppable>
              ))}
            </Stack>
            {modalShow && (oarModalData ? openEditModal(organsInUse, editOarIndex) : openAddModal(organsInUse))}
          </DragDropContext>
        )}
      </Stack>
    </Fragment>
  );
};

export default OrgansAtRiskModule;
