import { Box, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import FilterList from '@mui/icons-material/FilterList';
import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)`
  :focus {
    outline: none;
  }
  && {
    text-transform: none;
    color: ${(props) => props.theme.palette.text.primary};
    border-color: ${(props) => props.theme.palette.grey[600]};
    min-width: 100px;
    border-radius: 2em;
    min-height: 2em;
    margin-right: 12px;
    font-size: 16px;
    border-width: 2px;
  }
`;

const StyledSvg = styled('svg')`
  margin-top: -15px;
  margin-left: -30px;
  margin-right: 15px;
}
`;

const StyledClose = styled('div')`
  cursor: pointer;
`;

const StyledMenu = (props: MenuProps) => (
  <Menu
    elevation={2}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
);

interface Props {
  children: any;
  showAlert: boolean;
}

const SearchFilter = (props: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const greenCircle = (
    <StyledSvg className="notification-green-circle" height="15" width="15">
      <circle cx="7.5" cy="7.5" r="7.5" fill={theme.palette.success.dark} />
    </StyledSvg>
  );

  return (
    <div>
      <StyledButton
        className="search-filter-button"
        aria-controls="customized-menu"
        aria-haspopup="true"
        data-testid="search-filter-button"
        startIcon={<FilterList />}
        onClick={handleClick}>
        Filter
      </StyledButton>
      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <Box display="flex" flexDirection="row-reverse" mx={1}>
          <StyledClose data-test-id="close-filter" onClick={handleClose}>
            <CloseIcon color="primary" />
          </StyledClose>
        </Box>
        {props.children}
      </StyledMenu>
      {props.showAlert && greenCircle}
    </div>
  );
};

export default SearchFilter;
