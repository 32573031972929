import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { CareplanBanners } from '../../Banner/Banner';
import CareplanContainer from '../../Container';
import ROPatientCarePlanPageFooter from '../../Footer';
import { logPage } from 'shared-components/utils';
import { Logger } from 'shared-components/utils';
import dayjs from 'dayjs';
import {
  DiagnosisData,
  DiagnosisVariables,
  DiseaseGroupVariables,
  StagingFieldsAJCC,
  TnmStageType,
  ConvertedDiagnosisType,
} from '../Interface';
import {
  isFieldLowCertainty,
  LOW_CERTAINTY,
  ClinexTooltip,
  stagingClinexMappings,
  selectFirstHighlight,
  PLEASE_REVIEW_CLINEX,
  getClinexTooltipText,
} from '../Clinex/helpers';
import { GET_CAREPLAN } from '../../Queries';
import {
  GET_DIAGNOSIS,
  CHECK_DIAGNOSIS_CAREPLAN_STATUS,
  GET_DISEASES,
  LOG_CLINEX_MUTATION,
  GET_MORPHOLOGY_LIST_BY_DISEASE,
  GET_TNM_LIST_BY_DISEASE,
  UPDATE_DIAGNOSIS,
} from '../Queries';
import { ClinexContext } from '../Clinex/context';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { DiagnosisRouterParams, DiseaseData } from '../Interface';
import classNames from 'classnames';
import { useErrorModalContext } from 'op-contexts';
import { LoadingSpinner } from 'shared-components/components';
import { generateValidationSchema } from './validation';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  VALUE_REQUIRED,
  convertMorphologyValuesToOptions,
  fieldsToClearMap,
  getTNMSubCode,
  getTNMDescription,
  calculateStageType,
  convertTypeForStagingPage,
  convertDisease,
} from './Helper';
import { LOAD_CAREPLAN_LIST_DATA } from '../../ListData/ListQueries';
import { filterListdata } from '../../ListData/ListData';
import { CurrentAppConfig } from '../../AppConfig';
import {
  FilteredListDropdown,
  FilteredListOption,
} from 'shared-components/components/FormFields/ROFilteredDropdown/FilteredListDropdown';
import { CAREPLAN_PAGES } from '../../Constants';
import { getOptionByValue, codeToIntakePageMapping } from '../Utils';
import {
  ROTextField,
  RODatePicker,
  ROAutocomplete,
  FormRow,
  ROHelperText,
} from 'shared-components/components/FormFields';
import { SEARCH_DROPDOWN_PLACEHOLDER } from 'shared-components/components/FormFields/common';
import { logMissingDiagnosisCode } from '../../Common';
import { MorphologyType } from '../Interface';
import ROPatientCarePlanContext from '../../ROPatientCarePlanContext';
import { ChildSectionWrapper } from 'shared-components/components/FormFields/common';
import { DateValidationError } from '@mui/x-date-pickers';
import FormGroup from '@mui/material/FormGroup';
import { Dayjs } from 'dayjs';
import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { SelectOptionType } from 'shared-components/components/FormFields';
import ClinexFieldWrapper from '../Clinex/ClinexFieldWrapper';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';
import { WarningOutlined as WarningOutlinedIcon } from '@mui/icons-material';
const logger = new Logger('StagingPage.tsx');

interface StagingPageProps {
  onDataRefresh: any;
}

export const scrollToClassName = (className: string, block?: any) => {
  if (!className) return;
  const pageElement = document.getElementsByClassName(className);
  if (pageElement) {
    // dateMui prevents all error scroll-tos after being opened.
    setTimeout(() => {
      pageElement[0]?.scrollIntoView({
        block: block ? block : 'nearest',
        behavior: 'smooth',
        inline: 'start',
      });
    }, 0);
  }
};

const ROPatientDiagnosisStagingPage = (props: StagingPageProps): JSX.Element => {
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width < 1900;
  const history = useHistory();
  const { setError } = useErrorModalContext();
  const match = useRouteMatch<DiagnosisRouterParams>();
  const { diagnosisId, careplanId, id: patientId, oncologyType } = match.params;
  const { onDataRefresh } = props;
  const clinexContexts = useContext(ClinexContext);
  const { clinexData, uuid } = clinexContexts;
  const theme = useTheme();
  const [logEvent] = useMutation(LOG_CLINEX_MUTATION);
  const [primaryDiagnosis, setPrimaryDiagnosis] = useState<ConvertedDiagnosisType>();
  const [metastasisDiagnosis, setMetastasisDiagnosis] = useState<ConvertedDiagnosisType>();
  const [isNewPrimaryDiagnosis, setIsNewPrimaryDiagnosis] = useState<boolean>(false);
  const [missingDiagnosisCode, setMissingDiagnosisCode] = useState<boolean>(false);
  const [careplanDiagnosis, setCareplanDiagnosis] = useState<string>(diagnosisId);
  const [ajccMajorVersion, setAjccMajorVersion] = useState<number>(8);
  const [lateralityOptions, setLateralityOptions] = useState<SelectOptionType[]>([]);
  // TODO these should be refs not state, we're not rendering anything
  const [tStageType, setTStageType] = useState<string>('t_stage_clinical');
  const [nStageType, setNStageType] = useState<string>('n_stage_clinical');
  const [mStageType, setMStageType] = useState<string>('m_stage_clinical');
  const startTime = useRef<number>(new Date().getTime());
  const [backButtonURL, setBackButtonURL] = useState<any>(null);
  const [continueButtonURL, setContinueButtonURL] = useState<any>(null);
  const { state: careplanState } = useContext(ROPatientCarePlanContext);
  const [morphologyOptions, setMorphologyOptions] = useState<SelectOptionType[]>([]);
  const [tStageOptions, setTStageOptions] = useState<FilteredListOption[]>([]);
  const [nStageOptions, setNStageOptions] = useState<FilteredListOption[]>([]);
  const [mStageOptions, setMStageOptions] = useState<FilteredListOption[]>([]);

  const [diagnosisDateError, setDiagnosisDateError] = useState<DateValidationError | null>(null);
  const [metastasisDiagnosisDateError, setMetastasisDiagnosisDateError] = useState<DateValidationError | null>(null);
  const BASE_CAREPLAN_URL = `/${oncologyType}/patient/${patientId}/careplan/${careplanId}`;
  const {
    data: diagnosisData,
    loading: diagnosisLoading,
    error: diagnosisError,
    refetch: refetchDiagnosis,
  } = useQuery<DiagnosisData, DiagnosisVariables>(GET_DIAGNOSIS, {
    variables: { diagnosisId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      logMissingDiagnosisCode(logger, data, 'Staging');
    },
  });

  useQuery(CHECK_DIAGNOSIS_CAREPLAN_STATUS, {
    variables: { diagnosisId, careplanId },
    fetchPolicy: 'network-only',
    skip: !diagnosisId || !careplanId,
    onCompleted: (data) => {
      const { status, id } = data?.checkDiagnosisToCareplanStatus;
      if (!status) {
        setCareplanDiagnosis(id);
        setMissingDiagnosisCode(true);
      }
    },
  });

  const {
    data: careplanData,
    loading: careplanLoading,
    error: careplanError,
  } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });

  const [updateDiagnosis] = useMutation(UPDATE_DIAGNOSIS, {
    refetchQueries: [
      { query: GET_DIAGNOSIS, variables: { diagnosisId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onDataRefresh();
    },
  });

  const updateDiagnosisWrapper = (diag: ConvertedDiagnosisType, isMetastasis = false): void => {
    updateDiagnosis({
      variables: {
        id: diag.id,
        disease: diag?.disease?.value || '',
        morphology: diag.morphology?.value || '',
        stageTCodePathological: diag.stageTCodePathological?.value || '',
        stageNCodePathological: diag.stageNCodePathological?.value || '',
        stageMCodePathological: diag.stageMCodePathological?.value || '',
        stageTCodeClinical: diag.stageTCodeClinical?.value || '',
        stageNCodeClinical: diag.stageNCodeClinical?.value || '',
        stageMCodeClinical: diag.stageMCodeClinical?.value || '',
        stageType: isMetastasis ? '' : calculateStageType(diag),
        laterality: diag.laterality?.value || '',
        diagnosisDate: diag.diagnosisDate,
        otherInfo: diag.otherInfo || '',
        majorVersion: ajccMajorVersion,
      },
    });
  };

  const {
    loading: morphologyOptionsLoading,
    error: morphologyOptionsError,
    refetch: refetchMorphologyList,
  } = useQuery(GET_MORPHOLOGY_LIST_BY_DISEASE, {
    variables: {
      diseaseId: primaryDiagnosis?.disease?.value,
      majorVersion: ajccMajorVersion,
    },
    skip: !primaryDiagnosis?.disease?.value || !ajccMajorVersion,
    fetchPolicy: 'cache-first',
    onCompleted: (data: { morphologyListByDisease: MorphologyType[] }): void => {
      if (data?.morphologyListByDisease) {
        setMorphologyOptions(convertMorphologyValuesToOptions(data.morphologyListByDisease));
      }
    },
  });

  useEffect(() => {
    if (clinexData && !isSmallDevice) {
      selectFirstHighlight(stagingClinexMappings, clinexContexts);
    }
  }, [clinexData]);

  useEffect(() => {
    if (primaryDiagnosis?.disease?.value && ajccMajorVersion) {
      refetchMorphologyList();
      refetchTNMList();
    }
  }, [primaryDiagnosis?.disease]);

  const { data: mostRelevantDiseasesData, loading: mostRelevantDataloading } = useQuery<
    DiseaseData,
    DiseaseGroupVariables
  >(GET_DISEASES, {
    variables: {
      ajccMajorVersion: ajccMajorVersion,
      relatedIcdCode: primaryDiagnosis?.diagnosisCode?.diagnosisCode,
    },
    skip: !primaryDiagnosis || !careplanData,
  });

  const { data: diseaseData } = useQuery<DiseaseData, DiseaseGroupVariables>(GET_DISEASES, {
    variables: {
      ajccMajorVersion: ajccMajorVersion,
    },
    skip: !primaryDiagnosis || !careplanData,
  });

  const getStageOptions = (tnmList: any[], stage: string) => {
    /// Arranges the list to have pathological first so that default filter selects pathological
    return [
      ...tnmList
        .filter((option: TnmStageType) => option.mainCode[1] === stage && option.mainCode[0] === 'p')
        .map((option: TnmStageType) => ({
          value: `${option.mainCode[0]}${option.subCode}`,
          label: `${option.mainCode[0]}${option.subCode} - ${option.description}`,
          category: 'Pathological',
        })),
      ...tnmList
        .filter((option: TnmStageType) => option.mainCode[1] === stage && option.mainCode[0] === 'c')
        .map((option: TnmStageType) => ({
          value: `${option.mainCode[0]}${option.subCode}`,
          label: `${option.mainCode[0]}${option.subCode} - ${option.description}`,
          category: 'Clinical',
        })),
    ];
  };

  const {
    loading: tnmOptionsLoading,
    error: tnmOptionsError,
    refetch: refetchTNMList,
  } = useQuery(GET_TNM_LIST_BY_DISEASE, {
    variables: {
      diseaseId: primaryDiagnosis?.disease?.value,
      majorVersion: ajccMajorVersion,
    },
    skip: !primaryDiagnosis?.disease?.value || !ajccMajorVersion,
    onCompleted: (data: any): void => {
      if (data?.tnmListByDisease) {
        const tnmList = data.tnmListByDisease;
        setTStageOptions(getStageOptions(tnmList, 'T'));
        setNStageOptions(getStageOptions(tnmList, 'N'));
        setMStageOptions(getStageOptions(tnmList, 'M'));
      }
    },
  });

  useEffect(() => {
    if (careplanError || diagnosisError || morphologyOptionsError || tnmOptionsError) {
      return setError();
    }
  }, [careplanError, diagnosisError, morphologyOptionsError, tnmOptionsError]);

  useEffect(() => {
    if (diagnosisData && diagnosisData.diagnosis) {
      const primaryDiagnosis = convertTypeForStagingPage(
        diagnosisData.diagnosis.isPrimaryDiagnosis
          ? diagnosisData.diagnosis
          : diagnosisData.diagnosis.relatedPrimaryDiagnosis,
      );
      const metastasisDiagnosis = convertTypeForStagingPage(
        diagnosisData.diagnosis.isPrimaryDiagnosis ? undefined : diagnosisData?.diagnosis,
      );

      setPrimaryDiagnosis(primaryDiagnosis);
      setMetastasisDiagnosis(metastasisDiagnosis);
      setIsNewPrimaryDiagnosis(metastasisDiagnosis?.relatedPrimaryDiagnosis?.draft !== false);
      setAjccMajorVersion(parseInt(diagnosisData.diagnosis.ajccMajorVersion));
    }
  }, [diagnosisData]);

  const getFieldData = () => {
    if (primaryDiagnosis) {
      let metsData = {};
      if (metastasisDiagnosis) {
        metsData = {
          metastasisLaterality: metastasisDiagnosis.laterality?.value,
          metastasisDiagnosisDate: metastasisDiagnosis.diagnosisDate,
          metastasisDiagnosis: metastasisDiagnosis.diagnosisCode?.diagnosisCode,
        };
      }
      return {
        // If no selection for disease has been made don't send in payload
        diseaseGroup: primaryDiagnosis.disease
          ? {
              selection_method:
                // clinex data may be undefined
                // ajcc_disease may be undefined (e.g. C44.7 has no associated disease groups)
                clinexData?.ajcc_disease?.value === primaryDiagnosis.disease.value
                  ? 'Clinex'
                  : primaryDiagnosis.disease.category,
              selection_value: primaryDiagnosis.disease.value,
              most_relevant_options: mostRelevantDiseasesData?.diseases?.map((disease) => disease.diseaseId),
            }
          : undefined,
        morphology: primaryDiagnosis.morphology?.value,
        stageType: primaryDiagnosis.stageType,
        stageTCodePathologicalSubcode: getTNMSubCode(primaryDiagnosis.stageTCodePathological),
        stageTCodePathologicalDescription: getTNMDescription(primaryDiagnosis.stageTCodePathological),
        stageNCodePathologicalSubcode: getTNMSubCode(primaryDiagnosis.stageNCodePathological),
        stageNCodePathologicalDescription: getTNMDescription(primaryDiagnosis.stageNCodePathological),
        stageMCodePathologicalSubcode: getTNMSubCode(primaryDiagnosis.stageMCodePathological),
        stageMCodePathologicalDescription: getTNMDescription(primaryDiagnosis.stageMCodePathological),
        stageTCodeClinicalSubcode: getTNMSubCode(primaryDiagnosis.stageTCodeClinical),
        stageTCodeClinicalDescription: getTNMDescription(primaryDiagnosis.stageTCodeClinical),
        stageNCodeClinicalSubcode: getTNMSubCode(primaryDiagnosis.stageNCodeClinical),
        stageNCodeClinicalDescription: getTNMDescription(primaryDiagnosis.stageNCodeClinical),
        stageMCodeClinicalSubcode: getTNMSubCode(primaryDiagnosis.stageMCodeClinical),
        stageMCodeClinicalDescription: getTNMDescription(primaryDiagnosis.stageMCodeClinical),
        primaryLaterality: primaryDiagnosis.laterality?.value,
        primaryDiagnosisDate: primaryDiagnosis.diagnosisDate,
        otherInfo: primaryDiagnosis.otherInfo,
        ...metsData,
      };
    }
  };

  useQuery(LOAD_CAREPLAN_LIST_DATA, {
    variables: { listCategory: 'lateralityDiagnosis', patientId },
    onCompleted: (data: any): void => {
      setLateralityOptions(filterListdata(data, 'lateralityDiagnosis'));
    },
  });

  useEffect(() => {
    if (clinexData && !isSmallDevice) {
      selectFirstHighlight(stagingClinexMappings, clinexContexts);
    }
  }, [clinexData]);

  useEffect(() => {
    if (patientId && careplanId && diagnosisId) {
      const urlDiagnosisId = careplanDiagnosis;
      if (primaryDiagnosis?.diagnosisId) {
        setBackButtonURL(`${BASE_CAREPLAN_URL}/diagnosis/${urlDiagnosisId}/primary`);
        setContinueButtonURL(`${BASE_CAREPLAN_URL}/${CAREPLAN_PAGES.TREATMENT_INFO}`);
      } else {
        setBackButtonURL(`${BASE_CAREPLAN_URL}/diagnosis/${urlDiagnosisId}/primary/newPrimary`);
        if (primaryDiagnosis?.diagnosisCode) {
          if (
            codeToIntakePageMapping(primaryDiagnosis.diagnosisCode.diagnosisCode, careplanState.intakePageMapping) &&
            careplanData?.careplan?.isPrimDiagnosisOwner
          ) {
            setContinueButtonURL(`${BASE_CAREPLAN_URL}/diagnosis/${urlDiagnosisId}/${CAREPLAN_PAGES.INTAKE}`);
          } else {
            setContinueButtonURL(`${BASE_CAREPLAN_URL}/${CAREPLAN_PAGES.TREATMENT_INFO}`);
          }
        }
      }
    }
  }, [primaryDiagnosis, patientId, careplanId, diagnosisId, careplanData]);

  if (diagnosisLoading || careplanLoading || !primaryDiagnosis || mostRelevantDataloading)
    return <LoadingSpinner loadingText={'Loading Staging'} subtitle={'Please wait while we set things up for you'} />;

  const primaryStagingRequiredFields =
    oncologyType === 'radiation'
      ? CurrentAppConfig.QuestionValidationChildren.Staging.Primary.RO
      : CurrentAppConfig.QuestionValidationChildren.Staging.Primary.MO;
  const metastasisStagingRequiredFields =
    oncologyType === 'radiation'
      ? CurrentAppConfig.QuestionValidationChildren.Staging.Metastasis.RO
      : CurrentAppConfig.QuestionValidationChildren.Staging.Metastasis.MO;
  const isPrimaryDiagnosisDateRequired = primaryStagingRequiredFields.includes('diagnosisDate');
  const isMetastasisDiagnosisDateRequired = metastasisStagingRequiredFields.includes('diagnosisDate');
  const isPrimaryLateralityRequired = primaryStagingRequiredFields.includes('laterality');
  const isMetastasisLateralityRequired = metastasisStagingRequiredFields.includes('laterality');
  // if there only one most relevant disease, set disease group to this value
  // only do this if not using existing primary diagnosis
  if (
    isNewPrimaryDiagnosis &&
    primaryDiagnosis?.disease === undefined &&
    mostRelevantDiseasesData?.diseases?.length === 1
  ) {
    const defaultDisease = convertDisease(mostRelevantDiseasesData.diseases[0]);
    if (defaultDisease) {
      defaultDisease.category = 'Most Relevant';
      setPrimaryDiagnosis({ ...primaryDiagnosis, disease: defaultDisease });
      updateDiagnosisWrapper({ ...primaryDiagnosis, disease: defaultDisease });
    }
  }

  const initialValues: StagingFieldsAJCC = {
    disease: primaryDiagnosis?.disease,
    morphology: getOptionByValue(
      morphologyOptions.map((data) => ({ label: data.label, value: data.value })),
      primaryDiagnosis?.morphology?.value,
    ),
    stageTCodeClinical: primaryDiagnosis?.stageTCodeClinical,
    stageTCodePathological: primaryDiagnosis?.stageTCodePathological,
    stageNCodeClinical: primaryDiagnosis?.stageNCodeClinical,
    stageNCodePathological: primaryDiagnosis?.stageNCodePathological,
    stageMCodeClinical: primaryDiagnosis?.stageMCodeClinical,
    stageMCodePathological: primaryDiagnosis?.stageMCodePathological,
    laterality: getOptionByValue(
      lateralityOptions.map((data) => ({ label: data.label, value: data.value })),
      primaryDiagnosis?.laterality?.value || null,
    ),
    diagnosisDate: primaryDiagnosis?.diagnosisDate,
    otherInfo: primaryDiagnosis?.otherInfo,
    metastasisLaterality: getOptionByValue(
      lateralityOptions.map((data) => ({ label: data.label, value: data.value })),
      metastasisDiagnosis?.laterality?.value || null,
    ),
    metastasisDiagnosisDate: metastasisDiagnosis?.diagnosisDate,
  };

  const fixedWidth = clinexData && !isSmallDevice ? '420px' : '526px';
  return (
    <Fragment>
      <Modal
        width="small"
        id="diagnosis-error-modal"
        open={missingDiagnosisCode}
        PaperProps={{ style: { maxWidth: '500px' } }}>
        <ModalHeader sx={{ padding: '16px' }}>
          <WarningOutlinedIcon color="warning" />
          <Typography
            variant="h6"
            sx={{
              color: 'text.primary',
              paddingLeft: '8px',
              lineHeight: '24px',
            }}>
            Diagnosis code error
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Typography
            variant="subtitle1"
            sx={{
              color: 'text.primary',
            }}>
            Please review the selected diagnosis code
          </Typography>
        </ModalBody>
        <ModalFooter
          sx={{
            margin: '0 16px 16px 0',
          }}>
          <Button
            mode="outlined"
            size="auto"
            onClick={() => history.push(backButtonURL)}
            data-cy="5g-exceeded-fraction-adjust-button">
            Review diagnosis selection
          </Button>
        </ModalFooter>
      </Modal>
      <div
        className={classNames('main-container-wrapper', {
          'clinex-wrapper': clinexData && !isSmallDevice,
        })}
        style={{ flexGrow: clinexData ? 0 : 1 }}>
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          validate={(values: FormikValues) =>
            generateValidationSchema(oncologyType, values, metastasisDiagnosis, primaryDiagnosis)
          }>
          {({
            values,
            errors,
            submitForm,
            submitCount,
            setFieldValue,
            validateForm,
          }: FormikProps<StagingFieldsAJCC>) => {
            const footerProps: any = {
              onBack: (): void => {
                history.push(backButtonURL);
              },
              backDisabled: !backButtonURL || (primaryDiagnosis && primaryDiagnosis.hasTreatmentStarted),
              onNext: (): void => {
                submitForm();
                validateForm().then((error: any) => {
                  if (Object.keys(error).length === 0 && !diagnosisDateError && !metastasisDiagnosisDateError) {
                    history.push(continueButtonURL);
                  }
                });

                const pageData = {
                  diagnosisId: diagnosisId,
                  fields: getFieldData(),
                  isPrimaryDiagnosis: metastasisDiagnosis
                    ? metastasisDiagnosis.isPrimaryDiagnosis
                    : primaryDiagnosis?.isPrimaryDiagnosis,
                  relatedPrimaryDiagnosis: metastasisDiagnosis
                    ? metastasisDiagnosis?.relatedPrimaryDiagnosis?.diagnosisCode?.diagnosisCode
                    : '',
                  tumourStream: metastasisDiagnosis
                    ? metastasisDiagnosis?.diagnosisCode?.tumourStream?.name
                    : primaryDiagnosis?.diagnosisCode?.tumourStream?.name,
                  patientId: patientId,
                  page: CAREPLAN_PAGES.STAGING,
                  ajcc_major_version: ajccMajorVersion,
                  careplanId: careplanId,
                  clinexResponseUuid: uuid,
                };
                logPage(startTime?.current, pageData, logEvent);
              },
            };

            if (submitCount > 0 && errors) {
              const errorElems = document.getElementsByClassName('icon-form-error');
              if (errorElems && errorElems.length > 0) {
                scrollToClassName('icon-form-error');
              }
            }

            const updateField = (fieldName: string, fieldValue: FilteredListOption | string) => {
              const specificDiagnosis = {};
              const metsFieldMapping = {
                metastasisLaterality: 'laterality',
                metastasisDiagnosisDate: 'diagnosisDate',
              };
              //@ts-ignore
              const fieldToUpdate = fieldName.includes('metastasis') ? metsFieldMapping[fieldName] : fieldName;
              //@ts-ignore
              specificDiagnosis[fieldToUpdate] = fieldValue ?? '';
              //@ts-ignore
              const fieldsToClear = fieldsToClearMap[fieldName];
              if (fieldsToClear) {
                fieldsToClear.forEach((field: string) => {
                  //@ts-ignore
                  specificDiagnosis[field] = '';
                  setFieldValue(field, '');
                });
              }
              if (fieldName.includes('metastasis')) {
                const newMets: ConvertedDiagnosisType = { ...metastasisDiagnosis, ...specificDiagnosis };
                setMetastasisDiagnosis(newMets);
                updateDiagnosisWrapper(newMets, true);
              } else {
                const newPrimary: ConvertedDiagnosisType = { ...primaryDiagnosis, ...specificDiagnosis };
                setPrimaryDiagnosis(newPrimary);
                updateDiagnosisWrapper(newPrimary);
              }
              setFieldValue(fieldName, fieldValue);
            };

            function getDiseaseGroupOptions(): FilteredListOption[] | undefined {
              const mostRelevantOptions: FilteredListOption[] | undefined = mostRelevantDiseasesData?.diseases?.map(
                (disease) => {
                  return {
                    value: disease.diseaseId,
                    label: disease.title,
                    category: 'Most Relevant',
                  };
                },
              );
              const diseaseOptions: FilteredListOption[] | undefined = diseaseData?.diseases?.map((disease) => {
                return {
                  value: disease.diseaseId,
                  label: disease.title,
                  category: disease.chapter,
                };
              });
              return [...(mostRelevantOptions || []), ...(diseaseOptions || [])].flat();
            }

            function updateTNM(
              tnmStage: string,
              selectedOption: FilteredListOption,
              setStageType: (value: React.SetStateAction<string>) => void,
            ) {
              const type = selectedOption
                ? selectedOption.category
                : //@ts-ignore
                  values[`stage${tnmStage.toUpperCase()}CodePathological`]?.category || 'Clinical';
              setStageType(`${tnmStage.toLowerCase()}_stage_${type.toLowerCase()}`);
              updateField(`stage${tnmStage.toUpperCase()}Code${type}`, selectedOption);
            }
            return (
              <>
                <CareplanBanners
                  data={careplanData}
                  forceUpdate={() => {
                    refetchDiagnosis();
                  }}
                  refetchQueriesList={[
                    { query: GET_DIAGNOSIS, variables: { diagnosisId } },
                    { query: GET_CAREPLAN, variables: { id: careplanId } },
                  ]}
                />
                <CareplanContainer>
                  <Typography variant="h5" data-testid="title">
                    Staging
                  </Typography>
                  {clinexData ? (
                    PLEASE_REVIEW_CLINEX
                  ) : (
                    <Typography
                      color={theme.palette.text.secondary}
                      paddingTop={1}
                      variant="body1"
                      data-testid="subtitle">
                      Please enter staging information.
                    </Typography>
                  )}
                  {primaryDiagnosis && isNewPrimaryDiagnosis && (
                    <div>
                      <Typography paddingTop={2} variant="h6" data-testid="sectionTitle">
                        Primary Diagnosis
                      </Typography>
                      <ClinexFieldWrapper field="ajcc_disease" ajccVersion={ajccMajorVersion}>
                        <FormRow id="row-disease" fieldlabel="Disease Group" alignItems="center">
                          <Stack sx={{ width: fixedWidth }} gap="4px">
                            <FilteredListDropdown
                              id="disease"
                              options={getDiseaseGroupOptions()}
                              selectedOption={values.disease}
                              onChange={(selectedOption: any) => {
                                updateField('disease', selectedOption);
                              }}
                              hasError={Boolean(submitCount > 0 && errors.disease)}
                              hasWarning={isFieldLowCertainty(clinexData, 'ajcc_disease', primaryDiagnosis)}
                            />
                            <ROHelperText
                              id="disease"
                              warning={isFieldLowCertainty(clinexData, 'ajcc_disease', primaryDiagnosis)}
                              helperText={
                                isFieldLowCertainty(clinexData, 'ajcc_disease', primaryDiagnosis)
                                  ? LOW_CERTAINTY
                                  : undefined
                              }
                            />
                          </Stack>
                        </FormRow>
                      </ClinexFieldWrapper>
                      <Typography
                        paddingBottom={2}
                        color={theme.palette.text.secondary}
                        variant="subtitle1">{`AJCC ${ajccMajorVersion}TH EDITION`}</Typography>
                      <ChildSectionWrapper>
                        <ClinexFieldWrapper field="histology_code" ajccVersion={ajccMajorVersion}>
                          <ROAutocomplete
                            id="morphology"
                            fieldlabel="Morphology"
                            fullWidth
                            sx={{ width: fixedWidth, maxWidth: fixedWidth }}
                            options={morphologyOptions}
                            value={values.morphology || null}
                            onChange={(option: SelectOptionType | string) => {
                              const value = typeof option === 'string' ? { label: option, value: option } : option;
                              updateField('morphology', value);
                            }}
                            disabled={!values.disease || morphologyOptionsLoading}
                            inputProps={{
                              helperText: isFieldLowCertainty(clinexData, 'histology_code', primaryDiagnosis)
                                ? LOW_CERTAINTY
                                : undefined,
                              placeholder: SEARCH_DROPDOWN_PLACEHOLDER,
                              warning: isFieldLowCertainty(clinexData, 'histology_code', primaryDiagnosis),
                            }}
                            placeholder="Start typing or choose from dropdown..."
                          />
                        </ClinexFieldWrapper>
                        <ClinexFieldWrapper field={tStageType} ajccVersion={ajccMajorVersion}>
                          <FormRow id="row-stageTCode" fieldlabel="T Stage" alignItems="center">
                            <Stack sx={{ width: fixedWidth }} gap="4px">
                              <FilteredListDropdown
                                tooltip={getClinexTooltipText(clinexData, tStageType, primaryDiagnosis)}
                                id="stageTCode"
                                options={tStageOptions}
                                selectedOption={values.stageTCodePathological || values.stageTCodeClinical}
                                onChange={(selectedOption: any) => {
                                  updateTNM('t', selectedOption as FilteredListOption, setTStageType);
                                }}
                                isLoading={tnmOptionsLoading}
                                isDisabled={!values.disease}
                                hasError={Boolean(
                                  submitCount > 0 &&
                                    //@ts-ignore
                                    errors[`stageTCode${values.stageTCodePathological?.category || 'Clinical'}`],
                                )}
                                hasWarning={isFieldLowCertainty(clinexData, tStageType, primaryDiagnosis)}
                              />
                              <ROHelperText
                                id="stageTCode"
                                error={
                                  submitCount > 0 &&
                                  //@ts-ignore
                                  errors[`stageTCode${values.stageTCodePathological?.category || 'Clinical'}`]
                                }
                                warning={isFieldLowCertainty(clinexData, tStageType, primaryDiagnosis)}
                                helperText={
                                  isFieldLowCertainty(clinexData, tStageType, primaryDiagnosis)
                                    ? LOW_CERTAINTY
                                    : undefined
                                }
                              />
                            </Stack>
                          </FormRow>
                        </ClinexFieldWrapper>

                        <ClinexFieldWrapper field={nStageType} ajccVersion={ajccMajorVersion}>
                          <FormRow id="row-stageNCode" fieldlabel="N Stage" alignItems="center">
                            <Stack sx={{ width: fixedWidth }} gap="4px">
                              <FilteredListDropdown
                                id="stageNCode"
                                tooltip={getClinexTooltipText(clinexData, nStageType, primaryDiagnosis)}
                                options={nStageOptions}
                                selectedOption={values.stageNCodePathological || values.stageNCodeClinical}
                                onChange={(selectedOption: any) => {
                                  updateTNM('n', selectedOption as FilteredListOption, setNStageType);
                                }}
                                isLoading={tnmOptionsLoading}
                                isDisabled={!values.disease}
                                hasError={Boolean(
                                  submitCount > 0 &&
                                    //@ts-ignore
                                    errors[`stageNCode${values.stageNCodePathological?.category || 'Clinical'}`],
                                )}
                                hasWarning={isFieldLowCertainty(clinexData, nStageType, primaryDiagnosis)}
                              />
                              <ROHelperText
                                id="stageNCode"
                                error={
                                  submitCount > 0 &&
                                  //@ts-ignore
                                  errors[`stageNCode${values.stageTCodePathological?.category || 'Clinical'}`]
                                }
                                warning={isFieldLowCertainty(clinexData, nStageType, primaryDiagnosis)}
                                helperText={
                                  isFieldLowCertainty(clinexData, nStageType, primaryDiagnosis)
                                    ? LOW_CERTAINTY
                                    : undefined
                                }
                              />
                            </Stack>
                          </FormRow>
                        </ClinexFieldWrapper>
                        <ClinexFieldWrapper field={mStageType} ajccVersion={ajccMajorVersion}>
                          <FormRow id="row-stageMCode" fieldlabel="M Stage" alignItems="center">
                            <Stack sx={{ width: fixedWidth }} gap="4px">
                              <FilteredListDropdown
                                tooltip={getClinexTooltipText(clinexData, mStageType, primaryDiagnosis)}
                                defaultSelectedFilter={'Clinical'}
                                id="stageMCode"
                                options={mStageOptions}
                                selectedOption={values.stageMCodePathological || values.stageMCodeClinical}
                                onChange={(selectedOption: any) => {
                                  updateTNM('m', selectedOption as FilteredListOption, setMStageType);
                                }}
                                isLoading={tnmOptionsLoading}
                                isDisabled={!values.disease}
                                hasError={Boolean(
                                  submitCount > 0 &&
                                    //@ts-ignore
                                    errors[`stageMCode${values.stageMCodePathological?.category || 'Clinical'}`],
                                )}
                                hasWarning={isFieldLowCertainty(clinexData, mStageType, primaryDiagnosis)}
                              />
                              <ROHelperText
                                id="stageMCode"
                                error={
                                  submitCount > 0 &&
                                  //@ts-ignore
                                  errors[`stageMCode${values.stageTCodePathological?.category || 'Clinical'}`]
                                }
                                warning={isFieldLowCertainty(clinexData, mStageType, primaryDiagnosis)}
                                helperText={
                                  isFieldLowCertainty(clinexData, mStageType, primaryDiagnosis)
                                    ? LOW_CERTAINTY
                                    : undefined
                                }
                              />
                            </Stack>
                          </FormRow>
                        </ClinexFieldWrapper>
                      </ChildSectionWrapper>
                      <ClinexFieldWrapper field="laterality" ajccVersion={ajccMajorVersion}>
                        <ClinexTooltip clinexData={clinexData} clinexField={'laterality'} formValues={primaryDiagnosis}>
                          <div>
                            <ROAutocomplete
                              id="laterality"
                              fieldlabel="Primary Laterality"
                              required={isPrimaryLateralityRequired}
                              sx={{ width: fixedWidth, maxWidth: fixedWidth }}
                              disableClearable
                              options={lateralityOptions}
                              value={values.laterality}
                              onChange={(option: SelectOptionType | string) => {
                                const value = typeof option === 'string' ? { label: option, value: option } : option;
                                updateField('laterality', value);
                              }}
                              inputProps={{
                                error: !!(submitCount > 0 && errors.laterality),
                                warning: isFieldLowCertainty(clinexData, 'laterality', primaryDiagnosis),
                                helperText: submitCount > 0 && errors.laterality ? VALUE_REQUIRED : LOW_CERTAINTY,
                              }}
                            />
                          </div>
                        </ClinexTooltip>
                      </ClinexFieldWrapper>
                      <ClinexTooltip
                        clinexData={clinexData}
                        clinexField={'diagnosis_date'}
                        formValues={primaryDiagnosis}>
                        <div>
                          <RODatePicker
                            id="diagnosisDate"
                            fieldlabel="Primary Diagnosis Date"
                            error={!!(submitCount > 0 && errors.diagnosisDate)}
                            warning={isFieldLowCertainty(clinexData, 'diagnosis_date', primaryDiagnosis)}
                            helperText={
                              isFieldLowCertainty(clinexData, 'diagnosis_date', primaryDiagnosis)
                                ? LOW_CERTAINTY
                                : undefined
                            }
                            required={isPrimaryDiagnosisDateRequired}
                            value={values.diagnosisDate ? dayjs(values.diagnosisDate) : null}
                            disableFuture
                            onChange={(date: Dayjs | null, context: any) => {
                              if (context.validationError) {
                                setDiagnosisDateError(context.validationError);
                                if (context.validationError === 'maxDate') {
                                  updateField('diagnosisDate', date ? date.format('YYYY-MM-DD').toString() : '');
                                }
                              } else {
                                const dateString = date ? date.format('YYYY-MM-DD').toString() : '';
                                updateField('diagnosisDate', dateString);
                              }
                            }}
                            onError={setDiagnosisDateError}
                          />
                        </div>
                      </ClinexTooltip>
                      <ROTextField
                        id="otherInfo"
                        fieldlabel="Other Information"
                        value={primaryDiagnosis?.otherInfo}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                          setPrimaryDiagnosis({ ...primaryDiagnosis, otherInfo: event.target.value });
                        }}
                        onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
                          setPrimaryDiagnosis({ ...primaryDiagnosis, otherInfo: event.target.value });
                          updateField('otherInfo', event.target.value);
                        }}
                        multiline
                        maxRows={4}
                      />
                    </div>
                  )}
                  {metastasisDiagnosis && (
                    <>
                      <Divider sx={{ padding: '8px 0' }} />
                      <Typography paddingTop={2} paddingBottom={2} variant="h6">
                        Metastasis Diagnosis
                      </Typography>
                      <FormGroup>
                        <ROAutocomplete
                          id="metastasisLaterality"
                          fieldlabel={'Metastasis Laterality'}
                          options={lateralityOptions}
                          value={values.metastasisLaterality}
                          required={isMetastasisLateralityRequired}
                          onChange={(option: SelectOptionType | string) => {
                            const value = typeof option === 'string' ? { label: option, value: option } : option;
                            updateField('metastasisLaterality', value);
                          }}
                          inputProps={{
                            error: submitCount > 0 && errors.metastasisLaterality ? true : false,
                            helperText: VALUE_REQUIRED,
                          }}
                        />
                      </FormGroup>
                      <RODatePicker
                        id="metastasisDiagnosisDate"
                        value={values.metastasisDiagnosisDate ? dayjs(values.metastasisDiagnosisDate.toString()) : null}
                        fieldlabel="Metastasis Diagnosis Date"
                        required={isMetastasisDiagnosisDateRequired}
                        disableFuture
                        error={
                          isMetastasisDiagnosisDateRequired &&
                          Boolean(submitCount > 0 && errors.metastasisDiagnosisDate)
                        }
                        onChange={(date: Dayjs | null, context: any) => {
                          if (context.validationError) {
                            setMetastasisDiagnosisDateError(context.validationError);
                            if (context.validationError === 'maxDate') {
                              updateField('metastasisDiagnosisDate', date ? date.format('YYYY-MM-DD').toString() : '');
                            }
                          } else {
                            const dateString = date ? date.format('YYYY-MM-DD').toString() : '';
                            updateField('metastasisDiagnosisDate', dateString);
                          }
                        }}
                        onError={setMetastasisDiagnosisDateError}
                      />
                    </>
                  )}
                </CareplanContainer>
                <ROPatientCarePlanPageFooter {...footerProps} />
              </>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );
};

export default ROPatientDiagnosisStagingPage;
