import { useEffect, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Divider, Typography } from '@mui/material';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'gc-ui';
import { CACHE_ERROR_MSG_QUERY, GET_CONTENT_SHOWN } from 'op-components/ErrorModalContextProvider/ErrorModalQueries';
import { ErrorModalContext } from 'op-contexts';
import { LOGOUT_MUTATION } from 'op-graphql/Logout';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { Logger, navigateToExternalURL } from 'shared-components/utils';

const logger = new Logger('ROErrorDialog');

interface Props {
  children: React.ReactNode;
}
interface ROErrorDialogInterface {
  title: string;
  body: string;
  btnText: string;
  onClick: () => void;
}

const ErrorModal = (error: any): JSX.Element => {
  const [state, setState] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [isNonBlockingError, setIsNonBlockingError] = useState(false);
  const { data: cacheData } = useQuery(CACHE_ERROR_MSG_QUERY);
  const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);
  const logout = (): void => {
    client.clearStore().then((): void => {
      client.writeQuery({
        query: GET_CONTENT_SHOWN,
        data: {
          contentShown: false,
        },
      });
    });

    logoutMutation({ variables: {} }).then(({ data }): void => {
      if (data.logout.errors !== null) {
        logger.error('logout', ['Unable to logout', data.logout.errors]);
        return;
      }
      navigateToExternalURL('/sso/logout');
    });
  };

  useEffect((): (() => void) => {
    if (cacheData && cacheData.error.statusCode !== -1) setState(true);

    if (cacheData && cacheData.error.statusCode >= 400 && cacheData.error.statusCode < 500) {
      if (error.errorType && error.errorType === 'ACCOUNT') {
        setIsAuthError(true);
      }
    }

    if (error.errorType && error.errorType === 'NON_BLOCKING') {
      setIsNonBlockingError(true);
    }

    return (): void => {
      setState(false);
      setIsAuthError(false);
    };
  }, [cacheData, error]);

  if (state || isAuthError) {
    if (isNonBlockingError) {
      return <></>;
    } else
      return (
        <ROErrorDialog
          title={isAuthError ? 'Account Error' : 'Something went wrong'}
          body={isAuthError ? CurrentAppConfig.ErrorPopup.Practitioner : CurrentAppConfig.ErrorPopup.Generic}
          btnText={isAuthError ? 'Logout' : 'Refresh page'}
          onClick={(): void => {
            if (isAuthError) {
              logout();
            } else {
              window.location.reload();
            }
          }}
        />
      );
  } else return <></>;
};

const ROErrorDialog = (props: ROErrorDialogInterface): JSX.Element => {
  const { title, body, btnText, onClick } = props;

  const componentClass = 'error-dialog-modal';
  return (
    <Modal
      id={componentClass}
      open
      PaperProps={{ style: { minWidth: '520px', lineHeight: '1.4rem', padding: '16px' } }}>
      <ModalHeader>
        <Typography variant="h6">{title}</Typography>
      </ModalHeader>
      <ModalBody>
        {/* body set in config with a link tag */}
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </ModalBody>
      <Divider />
      <ModalFooter className={`${componentClass}-footer`}>
        <Button id={`${componentClass}-submit-btn`} size="small" onClick={onClick} data-test-id={`${btnText}-button`}>
          {btnText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ErrorModalContextProvider = (props: Props): JSX.Element => {
  const [error, setError] = useState('');

  return (
    <ErrorModalContext.Provider value={{ state: { error }, setError }}>
      <ErrorModal errorType={error} />
      {props.children}
    </ErrorModalContext.Provider>
  );
};

export default ErrorModalContextProvider;
