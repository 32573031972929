import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { FormContainer, ModalSubmitForm } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import { FormStatus } from 'op-enums';
import { RELEASE_LOCK_MUTATION } from 'op-graphql';
import { PatientAttachment } from 'op-interfaces';
import { FormContext } from 'op-pages/OP/PatientNavigation/context';
import { registrationAttachments } from 'op-utils';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { LoadingSpinner, Modal } from 'shared-components/components';
import { ErrorInfo } from 'shared-components/components/FormFields';
import { AustralianStates } from 'shared-components/enums';
import { Edit, WarningTriangle } from 'shared-components/images';
import { MEDICARE_REGEX, navigateToExternalURL } from 'shared-components/utils';
import { styled } from '@mui/system';
import {
  DVA_FIELD_MAPPINGS,
  HeaderTitle,
  MEDICARE_DVA_FIELD_MAPPINGS,
  extraInformation,
  registrationPath,
  sharedFormContainerProps,
} from '../Helper';
import { FormProps, PatientReviewAndSubmit, Profile, User } from '../interfaces';
import { REVIEW_AND_SUBMIT_QUERY, SUBMIT_FORM, SUBMIT_FORM_PSO } from './ReviewAndSubmitQueries';
import {
  ADDRESS_FIELDS,
  BASIC_FIELDS,
  CONTACT_FIELDS,
  DEMOGRAPHICS_FIELDS,
  DOCUMENT_UPLOAD_FIELDS,
  EMERGENCY_CONTACT_FIELDS,
  FORM_TEXT,
  INFORMATION_NOTICE_FIELDS,
  INSURANCE_FIELDS,
  MODAL_PSO_SUBMIT_COPY,
  MODAL_SUBMIT_COPY,
  PSO_FORM_TEXT,
  REFERRER_FIELDS,
  fieldsToExclude,
} from './constants';
import { Field, HeaderProps, RowBlockProps, RowProps } from './interfaces';
import RenderInvalidSections, { documentUploadInvalid } from './reviewValidation';
import { useTheme } from '@mui/material';

export const StyledCard = styled('div')<{ notvalid?: boolean }>`
  padding: 16px 24px 24px 24px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 8px rgba(113, 110, 106, 0.4);
  ${(props) => (props?.notvalid ? `border: solid 2px ${props.theme.palette.error.main}` : '')};

  @media all and (max-width: ${supportedSize.tablet}px) {
    padding 8px 12px 12px 12px;
  }
`;

export const StyledHeader = styled('div')<{ notvalid?: boolean }>`
  color: ${(props) => (props?.notvalid ? props.theme.palette.error.main : props.theme.palette.primary.main)};
  font-size: 23px;
  line-height: 28px;

  @media all and (max-width: ${supportedSize.tablet}px) {
    font-size: 20px;
    align-self: center;
  }
`;

export const StyledHeaderRow = styled('div')`
  line-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  .ro-button-style .form-fields-default-button .default-gc-button {
    margin-right: 0;
  }
`;

export const StyledRow = styled('div')`
  line-height: 28px;
  display: flex;
  flex-direction: row;

  @media all and (max-width: ${supportedSize.tablet}px) {
    padding-bottom: 6px;
    font-size: 0.9rem;
  }
`;

export const StyledField = styled('label')`
  flex-grow: 1;
  width: 50%;
  padding-right: 16px;
  overflow-wrap: break-word;
`;

export const StyledInfoField = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 50%;
  justify-content: flex-start;
  line-height: 28px;
  align-items: flex-start;
  margin-bottom: 13px;
  overflow-wrap: break-word;

  label:first-of-type {
    line-height: initial;
    flex-grow: 0;
  }

  div:first-of-type {
    margin-top: 2px;
    line-height: initial;
    flex-grow: 1;
    align-items: start;
  }
`;

export const StyledConditionalSection = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]};

  label:first-of-type {
    padding-left: 16px;
  }
`;

export const StyledSubHeading = styled('div')`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const StyledDivider = styled('div')`
  border-top: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-top: 24px;
  padding-top: 18px;
`;

const EditIcon = styled(Edit)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;

const StyledButton = styled('button')`
  width: 90px;
  min-width: 90px;
  height: 40px;
  display: flex;
  background: white;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 20px;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 17px;
  cursor: pointer;
`;

const StyledButtonText = styled('div')`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.primary};
`;

const THERANOSTICS = 'THA';

export const Header = (props: HeaderProps): JSX.Element => {
  const { patientId: id } = useParams<{ patientId: string }>();
  const { title, path, notvalid } = props;
  const history = useHistory();

  return (
    <StyledHeaderRow>
      <StyledHeader data-test-id={`header-${title}`} notvalid={notvalid}>
        {title} <span>{notvalid && <WarningTriangle title="Warning_Triangle.svg" />}</span>
      </StyledHeader>
      <StyledButton
        data-test-id={`edit-button-${title}`}
        onClick={(): void => {
          history.push(`/patient/${id}/registration/${path}`);
        }}>
        <EditIcon />
        <StyledButtonText>Edit</StyledButtonText>
      </StyledButton>
    </StyledHeaderRow>
  );
};

const dateFields = [
  'dob',
  'spouseDob',
  'primaryInsurance.policyDob',
  'secondaryInsurance.policyDob',
  'healthPensionCardExpiry',
  'healthPensionCardStart',
];
const infoFields = ['advancedDirective', 'powerOfAttorney', 'livingWill'];
const checkboxFields = ['agreeToSurvey'];

const formatValue = (name: string, value: string | number | boolean) => {
  if (dateFields.includes(name)) {
    // Format as a nice date
    return value ? moment(value.toString()).format('DD/MM/YYYY') : value ?? '-';
  }
  if (typeof value === 'boolean') {
    if (value) return 'Yes';
    if (value === false && !checkboxFields.includes(name)) return 'No';
    return '-';
  }
  // Nice format of a valid medicare number
  if (name === 'healthMedicareNumber' && MEDICARE_REGEX.test(value.toString())) {
    const val = value.toString();
    return val.slice(0, 4) + ' ' + val.slice(4, 9) + ' ' + val.slice(9, 10);
  }
  return value?.toString() || '-';
};

const Row = ({ field, isValidationField, patient, isPso }: RowProps): JSX.Element => {
  const value = _.get(patient, field.key);

  const styledField = <StyledField data-test-id={`${field.key}`}>{formatValue(field.key, value)}</StyledField>;
  if (isValidationField && (value || fieldsToExclude.includes(field.key))) {
    return <></>;
  }

  const fieldRow =
    infoFields.includes(field.key) && value === true && !isPso ? (
      <StyledInfoField>
        {styledField}
        {extraInformation}
      </StyledInfoField>
    ) : (
      styledField
    );

  return (
    <StyledRow>
      <StyledField>{field.label}</StyledField>
      {fieldRow}
    </StyledRow>
  );
};

export const RowBlock = ({ fields, isValidationField, patient, isPso }: RowBlockProps): JSX.Element => {
  return (
    <>
      {fields.map(
        (field: Field, index: number): JSX.Element => (
          <Row
            key={`${field.label}-${index}`}
            field={field}
            isValidationField={isValidationField}
            patient={patient}
            isPso={isPso}
          />
        ),
      )}
    </>
  );
};

const ReviewAndSubmit = (props: FormProps): JSX.Element => {
  const { formStatusDetails, setFormStatusDetails } = useContext(FormContext);
  const registrationContext = useContext(RegistrationContext);
  const theme = useTheme();

  const { exitForm } = props;
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const { patientId: id } = useParams<{ patientId: string }>();

  const { data, error, loading } = useQuery<{
    patient: PatientReviewAndSubmit;
    user: User;
    profile: Profile;
    distressThermometerInitial: {
      id: string;
      status: string;
      version: string;
    };
  }>(REVIEW_AND_SUBMIT_QUERY, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });
  const referringPage = sessionStorage.getItem('referringPage');

  const [submitForm] = useMutation(SUBMIT_FORM, {
    refetchQueries: ['PatientFormsDetails'],
    onCompleted: () => {
      if (formStatusDetails)
        setFormStatusDetails({
          ...formStatusDetails,
          patient: { ...formStatusDetails.patient, regFormStatus: FormStatus.REG_REVIEW_REQUIRED },
        });
    },
  });

  const [submitFormPso, { loading: mutationLoading }] = useMutation(SUBMIT_FORM_PSO, {
    onCompleted: () => {
      sessionStorage.setItem('referringPage', '');
      history.push(referringPage || `/navigator/patient/${id}/summary`);
    },
  });

  const [releaseLock] = useMutation(RELEASE_LOCK_MUTATION);
  const history = useHistory();

  if (loading || !data || error) return <LoadingSpinner />;

  if (mutationLoading) return <LoadingSpinner loadingText="Submitting registration form" />;

  const { patient, user } = data;

  // Mapping of old medicare dva values to new values if needed
  const healthMedicareDvaOption = patient?.healthMedicareDvaOption || '';
  if (Object.keys(MEDICARE_DVA_FIELD_MAPPINGS).includes(healthMedicareDvaOption)) {
    patient.healthMedicareDvaOption =
      MEDICARE_DVA_FIELD_MAPPINGS[healthMedicareDvaOption as keyof typeof MEDICARE_DVA_FIELD_MAPPINGS];
  }

  const logicallyRedirect = () => {
    // TODO - repetition of the constants. Move to a higher order constant file or env var
    const theranosticsPath = '/server/forms/theranostics';
    if (healthAssessmentSubmitted && goToDt && dtId) return history.push(`/patient/${id}/distress/${dtId}/information`);

    if (!healthAssessmentSubmitted && showHealthAssessment) return history.push(`/patient/${id}/health/info`);
    if (patient.emr === THERANOSTICS && formStatusDetails?.theranostics?.status !== FormStatus.REG_SUBMITTED) {
      return navigateToExternalURL(theranosticsPath);
    }

    history.push({ pathname: `/patient/${id}/home`, state: { pxRedirect: true } });
  };

  // Mapping of old dva values to new values if needed
  const dvaOption = patient?.healthDvaType || '';
  if (Object.keys(DVA_FIELD_MAPPINGS).includes(dvaOption)) {
    patient.healthDvaType = DVA_FIELD_MAPPINGS[dvaOption as keyof typeof DVA_FIELD_MAPPINGS];
  }

  const isPso = user?.isPso;

  const healthAssessmentSubmitted = patient?.healthAssessment.status === FormStatus.REG_SUBMITTED;
  const showHealthAssessment = patient?.userProfile?.showHealthAssessment;
  const showDistressThermometer = patient?.userProfile?.showDistressThermometer;
  const goToDt = data?.distressThermometerInitial?.status !== FormStatus.REG_SUBMITTED && showDistressThermometer;
  const dtId = data?.distressThermometerInitial?.id;

  const handleSubmit = () => {
    releaseLock({ variables: { accessPatientId: id } });
    if (isPso) {
      submitFormPso({
        variables: { id },
      }).then(() => {
        logicallyRedirect();
      });
    } else {
      submitForm({ variables: { id } }).then(() => {
        logicallyRedirect();
      });
    }
  };

  const sharedProps = sharedFormContainerProps('Review and Submit', 10, registrationPath(id, 'upload'));
  const formContainerProps = {
    ...sharedProps,
    continueLink: '',
    submitButtonText: 'Submit',
    handleShowSaveExitConfirm: () => exitForm(true),
    submitForm: () => {
      if (isPso && documentUploadInvalid(patient)) {
        return setShowValidationModal(true);
      }
      setShowSubmitModal(true);
    },
    showPTSummaryNav: isPso,
    patient: isPso && patient,
  };

  const showInterpreterRequired = patient?.niceLanguageAtHome && patient?.niceLanguageAtHome !== 'English';
  const attachments = registrationAttachments(patient.attachments);
  registrationContext.setRegistrationSummaryVisited(true);

  const basicFields = () => {
    if (patient?.patientGenderIdentification !== 'Different Term') {
      return BASIC_FIELDS.fields.filter((field) => field.key !== 'patientGenderIdentificationOther');
    }
    return BASIC_FIELDS.fields;
  };
  return (
    <>
      <FormContainer {...formContainerProps}>
        <HeaderTitle data-test-id="au-rego-page-title">{'Review & Submit'}</HeaderTitle>
        {isPso && <RenderInvalidSections patient={patient} />}
        <StyledCard>
          <Header title={INFORMATION_NOTICE_FIELDS.title} path={INFORMATION_NOTICE_FIELDS.path} />
          <RowBlock fields={INFORMATION_NOTICE_FIELDS.fields} patient={patient} isPso={isPso} />
        </StyledCard>

        {/* Basic details */}
        <StyledCard>
          <Header title={BASIC_FIELDS.title} path={BASIC_FIELDS.path} />
          <RowBlock fields={basicFields()} patient={patient} isPso={isPso} />
        </StyledCard>

        {/* Contact details */}
        <StyledCard>
          <Header title={CONTACT_FIELDS.title} path={CONTACT_FIELDS.path} />
          <RowBlock fields={CONTACT_FIELDS.fields} patient={patient} isPso={isPso} />
        </StyledCard>

        {/* Address */}
        <StyledCard>
          <Header title={ADDRESS_FIELDS.title} path={ADDRESS_FIELDS.path} />
          <StyledSubHeading>Residential Address</StyledSubHeading>
          <RowBlock fields={ADDRESS_FIELDS.fields} patient={patient} isPso={isPso} />
          <StyledDivider />
          <StyledSubHeading>Postal Address</StyledSubHeading>
          <RowBlock fields={ADDRESS_FIELDS.postalSameField} patient={patient} isPso={isPso} />
          {patient?.postalAddressSameAsResidential === false && (
            <StyledConditionalSection>
              <RowBlock fields={ADDRESS_FIELDS.postalFields} patient={patient} isPso={isPso} />
            </StyledConditionalSection>
          )}
        </StyledCard>

        {/* Emergency contact */}
        <StyledCard>
          <Header title={EMERGENCY_CONTACT_FIELDS.title} path={EMERGENCY_CONTACT_FIELDS.path} />
          <RowBlock fields={EMERGENCY_CONTACT_FIELDS.fields} patient={patient} isPso={isPso} />
          {patient?.altContactProvided === true && (
            <StyledConditionalSection>
              <RowBlock fields={EMERGENCY_CONTACT_FIELDS.nextOfKinFields} patient={patient} isPso={isPso} />
            </StyledConditionalSection>
          )}
        </StyledCard>

        {/* Referrers */}
        <StyledCard>
          <Header title={REFERRER_FIELDS.title} path={REFERRER_FIELDS.path} />
          <StyledSubHeading>Referring specialist</StyledSubHeading>
          <RowBlock fields={REFERRER_FIELDS.fields} patient={patient} isPso={isPso} />
          <StyledSubHeading>{'GP (General practitioner)'}</StyledSubHeading>
          <RowBlock fields={REFERRER_FIELDS.gpFields} patient={patient} isPso={isPso} />
          <StyledSubHeading>Other specialists</StyledSubHeading>
          <RowBlock fields={REFERRER_FIELDS.otherReferrer} patient={patient} isPso={isPso} />
        </StyledCard>

        {/* Insurance */}
        <StyledCard>
          <Header title={INSURANCE_FIELDS.title} path={INSURANCE_FIELDS.path} />
          <RowBlock fields={INSURANCE_FIELDS.fields} patient={patient} isPso={isPso} />
          {['Medicare', 'Medicare and DVA'].includes(patient.healthMedicareDvaOption) && (
            <>
              <StyledSubHeading>Medicare card</StyledSubHeading>
              <StyledConditionalSection>
                <RowBlock fields={INSURANCE_FIELDS.medicareFields} patient={patient} isPso={isPso} />
              </StyledConditionalSection>
            </>
          )}
          {['Medicare and DVA'].includes(patient.healthMedicareDvaOption) && (
            <>
              <StyledSubHeading>DVA card</StyledSubHeading>
              <StyledConditionalSection>
                <RowBlock fields={INSURANCE_FIELDS.dvaFields} patient={patient} isPso={isPso} />
              </StyledConditionalSection>
            </>
          )}
          <StyledDivider />
          <RowBlock fields={INSURANCE_FIELDS.privateInsuranceConditional} patient={patient} isPso={isPso} />
          {patient?.healthPrivateHealthInsurance && (
            <StyledConditionalSection>
              <RowBlock fields={INSURANCE_FIELDS.privateInsuranceFields} patient={patient} isPso={isPso} />
            </StyledConditionalSection>
          )}
          <StyledDivider />
          <RowBlock fields={INSURANCE_FIELDS.pensionCardConditional} patient={patient} isPso={isPso} />
          {patient?.healthPensionCard && (
            <StyledConditionalSection>
              <RowBlock fields={INSURANCE_FIELDS.pensionCardFields} patient={patient} isPso={isPso} />
            </StyledConditionalSection>
          )}
        </StyledCard>

        {/* Demographics */}
        <StyledCard>
          <Header title={DEMOGRAPHICS_FIELDS.title} path={DEMOGRAPHICS_FIELDS.path} />
          <RowBlock fields={DEMOGRAPHICS_FIELDS.fields} patient={patient} isPso={isPso} />
          {showInterpreterRequired && (
            <StyledConditionalSection>
              <RowBlock fields={DEMOGRAPHICS_FIELDS.interpreterRequiredField} patient={patient} isPso={isPso} />
            </StyledConditionalSection>
          )}
          <RowBlock fields={DEMOGRAPHICS_FIELDS.remainingFields} patient={patient} isPso={isPso} />
          {patient.patientState === AustralianStates.VIC && (
            <>
              <RowBlock fields={DEMOGRAPHICS_FIELDS.vicSpecificFields} patient={patient} isPso={isPso} />
              {patient.ambulanceMembership && (
                <RowBlock fields={DEMOGRAPHICS_FIELDS.ambulanceNumberFields} patient={patient} isPso={isPso} />
              )}
            </>
          )}
        </StyledCard>

        <StyledCard>
          <Header title={DOCUMENT_UPLOAD_FIELDS.title} path={DOCUMENT_UPLOAD_FIELDS.path} />
          {attachments.length ? (
            attachments.map((attachment: PatientAttachment, index: number) => {
              return (
                <div className="py-1" key={`attachment-${index}`}>
                  <a
                    style={{ color: theme.palette.info.main }}
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    {`${index + 1}. ${attachment.filename}`}
                  </a>
                </div>
              );
            })
          ) : (
            <div>No attachments provided.</div>
          )}
        </StyledCard>
      </FormContainer>

      <ModalSubmitForm
        isOpen={showSubmitModal}
        setIsOpen={setShowSubmitModal}
        submitFormMutation={() => {
          handleSubmit();
        }}
        title={isPso ? MODAL_PSO_SUBMIT_COPY : MODAL_SUBMIT_COPY}
        submitText={'Submit'}
        formText={isPso ? PSO_FORM_TEXT : FORM_TEXT}
      />

      <Modal
        isOpen={showValidationModal}
        title="Required Fields Are Incomplete"
        dismissFunction={() => setShowValidationModal(false)}
        leftButtonText={'Cancel'}
        rightButtonText={'Go to incomplete field'}
        rightButtonBackground={theme.palette.primary.main}
        rightButtonBorder={theme.palette.primary.main}
        rightButtonWidth="200px"
        rightButtonHandleClick={(e: any) => {
          e.preventDefault();
          history.push({ pathname: 'upload' });
          setShowValidationModal(false);
        }}
        leftButtonHandleClick={(e: any) => {
          e.preventDefault();
          setShowValidationModal(false);
        }}>
        <span>
          Please answer all mandatory fields before submitting. Mandatory fields are marked with an asterisk*.
        </span>
        {attachments.some((attachment) => !attachment.documentType) && (
          <ErrorInfo errors={['docType']} errorMessages={{ docType: 'Document type' }} />
        )}
        {!patient.attachmentAcknowledged && (
          <ErrorInfo errors={['documentAck']} errorMessages={{ documentAck: 'Document acknowledgement' }} />
        )}
      </Modal>
    </>
  );
};

export default ReviewAndSubmit;
