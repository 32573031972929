import { useMutation } from '@apollo/client';
import { Form as FormikForm, Formik } from 'formik';
import _ from 'lodash';
import { DTRatingItem } from 'op-classes';
import { FormContainer, NAPageContainer } from 'op-components';
import { MandatoryPatient } from 'op-interfaces';
import { AssessmentContext } from 'op-pages';
import { deletePreCtModal } from 'op-pages/OP/PatientFormSummary/PatientFormSummary';
import React, { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { Modal, ModalContentSubmissionError, SCStepper } from 'shared-components/components';
import { Logger } from 'shared-components/utils';
import { styled } from '@mui/system';
import {
  CREATE_AMENDED_PRE_CT_CHART_CHECK,
  CREATE_PRE_CT_TRIAGE,
  DELETE_PRE_CT_CHART_CHECK,
  DELETE_PRE_CT_TRIAGE,
  QUERY_NURSE,
} from '../../PatientFormSummary/PatientFormSummaryQueries';
import { routes as routesTriage } from '../PreCtTriage/routes';
import {
  AdditionalNotes,
  ModalContentMissingQcl,
  ModalContentProceedToTriage,
  ModalContentSubmit,
  SideBar,
} from '../shared/components';
import { sharedContent } from '../shared/constants';
import { LocationListData, LoggedInUser } from '../shared/interfaces';
import { GET_ACTIVE_QCLS } from '../shared/queries';
import { fieldsToValidateOnContinue, preCtChartCheckContent, reviewInfo, stepperTitle } from './constants';
import FormContext from './contexts';
import { PRE_CT_CHART_CHECK_QUERY, SUBMIT_PRE_CT_CHART_CHECK } from './graphql/PreCtChartCheckQueries';
import { HealthAssessmentData, PreCtChartCheckData } from './interfaces/PreCtChartCheckInterfaces';
import AdvancedCareDirective from './pages/AdvancedCareDirective';
import Allergies from './pages/Allergies/Allergies';
import BasicInformation from './pages/BasicInformation';
import ChemotherapyRisk from './pages/ChemotherapyRisk';
import FallsRisk from './pages/FallsRisk';
import ImplantedMedicalDevices from './pages/ImplantedMedicalDevices';
import InfectiousRisk from './pages/InfectiousRisk';
import PressureInjury from './pages/PressureInjury';
import ReviewAndSubmit from './pages/ReviewAndSubmit';
import Wellbeing from './pages/Wellbeing';
import { patientSummaryLink, routes } from './routes';
import { generateValidationSchema, validationSchema } from './validation';

const logger = new Logger('PreCtChartCheck');

const Wrapper = styled('div')`
  height: 100%;
`;

const Form = styled(FormikForm)`
  height: 100%;
`;

const StepperPlaceholder = styled('div')`
  min-width: 15%;
  background-color: ${(props) => props.theme.palette.grey[100]};
  min-height: calc(100% - 60px);
  height: auto;
  box-shadow: inset -1px 0px 1px rgba(0, 0, 0, 0.2);
`;

interface Props {
  allowedLocationsData: LocationListData[];
  distressThermometerData: DTRatingItem;
  healthAssessmentData: HealthAssessmentData;
  patientDetailsData: MandatoryPatient;
  preCtChartCheckData: PreCtChartCheckData;
  loggedInUserData: LoggedInUser;
}

interface FormikProps {
  setTouched: any;
  values: any;
  errors: any;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: any;
}

const implantedMedicalDeviceFields = Object.keys(preCtChartCheckContent.implantedMedicalDevices.fields).filter(
  (field) => field !== 'hasImplantedMedicalDevice',
);

implantedMedicalDeviceFields.reduce((initial, field) => {
  //@ts-ignore
  initial[field] = [];
  return initial;
}, {});

const PreCtChartCheck = ({
  allowedLocationsData,
  distressThermometerData,
  healthAssessmentData,
  patientDetailsData,
  preCtChartCheckData,
  loggedInUserData,
}: Props): any => {
  const [fieldsVisited, setFieldsVisited] = useState({});
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [openProceedToTriageModal, setOpenProceedToTriageModal] = useState(false);
  const [openSubmitErrorModal, setOpenSubmitErrorModal] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const { patientId, formId } = useParams<{ patientId: string; formId: string }>();
  const formContext = useContext(FormContext);
  const { chartCheckIdentifier } = useContext(AssessmentContext);
  const { updatePreCtChartCheck, createInitialTriageQclCall, refetchPreCtChartCheck } = formContext.mutations;
  const patientState = patientDetailsData.userProfile && patientDetailsData.userProfile.systemState;

  const history = useHistory();
  const location = useLocation();

  const {
    id,
    patientIdentificationCheck,
    patientHeight,
    patientWeight,
    previousRadiationTreatment,
    anticancerDrugs,
    chemoPastWeek,
    hasImplantedMedicalDevice,
    hasInfectiousDisease,
    infectiousDisease,
    hasMroInfection,
    mroInfection,
    outsideWaLastTwelveMonths,
    fallLastTwelveMonths,
    safetyConcernsMobility,
    hasCurrentAcd,
    acdInfoRequested,
    hasCurrentResusMxPlan,
    staffConcern,
    anxietyClaustrophobia,
    treatmentOverHalfHour,
    currentInpatient,
    requiresMaskCast,
    existingPressureInjuryWound,
    nurseLocation,
    hasPatientAllergies,
    pcccAllergy,
    additionalNotes,
    lastUpdateUser,
    isLocked,
    updatedBy,
    isAmendment = false,
  } = preCtChartCheckData;

  const implantedMedicalDeviceValues = preCtChartCheckData.implantedMedicalDevice.reduce(
    (transformedValues, device) => {
      const { deviceField, name, id, lastUpdateUser } = device;
      // @ts-ignore
      transformedValues[deviceField] = transformedValues.hasOwnProperty(deviceField) && [
        // @ts-ignore
        ...transformedValues[deviceField],
        { id, name, deviceField, lastUpdateUser },
      ];
      return transformedValues;
    },
    implantedMedicalDeviceFields.reduce((initial, field) => {
      //@ts-ignore
      initial[field] = [];
      return initial;
    }, {}),
  );

  const handleArrayValidation = (rootField: any, nestedFieldName?: string) => {
    return rootField.reduce((groupsTouched: any, group: any) => {
      Object.keys(group).forEach((baseField) => {
        if (nestedFieldName && baseField === nestedFieldName) {
          group[baseField] = _.cloneDeep(group[baseField]).reduce((nestedGroupsTouched: any, nestedGroup: any) => {
            Object.keys(nestedGroup).forEach((nestedField) => {
              nestedGroup[nestedField] = true;
            });
            nestedGroupsTouched = [...nestedGroupsTouched, nestedGroup];
            return nestedGroupsTouched;
          }, []);
        } else {
          group[baseField] = true;
        }
      });
      groupsTouched = [...groupsTouched, group];
      return groupsTouched;
    }, []);
  };

  const incomingValues = {
    patientIdentificationCheck,
    patientHeight,
    patientWeight,
    previousRadiationTreatment,
    anticancerDrugs,
    chemoPastWeek,
    hasImplantedMedicalDevice,
    ...implantedMedicalDeviceValues,
    hasInfectiousDisease,
    infectiousDisease,
    hasMroInfection,
    mroInfection,
    outsideWaLastTwelveMonths,
    fallLastTwelveMonths,
    safetyConcernsMobility,
    hasCurrentAcd,
    acdInfoRequested,
    hasCurrentResusMxPlan,
    staffConcern,
    anxietyClaustrophobia,
    treatmentOverHalfHour,
    currentInpatient,
    requiresMaskCast,
    existingPressureInjuryWound,
    nurseLocation: nurseLocation ? nurseLocation.toString() : '',
    hasPatientAllergies,
    pcccAllergy,
    additionalNotes,
    generatedQCLs: [],
    updatedBy,
    lastUpdateUser,
    isAmendment,
  };

  const handleMutation = (fieldName: string, value: any): void => {
    updatePreCtChartCheck({ variables: { id: formId, [fieldName]: value } });
  };
  const [submitPreCtChartCheck] = useMutation(SUBMIT_PRE_CT_CHART_CHECK, {
    refetchQueries: [{ query: PRE_CT_CHART_CHECK_QUERY, variables: { id: formId } }],
    awaitRefetchQueries: true,
  });
  const [createAmendedPreCtChartCheck] = useMutation(CREATE_AMENDED_PRE_CT_CHART_CHECK, {
    refetchQueries: [
      { query: QUERY_NURSE, variables: { id: patientId } },
      { query: GET_ACTIVE_QCLS, variables: { patientId, isAmendment: true } },
    ],
    awaitRefetchQueries: true,
    fetchPolicy: 'no-cache',
  });

  const submitForm = (): void => {
    submitPreCtChartCheck({ variables: { id: formId } })
      .then(() => {
        setOpenSubmitModal(false);
        setOpenProceedToTriageModal(!isAmendment && String(chartCheckIdentifier) !== String(formId));
        setSubmitDisabled(false);
        isAmendment && history.push(`/patient/${patientId}/summary`);
      })
      .catch((error) => {
        setOpenSubmitModal(false);
        setOpenSubmitErrorModal(true);
        logger.error(error);
      });
  };

  const [createPreCtTriage] = useMutation(CREATE_PRE_CT_TRIAGE);
  const generateTriageLink = async () => {
    await createPreCtTriage({ variables: { patientId } })
      .then(({ data }: any) => {
        history.push(
          generatePath(routesTriage.malnutritionRisk.path, {
            patientId,
            formId: data.createPreCtTriage.preCtTriage.id,
          }),
        );
      })
      .then(() => isAmendment && refetchPreCtChartCheck());
  };

  const generateEmail = () => {
    window.location.href = `mailto:${sharedContent.reviewAndSubmit.submissionError.emailLink}`;
  };

  const { firstName, lastName } = loggedInUserData;
  const submittedByNurse = `${firstName} ${lastName}`;
  const [deletePreCtChartCheck] = useMutation(DELETE_PRE_CT_CHART_CHECK, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patientId } }],
  });
  const [deletePreCtTriage] = useMutation(DELETE_PRE_CT_TRIAGE, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patientId } }],
  });

  useEffect(() => {
    if (isLocked && !location.pathname.includes('review-and-submit')) {
      history.push(
        generatePath(routes.reviewAndSubmit.path, {
          patientId,
          formId,
        }),
      );
    }
  }, []);

  const { pathname } = window.location;
  const currentPageVisited = pathname.substring(pathname.lastIndexOf('/') + 1);
  const currentPage = window.location.pathname.split('/').slice(-1)[0];

  useEffect(() => {
    // @ts-ignore
    const fields = fieldsToValidateOnContinue[currentPageVisited].reduce((allFieldsTouched: any, field: any) => {
      allFieldsTouched[field] = true;
      return allFieldsTouched;
    }, {});
    setFieldsVisited({ ...fieldsVisited, ...fields });
  }, [window.location.pathname]);

  return (
    <Wrapper>
      <Formik
        initialValues={incomingValues}
        validationSchema={generateValidationSchema(patientState, validationSchema)}
        onSubmit={() => {}}>
        {({ values, errors, setTouched, handleChange, setFieldValue }: FormikProps) => {
          const handlePagesVisitedValidation = () => {
            setTouched(fieldsVisited);
          };

          const handleInfectiousRiskValidation = (): void => {
            const infectiousDiseaseValues = _.cloneDeep(values.infectiousDisease);
            const mroInfectionValues = _.cloneDeep(values.mroInfection);
            setFieldsVisited({
              ...fieldsVisited,
              infectiousDisease: handleArrayValidation(infectiousDiseaseValues),
              mroInfection: handleArrayValidation(mroInfectionValues),
            });
            handlePagesVisitedValidation();
          };

          const handleAllergiesValidation = (): void => {
            const pcccAllergyValues = _.cloneDeep(values.pcccAllergy);
            setFieldsVisited({
              ...fieldsVisited,
              pcccAllergy: handleArrayValidation(pcccAllergyValues, 'pcccReaction'),
            });
            handlePagesVisitedValidation();
          };

          const sidebarVariables = {
            patientId,
            formId,
          };

          const stepperArray = Object.values<{ sectionTitle: string; name: string; path: string }>(routes).map(
            (object) => ({
              sectionTitle: `${object.sectionTitle}`,
              name: `${object.name}`,
              path: `${generatePath(object.path, sidebarVariables)}`,
            }),
          );

          const formData = { id: formId, title: 'Initial Chart Check', isAmendment: isAmendment };

          const createAmendmentAndRedirect = async () => {
            await createAmendedPreCtChartCheck({ variables: { id: formId } }).then(({ data }: any) =>
              refetchPreCtChartCheck()
                .then(() =>
                  history.push(
                    generatePath(routes.basicInformation.path, {
                      patientId: patientId,
                      formId: data.createAmendedPreCtChartCheck.preCtChartCheck.id,
                    }),
                  ),
                )
                .then(() => setFieldValue('additionalNotes', '')),
            );
          };

          const formStandardValues = {
            updatedBy: preCtChartCheckData.updatedBy,
            isAmendment: Boolean(preCtChartCheckData.isAmendment),
            lastUpdateUser: preCtChartCheckData.lastUpdateUser,
            patient: patientDetailsData,
            reviewInfo: reviewInfo,
            saveAndExitLink: generatePath(patientSummaryLink, { patientId }),
            setDeleteModalIsOpen: setDeleteModalIsOpen,
            deleteModal: () =>
              deletePreCtModal(
                deletePreCtChartCheck,
                deletePreCtTriage,
                deleteModalIsOpen,
                setDeleteModalIsOpen,
                formData,
                patientId,
                history,
                true,
              ),
            createAmendmentAndRedirect: () => createAmendmentAndRedirect(),
          };
          const formContainerData = {
            basicInformation: {
              handleValidation: () => handlePagesVisitedValidation(),
              pageNumber: 1,
              sectionTitle: routes.basicInformation.sectionTitle,
              continueLink: generatePath(routes.chemoRisk.path, {
                patientId,
                formId,
              }),
            },
            chemoRisk: {
              handleValidation: () => handlePagesVisitedValidation(),
              pageNumber: 2,
              sectionTitle: routes.chemoRisk.sectionTitle,
              continueLink: generatePath(routes.implantedMedicalDevices.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.basicInformation.path, {
                patientId,
                formId,
              }),
            },
            implantedMedicalDevices: {
              handleValidation: () => handlePagesVisitedValidation(),
              pageNumber: 3,
              sectionTitle: routes.implantedMedicalDevices.sectionTitle,
              continueLink: generatePath(routes.infectiousRisk.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.chemoRisk.path, {
                patientId,
                formId,
              }),
            },
            infectiousRisk: {
              handleValidation: () => handleInfectiousRiskValidation(),
              pageNumber: 4,
              sectionTitle: routes.infectiousRisk.sectionTitle,
              continueLink: generatePath(routes.fallsRisk.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.implantedMedicalDevices.path, {
                patientId,
                formId,
              }),
            },
            fallsRisk: {
              handleValidation: () => handlePagesVisitedValidation(),
              pageNumber: 5,
              sectionTitle: routes.fallsRisk.sectionTitle,
              continueLink: generatePath(routes.pressureInjuryRisk.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.infectiousRisk.path, {
                patientId,
                formId,
              }),
            },
            pressureInjuryRisk: {
              handleValidation: () => handlePagesVisitedValidation(),
              pageNumber: 6,
              sectionTitle: routes.pressureInjuryRisk.sectionTitle,
              continueLink: generatePath(routes.wellbeing.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.fallsRisk.path, {
                patientId,
                formId,
              }),
            },
            wellbeing: {
              handleValidation: () => handlePagesVisitedValidation(),
              pageNumber: 7,
              sectionTitle: routes.wellbeing.sectionTitle,
              continueLink: generatePath(routes.advancedCareDirective.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.pressureInjuryRisk.path, {
                patientId,
                formId,
              }),
            },
            advancedCareDirective: {
              handleValidation: () => handlePagesVisitedValidation(),
              pageNumber: 8,
              sectionTitle: routes.advancedCareDirective.sectionTitle,
              continueLink: generatePath(routes.allergies.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.wellbeing.path, {
                patientId,
                formId,
              }),
            },
            allergies: {
              handleValidation: () => handleAllergiesValidation(),
              pageNumber: 9,
              sectionTitle: routes.allergies.sectionTitle,
              continueLink: generatePath(routes.reviewAndSubmit.path, {
                patientId,
                formId,
              }),
              backLink: generatePath(routes.advancedCareDirective.path, {
                patientId,
                formId,
              }),
            },
            reviewAndSubmit: {
              handleValidation: () => handlePagesVisitedValidation(),
              pageNumber: 10,
              sectionTitle: routes.reviewAndSubmit.sectionTitle,
              backLink: generatePath(routes.allergies.path, {
                patientId,
                formId,
              }),
            },
          };

          return (
            <Form>
              <NAPageContainer>
                <StepperPlaceholder>
                  <SideBar sidebarVariables={sidebarVariables}>
                    <SCStepper
                      stepperTitle={stepperTitle}
                      data={stepperArray}
                      currentPage={currentPage}
                      handleValidation={() =>
                        currentPageVisited === 'infectious-risk'
                          ? handleInfectiousRiskValidation()
                          : currentPageVisited === 'allergies'
                          ? handleAllergiesValidation()
                          : handlePagesVisitedValidation()
                      }
                      isLocked={isLocked}
                    />
                  </SideBar>
                </StepperPlaceholder>
                <Switch>
                  <Route
                    path={routes.basicInformation.path}
                    render={(): JSX.Element => (
                      <FormContainer
                        isLocked={isLocked}
                        {...formStandardValues}
                        {...formContainerData.basicInformation}
                        reviewInfo={reviewInfo}
                        handleValidation={handlePagesVisitedValidation}
                        saveAndExitLink={generatePath(patientSummaryLink, { patientId })}>
                        <BasicInformation
                          allowedLocations={allowedLocationsData}
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={generatePath(routes.chemoRisk.path, { patientId, formId })}
                    render={(): JSX.Element => (
                      <FormContainer isLocked={isLocked} {...formStandardValues} {...formContainerData.chemoRisk}>
                        <ChemotherapyRisk
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.implantedMedicalDevices.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.implantedMedicalDevices}>
                        <ImplantedMedicalDevices
                          assessmentId={id}
                          handleMutation={handleMutation}
                          preCtChartCheckData={preCtChartCheckData}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.infectiousRisk.path}
                    render={(): JSX.Element => (
                      <FormContainer isLocked={isLocked} {...formStandardValues} {...formContainerData.infectiousRisk}>
                        <InfectiousRisk
                          assessmentId={id}
                          lastUpdateUserData={lastUpdateUser}
                          patient={patientDetailsData}
                          updateAssessment={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.fallsRisk.path}
                    render={(): JSX.Element => (
                      <FormContainer isLocked={isLocked} {...formStandardValues} {...formContainerData.fallsRisk}>
                        <FallsRisk
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.pressureInjuryRisk.path}
                    render={(): JSX.Element => (
                      <FormContainer {...formStandardValues} {...formContainerData.pressureInjuryRisk}>
                        <PressureInjury
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.wellbeing.path}
                    render={(): JSX.Element => (
                      <FormContainer isLocked={isLocked} {...formStandardValues} {...formContainerData.wellbeing}>
                        <Wellbeing
                          distressThermometerData={distressThermometerData}
                          handleMutation={handleMutation}
                          healthAssessmentData={healthAssessmentData}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.advancedCareDirective.path}
                    render={(): JSX.Element => (
                      <FormContainer
                        isLocked={isLocked}
                        {...formStandardValues}
                        {...formContainerData.advancedCareDirective}>
                        <AdvancedCareDirective updateAssessment={handleMutation} lastUpdateUserData={lastUpdateUser} />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.allergies.path}
                    render={(): JSX.Element => (
                      <FormContainer isLocked={isLocked} {...formStandardValues} {...formContainerData.allergies}>
                        <Allergies
                          assessmentId={id}
                          handleMutation={handleMutation}
                          lastUpdateUserData={lastUpdateUser}
                        />
                      </FormContainer>
                    )}
                  />
                  <Route
                    path={routes.reviewAndSubmit.path}
                    render={(): JSX.Element => (
                      <FormContainer
                        isLocked={isLocked}
                        {...formStandardValues}
                        {...formContainerData.reviewAndSubmit}
                        reviewInfo={reviewInfo}
                        handleValidation={handlePagesVisitedValidation}
                        backLink={generatePath(routes.allergies.path, { patientId, formId })}
                        saveAndExitLink={generatePath(patientSummaryLink, { patientId })}
                        submitForm={() => setOpenSubmitModal(true)}>
                        <ReviewAndSubmit
                          formId={formId}
                          patientId={patientId}
                          allowedLocations={allowedLocationsData}
                          patient={patientDetailsData}
                          isLocked={isLocked}
                        />
                        <Modal
                          isOpen={openSubmitModal}
                          title={
                            errors.nurseLocation
                              ? sharedContent.reviewAndSubmit.missingQcl.title
                              : sharedContent.reviewAndSubmit.submit.title
                          }
                          dismissFunction={() => setOpenSubmitModal(false)}
                          leftButtonText={sharedContent.generic.label.cancel}
                          leftButtonHandleClick={(event: MouseEvent): void => {
                            event.preventDefault();
                            setOpenSubmitModal(false);
                          }}
                          rightButtonText={
                            errors.nurseLocation
                              ? sharedContent.reviewAndSubmit.missingQcl.returnToField
                              : sharedContent.generic.label.submit
                          }
                          rightButtonLink={
                            errors.nurseLocation && generatePath(routes.basicInformation.path, { patientId, formId })
                          }
                          rightButtonDisabled={submitDisabled}
                          rightButtonHandleClick={(event: MouseEvent) => {
                            if (errors.nurseLocation) {
                              setOpenSubmitModal(false);
                            } else {
                              event.preventDefault();
                              setSubmitDisabled(true);
                              submitForm();
                            }
                          }}>
                          {errors.nurseLocation ? (
                            <ModalContentMissingQcl sectionName={routes.basicInformation.sectionTitle} />
                          ) : (
                            <ModalContentSubmit
                              isAmendment={isAmendment}
                              submittedByNurse={submittedByNurse}
                              formName="Chart Check"
                            />
                          )}
                        </Modal>
                        <Modal
                          isOpen={openProceedToTriageModal}
                          title={sharedContent.reviewAndSubmit.proceedToTriage.title}
                          leftButtonText={sharedContent.reviewAndSubmit.proceedToTriage.backToSummary}
                          rightButtonText={sharedContent.generic.label.continue}
                          leftButtonHandleClick={() => {
                            logger.info('createInitialTriageQclCall start');
                            createInitialTriageQclCall({ variables: { id: formId } });
                            setOpenProceedToTriageModal(false);
                          }}
                          rightButtonHandleClick={(event) => {
                            event.preventDefault();
                            generateTriageLink();
                          }}
                          leftButtonLink={generatePath(patientSummaryLink, { patientId })}>
                          <ModalContentProceedToTriage />
                        </Modal>
                        <Modal
                          centeredButton
                          isOpen={openSubmitErrorModal}
                          dismissFunction={() => setOpenSubmitErrorModal(false)}
                          title={sharedContent.reviewAndSubmit.submissionError.title}
                          rightButtonText={sharedContent.reviewAndSubmit.submissionError.contactText}
                          rightButtonLink={generatePath(patientSummaryLink, { patientId })}
                          rightButtonHandleClick={(): void => {
                            generateEmail();
                          }}>
                          <ModalContentSubmissionError
                            text={sharedContent.reviewAndSubmit.submissionError.mainText}
                            emailLink={sharedContent.reviewAndSubmit.submissionError.emailLink}
                          />
                        </Modal>
                      </FormContainer>
                    )}
                  />
                </Switch>
                <AdditionalNotes
                  handleMutation={handleMutation}
                  value={values.additionalNotes}
                  handleChange={handleChange}
                  name="additionalNotes"
                  isLocked={isLocked}
                />
              </NAPageContainer>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default PreCtChartCheck;
