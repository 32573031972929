// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'gc-ui';
import { ButtonType } from 'shared-components/enums';
import { GCButton } from 'shared-components/components/FormFields';
import { GCButtonSize } from 'shared-components/enums';

import './RecordLockedModal.scss';
import { Typography } from '@mui/material';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  title: string | JSX.Element;
  contents: string | JSX.Element;
}

class RecordLockedModal extends Component<Props> {
  public render(): JSX.Element {
    const { isOpen, dismissFunction, title, contents } = this.props;
    return (
      <Modal open={isOpen} onClose={dismissFunction} className="modal-gc save-exit-modal">
        <ModalHeader toggle={dismissFunction}>
          <Typography variant="h6" fontWeight={600}>
            {title}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <div className="modal-save-exit-body">{contents}</div>
          <div className="modal-save-exit-buttons">
            <GCButton
              name="close"
              title={'Exit form'}
              onClick={() => dismissFunction()}
              buttonSize={GCButtonSize.REGULAR}
              type={ButtonType.GREEN}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default RecordLockedModal;
