// eslint-disable-next-line no-use-before-define
import React, { Component, useEffect } from 'react';
import './ROCreatePatient.scss';
import { ROSideNav, UKROStepper } from 'op-components';
import { Prompt } from 'react-router-dom';

import { Route, RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import { RORegBasicApollo, RORegInsuranceApollo, RORegSummaryApollo, RORegAttachmentsApollo } from 'op-pages';
import ROPatientCarePlanPageFooter from 'op-pages/RO/Careplan/Footer';
import { PatientCard } from 'op-components';
import { gql } from '@apollo/client';
import { LoadingSpinner } from 'shared-components/components';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { EMAIL_STRING_REGEX, UK_CITY_REGEX, UK_NAME_REGEX, UK_PHONE_REGEX } from 'shared-components/utils';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'gc-ui';
import { ButtonRO } from 'shared-components/components/FormFields';
import { ButtonMode, ButtonType } from 'shared-components/enums';
import { resolveListDataValue } from 'op-utils';
import { ListData } from 'shared-components/interfaces';
import { useErrorModalContext } from 'op-contexts';
import { ROPatientContextProvider } from '../../../PatientSummary/context';
import { theme } from 'theme';
import Typography from '@mui/material/Typography/Typography';
import dayjs from 'dayjs';

const EXIT_MESSAGE = 'Are you sure you want to leave? Changes that you made may not be saved.';

const pageFields = {
  basic: [
    'namePrefix',
    'firstName',
    'middleName',
    'lastName',
    'gender',
    'dobDateSelector',
    'idb',
    'primaryCenter',
    'primaryPhone',
    'secondaryPhone',
    'email',
    'address',
    'residentialAddressLine1',
    'residentialAddressLine2',
    'residentialAddressCity',
    'residentialAddressCountry',
    'residentialAddressState',
    'residentialAddressPostcode',
  ],
  insurance: ['payor', 'relationship', 'policyNumber', 'preAuthNumber', 'insuranceNotes'],
  attachments: [],
};

const SUBMIT_MUTATION = gql`
  mutation submit($patientId: ID!) {
    createRoSubmission(patientId: $patientId) {
      submission {
        id
      }
      submissionCreated
    }
  }
`;

const PATIENT_QUERY = gql`
  query patientQuery($id: ID) {
    patient(id: $id) {
      id
      ida
      idb
      idbConflict
      payor
      coverageRelationship
      policyNumber
      preAuthNumber
      preAuthNotes
      attachments {
        id
        dateAttached
        encounterDate
        documentType
        submitted
        file
        url
        filename
        docStatus
        filesize
        typeDisplay
      }
      primaryPhone
      secondaryPhone
      email
      firstName
      middleName
      lastName
      namePrefix
      fullName
      gender
      dob
      dobRawDay
      dobRawMonth
      dobRawYear
      primaryCenter
      hasPendingSubmission
      otherInformation
      referringSurgeon
      address {
        id
        formattedAddress
        ukFormattedAddress
      }
    }
    user {
      id
      isPso
      isShowcaseUser
    }
    documentTypeRefData: attachmentTypes {
      id
      conceptCode
      name: conceptDisplay
    }
    titleRefData: listData(category: "nameTitle") {
      id
      name
    }
    gpSurgeryRefData: practitionerLocations(patient: $id, search: "") {
      id
      name: fullName
    }
    surgeonLocationRefData: practitionerLocations(patient: $id, search: "") {
      id
      name: fullName
    }
    gpRefData: practitioners(url: "https://genesiscare.com/fhir/general-practice-surgery", patient: $id) {
      id
      name
    }
    surgeonRefData: extPractitioners(patient: $id, search: "") {
      id
      name: name
    }
    oncologistRefData: oncologists(patient: $id, search: "") {
      id
      name
    }
    relationshipsRefData: listData(category: "relationships") {
      id
      name
    }
    heritageRefData: listData(category: "heritage") {
      id
      name
    }
    maritalStatusRefData: listData(category: "maritalStatus") {
      id
      name
    }
    ethnicityRefData: listData(category: "ethnicity") {
      id
      name
    }
    religionRefData: listData(category: "religion") {
      id
      name
    }
    countryOfBirthRefData: listData(category: "countryOfBirth") {
      id
      name
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    languageSpokenRefData: listData(category: "languages") {
      id
      name
    }
    medicareTypeRefData: listData(category: "healthFund") {
      id
      name
      appKey
    }
    insurerRefData: departments {
      id
      name
    }
    relationshipRefData: listData(category: "coverageRelationship") {
      id
      name
    }
    dvaTypeRefData: listData(category: "dvaCardType") {
      id
      name
      appKey
    }
    ausStateRefData: listData(category: "ukCounties") {
      id
      name
    }
    primaryCenterRefData: configs {
      id
      name
    }
    nhsOptionsRefData: listData(category: "nhsOptions") {
      id
      name
    }
    registrationReasonRefData: listData(category: "registrationReason") {
      id
      name
    }
    ukCounties: listData(category: "ukCounties") {
      id
      name
    }
    countries: listData(category: "countryOfBirth") {
      id
      name
    }
  }
`;

interface RORefData extends ListData {
  conceptCode?: string;
}

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ id?: string }> {
  isActive?: boolean;
}

interface State {
  //the updatedFields array stores the list of dirty fields in the forms. Dirty fields need validation run on them.
  updatedFields: any[];
  errorModalOpen: boolean;
  submitModalOpen: boolean;
  submitting: boolean;
}

class ROCreatePatient extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      updatedFields: [],
      errorModalOpen: false,
      submitModalOpen: false,
      submitting: false,
    };
  }

  private handleUnload = (event: any) => {
    event.preventDefault();
    event.returnValue = EXIT_MESSAGE;
  };

  public componentDidMount(): void {
    window.addEventListener('beforeunload', this.handleUnload);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this.handleUnload);
  }

  private switchErrorModalStatus = () => {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  };
  private switchSubmitModalStatus = () => {
    this.setState({ submitModalOpen: !this.state.submitModalOpen });
  };
  private onFieldVisit = (keys: string[]) => {
    this.setState({ updatedFields: Array.from(new Set(this.state.updatedFields.concat(keys))) });
  };
  private submitPatient(): void {
    this.setState({ submitting: true });
    const {
      match: {
        params: { id },
      },
      client,
    } = this.props;
    client
      ?.mutate({
        mutation: SUBMIT_MUTATION,
        variables: { patientId: id },
      })
      .then((response): void => {
        this.setState({ submitting: false });
        if (response.data && response.data.createRoSubmission.submissionCreated) {
          this.props.history.replace('/radiation');
        }
      })
      .catch(() => {
        this.setState({ submitting: false });
        this.props.history.replace('/error');
      });
  }
  private validIdb(value: string | undefined): boolean | undefined {
    if (value === '') {
      return true;
    } else if (typeof value === 'string') {
      const mul = [];
      const newValue = value.replace(/ /g, '');
      for (let i = 0; i < 9; i++) {
        // @ts-ignore
        mul.push(parseInt(newValue[i]) * [i + 1]);
      }
      const sum = mul.reduce((n, a) => n + a, 0);
      const mod = sum % 11;
      return mod === parseInt(newValue[9]);
    }
  }
  private isValidFields = (validationObject: any): boolean => {
    for (const key of Object.keys(validationObject)) {
      const pageObj = validationObject[key];
      for (const objKey of Object.keys(pageObj)) {
        if (pageObj[objKey]) {
          return false;
        }
      }
    }
    return true;
  };
  private sectionValid = (validationObject: any, page: string): boolean => {
    const pageObj = validationObject[page];
    for (const objKey of Object.keys(pageObj)) {
      if (pageObj[objKey]) {
        return false;
      }
    }
    return true;
  };
  public render(): JSX.Element {
    const navRoutes = ['basic', 'insurance', 'attachments', 'summary'];
    const { submitting } = this.state;
    const match: any = this.props.match;
    const currLocation = window.location.pathname.split('/').slice(-1)[0];
    const getLocation = (loc: any) => loc === currLocation;
    const currLocationIndex = navRoutes.findIndex(getLocation);
    const patientId: string = match.params.id;
    const today = dayjs();
    const stepperData = {
      sections: [
        {
          title: 'Patient Registration',
          short: 'REG',
          collapsible: false,
          pages: [
            {
              title: 'Basic Details',
              path: `/radiation/registration/patient/${patientId}/basic`,
              key: 'basic',
              pos: 1,
            },
            {
              title: 'Insurance',
              path: `/radiation/registration/patient/${patientId}/insurance`,
              key: 'insurance',
              pos: 2,
            },
            {
              title: 'Attachments',
              path: `/radiation/registration/patient/${patientId}/attachments`,
              key: 'attachments',
              pos: 3,
            },
            {
              title: 'Review & Submit',
              path: `/radiation/registration/patient/${patientId}/summary`,
              key: 'summary',
              pos: 4,
            },
          ],
        },
      ],
    };

    const variables = {
      id: patientId,
    };

    return (
      <Query query={PATIENT_QUERY} variables={variables} fetchPolicy="cache-and-network">
        {({ loading, data, error }: any): JSX.Element => {
          const { setError } = useErrorModalContext();
          useEffect(() => {
            if (error) return setError();
          }, [error]);
          if (loading) return <LoadingSpinner />;
          if (data && data.patient && data.user) {
            // data.documentTypeRefData = this.formatRefData(data.documentTypeRefData);
            // data.gpSurgeryRefData = this.formatRefData(data.gpSurgeryRefData);
            // data.surgeonLocationRefData = this.formatRefData(data.surgeonLocationRefData);
            const patient = data.patient;
            //for the obj validationObject, the value is false if it doesnt need validation or is valid.
            const residentialAddressCityInvalid = patient.residentialAddressCity
              ? (patient.residentialAddressCity as any).match(UK_CITY_REGEX)[0] !== patient.residentialAddressCity
              : false;
            const stateValidationFormatPattern = '[a-zA-Z\\s]+|';
            const residentialAddressCountyInvalid = patient.residentialAddressState
              ? (resolveListDataValue(patient.residentialAddressState, data.ukCounties) as any).match(
                  stateValidationFormatPattern,
                )[0] !== patient.residentialAddressState
              : false;
            const residentialAddressCountryInvalid = patient.residentialAddressCountry
              ? resolveListDataValue(patient.residentialAddressCountry, data.countries) ===
                patient.residentialAddressCountry
              : false;
            const addressInvalid =
              residentialAddressCityInvalid || residentialAddressCountyInvalid || residentialAddressCountryInvalid
                ? true
                : false;

            const validationObject = {
              basic: {
                title: !patient.namePrefix,
                firstName: !patient.firstName || patient.firstName.match(UK_NAME_REGEX)[0] !== patient.firstName,
                middleName: patient.middleName.match(UK_NAME_REGEX)[0] !== patient.middleName,
                lastName: !patient.lastName || patient.lastName.match(UK_NAME_REGEX)[0] !== patient.lastName,
                gender: !patient.gender,
                dob: patient.dob
                  ? !(dayjs(today).diff(patient.dob, 'year') >= 18 && dayjs(today).diff(patient.dob, 'year') <= 120)
                  : true,
                nhsId: !this.validIdb(patient.idb) || patient.idbConflict,
                nhsReason: false,
                primaryCentre: !patient.primaryCenter,
                primaryPhone:
                  patient.primaryPhone || patient.secondaryPhone
                    ? patient.primaryPhone
                      ? !patient.primaryPhone.match(UK_PHONE_REGEX)
                      : false
                    : true,
                secondaryPhone:
                  patient.primaryPhone || patient.secondaryPhone
                    ? patient.secondaryPhone
                      ? !patient.secondaryPhone.match(UK_PHONE_REGEX)
                      : false
                    : true,
                email: patient.email ? !EMAIL_STRING_REGEX.test(String(patient.email).toLowerCase()) : false,
                address: addressInvalid,
                residentialAddressLine1: false,
                residentialAddressLine2: false,
                residentialAddressCity: patient.residentialAddressCity
                  ? !patient.residentialAddressCity.match(UK_CITY_REGEX)
                  : false,
                residentialAddressCountry: false,
                residentialAddressState: false,
                residentialAddressPostcode: false,
                otherInformation: false,
              },
              insurance: {
                payor: false,
                relationship: false,
                policyNumber: false,
                preAuthNumber: false,
                insuranceNotes: patient.preAuthNotes ? (patient.preAuthNotes.length > 2048 ? true : false) : false,
              },
              attachments: {},
              summary: {
                hasPendingSubmission: patient.hasPendingSubmission,
              },
            };
            return (
              <div className="RO-home-wrapper">
                <Modal open={this.state.submitModalOpen} onClose={this.switchSubmitModalStatus} className="modal-gc-ro">
                  <ModalHeader toggle={this.switchSubmitModalStatus}>
                    <Typography variant="h6" fontWeight={600}>
                      Submit patient to Mosaiq?
                    </Typography>
                  </ModalHeader>
                  <ModalBody>
                    <div className="modal-submit-ro">
                      <ul>
                        <li>Please ensure patient details are correct.</li>
                        <li>Patient information will be updated in both systems.</li>
                      </ul>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonRO
                      disabled={false}
                      title={submitting ? 'Submission in progress' : 'Submit'}
                      type={ButtonType.GREEN}
                      mode={ButtonMode.NORMAL}
                      loading={submitting}
                      dataTestId={'submit'}
                      onClick={(): void => {
                        if (!submitting) {
                          this.submitPatient();
                        }
                      }}
                    />
                  </ModalFooter>
                </Modal>
                <Modal open={this.state.errorModalOpen} onClose={this.switchErrorModalStatus} className="modal-gc-ro">
                  <ModalHeader toggle={this.switchErrorModalStatus}>
                    <Typography variant="h6" fontWeight={600}>
                      Missing Mandatory/Error Fields
                    </Typography>
                  </ModalHeader>
                  <ModalBody>
                    <div className="modal-conflicts-body">
                      <div>
                        Please correct or complete the required fields in{' '}
                        <span style={{ color: theme.palette.error.main }}>red</span> for patient registration
                        submission.
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonRO
                      disabled={false}
                      title={'Return to form'}
                      type={ButtonType.GREEN}
                      mode={ButtonMode.NORMAL}
                      onClick={(): void => {
                        this.setState({ errorModalOpen: false });
                      }}
                    />
                  </ModalFooter>
                </Modal>
                <ROSideNav>
                  <UKROStepper validationObject={validationObject} data={stepperData} activePageKey={currLocation} />
                </ROSideNav>

                <div className="RO-create-pat-content">
                  <ROPatientContextProvider>
                    <PatientCard id={patientId} />
                  </ROPatientContextProvider>
                  <Switch>
                    <Route
                      path="/radiation/registration/patient/:id/basic"
                      render={(props: RouteComponentProps<any>) => (
                        // @ts-ignore
                        <RORegBasicApollo
                          // UPGRADE: Typescript 4 Upgrade - No longer available
                          // @ts-ignore
                          onFieldVisit={this.onFieldVisit}
                          updatedFields={this.state.updatedFields}
                          {...props}
                        />
                      )}
                    />
                    <Route path="/radiation/registration/patient/:id/insurance" component={RORegInsuranceApollo} />
                    <Route path="/radiation/registration/patient/:id/attachments" component={RORegAttachmentsApollo} />
                    <Route path="/radiation/registration/patient/:id/summary" component={RORegSummaryApollo} />
                    <Route render={() => <Redirect to={`/radiation/registration/patient/${patientId}/basic`} />} />
                  </Switch>
                  <ROPatientCarePlanPageFooter
                    backDisabled={currLocationIndex - 1 < 0}
                    nextDisabled={currLocation === 'summary' && data.user.isShowcaseUser}
                    onBack={(): void => {
                      this.props.history.push(
                        `/radiation/registration/patient/${patientId}/${navRoutes[currLocationIndex - 1]}`,
                      );
                    }}
                    onNext={(): void => {
                      if (currLocation === 'summary') {
                        if (!this.isValidFields(validationObject)) {
                          this.setState({ errorModalOpen: true });
                        } else {
                          this.setState({ submitModalOpen: true });
                        }
                      } else {
                        //@ts-ignore
                        this.onFieldVisit(pageFields[currLocation]);
                        if (this.sectionValid(validationObject, currLocation)) {
                          this.props.history.push(
                            `/radiation/registration/patient/${patientId}/${navRoutes[currLocationIndex + 1]}`,
                          );
                        }
                      }
                    }}
                    nextText={currLocation === 'summary' ? 'Submit patient data' : 'Continue'}
                  />
                  <Prompt
                    message={(params) =>
                      params.pathname.includes('/radiation/registration/patient/') || this.state.submitModalOpen
                        ? true
                        : EXIT_MESSAGE
                    }
                  />
                </div>
                <div style={{ width: '1px' }}></div>
              </div>
            );
          }
          return <></>;
        }}
      </Query>
    );
  }

  private formatRefData = (refData: RORefData[]): ListData[] => {
    return refData.map((value) => {
      value.id = value.conceptCode || value.name;
      return value;
    });
  };
}

const apolloComponent = withApollo<Props>(ROCreatePatient);
export default apolloComponent;
